import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Segment, Table, Pagination,TextArea } from "semantic-ui-react";
import "../../Grid/grid.css";
import "../../Modal/dialog.css";
import { validNewPassword } from '../../../validation/Validation';
import api from "../../../api";
import DatePicker from "react-datepicker";
import jstz from "jstz";
import moment from 'moment'
const CustomModel = (props) => {
    const [loading, setloading] = useState(false);
    const [formdata, setformdata] = useState([]);
    const [searchdata, setsearchdata] = useState([]);
    const [errs, seterrs] = useState([]);
    const [msg, setmsg] = useState("");
    const [formerrs, setformerrs] = useState({});
    const [formlerrs, setformlerrs] = useState({});
    const [searcherrs, setsearcherrs] = useState({});
    const [timezonedata, settimezonedata] = useState({});
    const  [key, setkey] = useState(Math.round(Math.random() * 100));
    const [disablebtn, setdisablebtn] = useState(false);
    const [job,setJob]=useState("");
    const [joberr,setJoberr]=useState("");
   const taref= useRef("");
    
    useEffect(() => {
        if(typeof props?.exist_data!="undefined" && typeof props?.exist_data?.parsed_data!="undefined"){
            setformdata(props.exist_data?.parsed_data);
            setkey(Math.random()*100); 
        }else{
            setformdata(props.parsed_data);
        }
        if(typeof props?.exist_data!="undefined" && typeof props?.exist_data?.search_data!="undefined"){
            let sset= props?.exist_data?.search_data ? props?.exist_data?.search_data:[];
            setsearchdata(sset);
           
        }
        if(typeof props?.exist_data!="undefined" && typeof props?.exist_data?.search_data!="undefined" && props?.exist_data?.job_description.length>0){
           setJob(props.exist_data.job_description);
        }else{
            setJob(props.job_description!='undefined' ? props.job_description : "");
        }
      
        
       
        setkey(Math.random()*100); 
        if(props?.time_zone){
            api.botbuilder.getCurrentTime({ timezone: props.time_zone }).then((data) => {
                try {
                  settimezonedata({
                    minDate: data.data.currenttime,
                    setDate: data.data.currenttime,
                    startTimeForDuration: data.data.currenttime,
                  });
                  
                } catch (err) {
                  console.log(err);
                }
              });
        }
        seterrs([]);
        setformerrs({});
        setformlerrs({});
        setsearcherrs({});
        setdisablebtn(false);
        setJoberr("");
    }, [props]);

   

   const  showOptions=(data)=>{
     if(data.length > 0 && typeof data == "object"){
        return(<>
        {data.map((item,index) => (<option key={item?.data_id} value={item?.data_id}>{item?.data_name}&nbsp;&nbsp;({item?.data_id})</option>))}
        </>);
     }else{
       return(<p>No data</p>);
     }

    }

   
   const closeCustomPopup=()=>{
    seterrs([]);
    setformerrs({});
    setformlerrs({});
    setsearcherrs("");
     props.modalClose();
   }
   const checkisrequired=(val)=>{
     let parsed_config=typeof props?.parsed_config!='undefined' ? props?.parsed_config : [];
     if(parsed_config.length==0){
         return false;
    }
   
     let isreq=parsed_config.filter(item=>{
        return item.rule.toLowerCase().trim()==val.toLowerCase().trim();
     });
     return isreq.length>0 ? isreq[0].required : false;
      
   }
   const checkSeachfieldReq=(val,val2="")=>{
	if(val2=="" && val2==null){
		let re_reqs=typeof props?.custom_search.is_required!='undefined' ? JSON.parse(props?.custom_search.is_required):[];
		if(re_reqs.length==0){
			return true;
		}
		return typeof re_reqs[val]!='undefined' ? re_reqs[val]:true;
	}else if(val2!=null){
		if(val2[val] && searchdata.length==0){
			return true;
		}else{
			let cur_search=searchdata.filter(item=>item.name==val);
			let s=cur_search.length>0 ? cur_search[0].val : "";
			if(s=="" && val2[val]){
				return true;
			}
		}
		return false;

	}
    
}
   const validateForm=()=>{
    let err=[];
    let err1=formerrs;
    let err2={};
    let iserr=false;
    var errnum=0;
    
      if(formdata.length==0 && props?.is_required==1 ){
         iserr=true;
         err.push("No parsed found.");
         seterrs(err);
      }
      if(formdata.length>0 && props?.is_required==1 ){
        for(let i=0;i<formdata.length;i++){
           
            if(formdata[i].value==null  && checkisrequired(formdata[i].key)){
                let v=`This field is required.`;
                iserr=true;
                err1[formdata[i].key]=v;
                if(errnum==0){
                    document.getElementById(formdata[i].key+"_value").focus();
                    document.getElementById("row_"+i).scrollIntoView({
                        behavior: 'smooth'
                      });
                      errnum++;
                }
                
            }
            else if((formdata[i].value && formdata[i].value.trim().length==0) || !formdata[i].value && checkisrequired(formdata[i].key)){
                let v=`This field is required.`;
                iserr=true;
                err1[formdata[i].key]=v;
                if(errnum==0){
                    document.getElementById(formdata[i].key+"_value").focus();
                    document.getElementById("row_"+i).scrollIntoView({
                        behavior: 'smooth'
                      });
                      errnum++;
                }
               
            }
           
            if(checkdatetimeinput({key:formdata[i].key})){
                let d1=moment(timezonedata.setDate);
                let d2=moment(formdata[i].value);
                if(d2<d1){
                    iserr=true;
                    err1[formdata[i].key]="Past date/time entered. Please select a current or future one.";
                    if(errnum==0){
                        document.getElementById(formdata[i].key+"_value").focus();
                        document.getElementById("row_"+i).scrollIntoView({
                            behavior: 'smooth'
                          });
                          errnum++;
                    }
                }
            }
        }
        if(props?.is_required==1){
            if(Object.keys(err1).length>0){
                setformerrs(err1);
            }
            if(Object.keys(err2).length>0){
                setformlerrs(err2);
            }
        }
        
      }
      let jobdesc=job;
      jobdesc=jobdesc.trim();
      if(jobdesc.length==0){
        iserr=true;
        setJoberr("This field is required.");
        if(errnum==0){
           let element= document.getElementById('job_desc').focus();
           taref.current.focus();
             errnum++;  
        }
      }
      
	  let searchErrs={};
      if(searchdata.length>0 ){
        for(let i=0;i<searchdata.length;i++){
            if(searchdata[i].val==null){
                iserr=true;
				searchErrs[searchdata[i].name]="This is required field.";
                document.getElementById("sel_"+i).focus();
              }else if((searchdata[i].val=="") && checkSeachfieldReq(searchdata[i].name)){
                iserr=true;
				searchErrs[searchdata[i].name]="This is required field.";
                if(errnum==0){
                    document.getElementById("sel_"+i).focus();
                    errnum++;
                }
                
          }
        }
		setsearcherrs(searchErrs);
      }
     
      let fieldArr=props?.custom_searchdata!='undefined' ? props?.custom_searchdata:[];
	  let fieldReqArr=props?.custom_search?.is_required!='undefined' ? JSON.parse(props?.custom_search?.is_required):[];
      if(fieldArr.length>0){
         for(let j=0;j<fieldArr.length;j++){
			if(checkSeachfieldReq(fieldArr[j].search_field,fieldReqArr)){
				searchErrs[fieldArr[j].search_field]="This is required field.";
                iserr=true;
                if(document.getElementById("sel_"+j)){
                    if(errnum==0){
                        document.getElementById("sel_"+j).focus();
                        errnum++;
                    }
                   
                }
               
			}
         }
        
		 setsearcherrs(searchErrs);
      }
    
       // setsearcherrs("Please select value for every search criteria.");
      
      setTimeout(() => {
        // seterrs([]);
        // setformerrs({});
        // setformlerrs({});
        // setsearcherrs({});
        setdisablebtn(false);
       
      }, 4000);
      return iserr;
   }
   const is_in_reqs=(key)=>{
     let is_reqs=typeof props?.custom_search?.is_required!='undefined' ? JSON.parse(props?.custom_search?.is_required):[];
     if(is_reqs.length==0){
        return false;
     }
     let keys=Object.keys(is_reqs);
     return !keys.includes(key) &&  is_reqs[key] ? true:false;
     
   }
   const handleSubmit=(e)=>{
    let iserr= validateForm();
    if(!iserr){
        setdisablebtn(true);
        setloading(true);
        api.chatlist.saveManulPushdata({parsed_data:formdata,search_data:searchdata,logid:props.curid,module:props.module,job:job}).then((data) => {
            if((data.status)){
                setloading(false);
                setmsg("Data saved successfully.");
                window.scrollTo(0, 0);
                setTimeout(() => {
                    closeCustomPopup();
                }, 5000);
               
            }else{
                setloading(false);
                setmsg(data.message);
                window.scrollTo(0, 0);

            }
           
        }).catch(err=>{
            setloading(false);
        });
    }else{
      setloading(false);
      setTimeout(() => {
        seterrs([]);
        setdisablebtn(false);
       // setJoberr("");  
      }, 4000);
    }    
    setTimeout(() => {
        setmsg("");
        setdisablebtn(false);
      }, 4000);
     
       
   }
   const changeSeachVal=(e,itemdata)=>{
       let name=e.target.name;
       let val=e.target.value;
       let search_data=searchdata ? searchdata :[];
       let ind=search_data.findIndex(item=>item?.name==name);
       val = val==val.toLowerCase().includes("select value") ? "":val;
       let label="";
       if(val!==""){
           itemdata=JSON.parse(itemdata.response_data); 
          let sr=itemdata.filter((t)=>t.data_id==val);
          label=typeof sr[0]?.data_name !="undefined" ? sr[0]?.data_name:"";
       }else{
        label="";
       }
       if(ind==-1){
        search_data.push({name:name,val:val,label:label});
       }else{
         search_data[ind].val=val;
         search_data[ind].label=label;
       }
       let errs=searcherrs;
       if(name in errs){
        delete errs[name];
       }
       setsearcherrs(errs);
       setsearchdata(search_data);
       setkey(Math.random()*100); 

   }
   const showRequired=(item)=>{
       let isreqs= props.parsed_config!=null && typeof props.parsed_config!='undefined' ? props.parsed_config:[];
       if(isreqs.length==0){
         return(<></>);
       }
       if(props.is_required==0){
        return(<></>);
      }
       if(isreqs.length>0 && props.is_required==1){
         let temp= isreqs.filter(req=>{
            let rule=req.rule.toLowerCase().trim();
            let itemkey=item.key.toLowerCase().trim().trim();
           return  rule==itemkey;
          });
         if(temp.length==0){
            return(<></>);
         }
         if(temp.length>0 && temp[0].required){
            return(<strong style={{color:'red'}}>*</strong>);
         }

       }
   }
   const checkdatetimeinput=(item)=>{
    let isreqs=props.parsed_config!=null  && typeof props.parsed_config!='undefined' ? props.parsed_config:[];
    if(isreqs.length==0){
      return false;
    }
    if(props.date_required==0){
        return false;
    }
    
    if(isreqs.length>0 && props.date_required==1){
      let temp= isreqs.filter(req=>{
         let rule=req.rule.toLowerCase().trim();
         let itemkey=item.key.toLowerCase().trim();
        return  rule==itemkey;
       });

      if(temp.length==0){
        return false;
      }
      if(temp.length>0 && temp[0].date_required){
         return true;
      }

    }
}
   const showrequiredMark=(val)=>{
		let fieldArr=props?.custom_search?.is_required && props?.custom_search?.is_required!='undefined' ? JSON.parse(props?.custom_search?.is_required):{};
		if(Object.keys(fieldArr).length==0){
			return (<></>);
		}
		if(Object.keys(fieldArr).length>0 && fieldArr[val]){
			return(<strong style={{color:'red'}}>*</strong>);
		}
   }

   const isAddressVal=(item)=>{
    let isreqs= props.parsed_config!=null && typeof props.parsed_config!='undefined' ? props.parsed_config:[];
       if(isreqs.length==0){
         return false;
       }
       if(props.use_address==0){
        return  false;
      }
       if(isreqs.length>0 && props.use_address==1){
         let temp= isreqs.filter(req=>{
            let rule=req.rule.toLowerCase().trim();
            let itemkey=item.key.toLowerCase().trim().trim();
           return  rule==itemkey;
          });
         if(temp.length==0){
           return false;
         }
         if(temp.length>0 && temp[0].is_address){
            return true;
         }

       }
   }
   const hideerrorMsg=(index,item)=>{
    let errs=formerrs;
    let key=item.key;
    console.log(key in errs,errs,"1");
    if(key in errs){
        delete errs[key];
    }
    console.log(key in errs,errs,"2");
    setformerrs(errs);

   }

   const showinput=(item,formdata,vall,index)=>{
    vall=vall!=null && vall!="null" ? vall:"";
    let isdatetime=checkdatetimeinput(item);
    if(!isdatetime){
        return( <input key={index} className='form-control'  type="text" id={`${item['key']}_value`} onChange={(e) => {
            e.preventDefault();
            let new_Arr = [...formdata];
            new_Arr[index].value = e.target.value;
            setformdata(new_Arr);
            hideerrorMsg(index,item);
            }}
            // disabled={isAddressVal(item)} 
            name={`${item['key']}_value`}
            defaultValue ={vall=='N/A' || vall=='n/a' ? '': vall} />);
    }else{
        if(Object.keys(timezonedata).length){
           
            if(!moment(vall).isValid() || vall.length==0){
                let newArr1 = [...formdata];
                newArr1[index].value = timezonedata.setDate;
                setformdata(newArr1);
            }else{
                vall=moment(vall).isValid() ? vall:timezonedata.setDate;
            }
            return(<p style={{cursor:"pointer"}}><input key={index} className='form-control' title="Edit datetime" step="60"  type="datetime-local" id={`${item['key']}_value`} onChange={(e) => {
                e.preventDefault();
                const newArr = [...formdata];
                let d1=moment(timezonedata.setDate);
                let d2=moment(e.target.value);
                let ferr=formerrs;
                if(d2>d1){
                    newArr[index].value = e.target.value;
                    if(item.key in ferr){
                       delete  ferr[item.key];
                    }
                    setformerrs(ferr);
                }else{
                    newArr[index].value =timezonedata.setDate;
                    ferr[item.key]="Past date/time entered. Please select a current or future one.";
                    setformerrs(ferr);
                }
                setformdata(newArr);
                }} 

                onKeyDown={(e) => {
                    e.preventDefault();
                }}
               
                
                min={timezonedata.minDate} 
                name={`${item['key']}_value`}
                defaultValue ={moment(vall).format('YYYY-MM-DDTHH:mm')} /></p>); 
        }else{
            return(<>No timezone</>);
        }
       
    }
   
   }
   const setStartDate = (date,index,item,vall) => {
    
  };
   
    return (
        <React.Fragment>
        {/* {<div style={{ display: loading }} class="ui loading form mh-loading-custom"></div>} */}
        <div
            id="notificationSetingModalPopup"
            className={props.show ? "modal fade show" : "modal fade"}
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="staticBackdrop"
            aria-hidden="true"
            style={{ display: props.show ? "block" : "none" }}
        >
            <div className="modal-dialog modal-dialog-centered"  role="document" style={{width:"50%",minWidth:'40%'}}>
                <div className="modal-content">
                    <button
                        type="button"
                        className="close linear-gradient"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => props.modalClose()}
                    >
                        X
                </button>
                    <div className="modal-header" style={{marginBottom:10,width:'100%'}}>
                        <div className='row' style={{width:'100%'}}>
                            <div className='col-md-6'> <h5 className="modal-title">{props.title}</h5></div>
                            <div className='col-md-6'> <p className="modal-title" style={{fontSize:'1em'}}> {props.exist_data.sent==1 && <div className='alert alert-success'>Data already sent automatically.</div>}</p></div>
                        </div>
                    </div>

                    {loading && <div className="ui loading form"></div>}
                    {errs.length > 0 && <div className='alert alert-danger'>{errs[0]}</div>}
                    {msg.length > 0 && <div className='alert alert-success'>{msg}</div>}
                    <div className="modal-body" style={{margin:"20px"}}>
                        <form style={{maxHeight:'55vh',overflowY:'auto',overflowX:'hidden',padding:'5px'}}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <h6>Parsed data</h6>
                                        {formdata.length  ? 
                            <Table id="additional_captured">
                                <Table.Body key={key}>
                                    {formdata.map(function(item,index){
                                        let vall=(typeof item['value'] == 'string') ? item['value'] : JSON.stringify(item['value']);
                                        return (
                                            <Table.Row style={{wordBreak:'break-all'}} key={index} id={`row_${index}`}>
                                                <Table.Cell>
                                                    <strong>{item.label}</strong> <span>{showRequired(item)}</span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {showinput(item,formdata,vall,index)}
                                                  
                                                {Object.keys(formerrs).length>0 && typeof formerrs[item.key]!="undefined" && <p className='text-danger'>{formerrs[item.key]}</p> }
                                                </Table.Cell>
                                            </Table.Row>
                                            )
                                        }) 
                                    }
                                </Table.Body>
                            </Table> : <>
                           
                            <h2>No parsed data available.</h2></>}
                            <div className='form-group'>
                                <label>Job Description <strong style={{color:'red'}}>*</strong></label>
                               <TextArea value={job} id="job_desc"  ref={taref} className="form-control" onChange={(e)=>{setJob(e.target.value);setJoberr("");}} name="job_description"  />
                               <input type="text" id="jobhidden" style={{display:'none'}} />
                                {joberr && <span className='text-danger'>{joberr}</span>}
                            </div>
                            <h6 style={{marginTop:"20px"}}>Custom integration data</h6>
                            {/* {searcherrs.length > 0 && <div className='text-danger'>{searcherrs}</div>} */}
                            {props?.custom_searchdata?.length > 0 ? <>
                               {props.custom_searchdata.map((item, index) => {
							
                                let response_data=JSON.parse(item.response_data);
                                let answers=typeof props.exist_data?.search_data!="undefined" ? props.exist_data.search_data : [];
                                let ind=-1;
                                answers=answers ? answers:[];
                                if(answers.length > 0) {
                                   ind=answers.findIndex(itemval=>itemval.name==item.search_field.trim())
                                }
                                let ans=ind>-1 && answers.length>0 ? answers[ind].val :"";
							
                                return(<div className='form-group' key={item?.search_field}>
                                    <label>Select {item.search_field}&nbsp;{showrequiredMark(item.search_field)}</label>
                                    <select name={item.search_field} 
                                    key={index}
                                    id={`sel_${index}`} 
                                    defaultValue={ans}
                                     onChange={(e)=>changeSeachVal(e,item)} 
                                     className='form-control'>
                                    <option value="">Select value for {item.search_field}</option>
                                        {showOptions(response_data)}
                                    </select>
                                    {typeof searcherrs[item.search_field]!='undefined' && <div className='text-danger'>{searcherrs[item.search_field]}</div>}
                                </div>);
                               })}
                            </>:<p>No data found</p>}
                                            
                                    </div>
                                </div>
                        </form>
                        
                    </div>
                    <div className="modal-footer">
                    <div className="row"><button
            type="button"
            disabled={disablebtn ? true : false}
            className="btn linear-gradient yr-submitbtn btn-sm"
            onClick={(e)=>handleSubmit(e)}
          >
           {props?.custom_search?.button_name}
          </button> &nbsp;&nbsp;
          <button
            type="button"
            className="btn linear-gradient yr-submitbtn btn-sm"
            onClick={(e)=>closeCustomPopup()} 
          >
            Cancel
          </button></div>
                    </div>

                </div>
                

            </div>
        </div>
    </React.Fragment>
    );
};




export default CustomModel;