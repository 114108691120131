import axios from "../../config/axios";
import config from "../../config/index";

const dialogflow = {
  loadDialogFlow: (params) =>
    axios
      .post("/integration/GoogleMyBusiness/", params)
      .then((resp) => (resp ? resp.data : null)),

  createWebhook: (params) =>
    axios
      .post("/integration/GoogleMyBusiness/create", params)
      .then((resp) => (resp ? resp.data : null)),

      getcustomWebhook: (params) =>
        axios
          .get(`${config.apiBaseURL2}getCustomWebhoook`, params)
          .then((resp) => (resp ? resp.data : null)),

      saveCustomWebhoook: (params) =>
        axios
          .post(`${config.apiBaseURL2}saveCustomWebhoook`, params)
          .then((resp) => (resp ? resp.data : null)), 
          
        searchCustomWebhoook: (params) =>
            axios
              .get(`${config.apiBaseURL2}sendwebhookdata`, params)
              .then((resp) => (resp ? resp.data : null)), 
              
              saveZapToken: (params) => 
                axios
                    .post(`${config.apiBaseURL2}create-zap-token`, params)
                    .then( (resp) => resp.data)
                    .catch( (err) => console.log(err)), 
                     
            getZaptoken: () => 
                axios
                    .get(`${config.apiBaseURL2}get-zap-token`)
                    .then( (resp) => resp.data)
                    .catch( (err) => console.log(err)), 
            
                    deleteZapToken: (params) => 
              axios
                  .post(`${config.apiBaseURL2}delete-zap-token`,params)
                  .then( (resp) => resp.data)
                  .catch( (err) => console.log(err)),  
                  
          primeZapToken: (params) => 
              axios
                  .post(`${config.apiBaseURL2}mark-token-prime`,params)
                  .then( (resp) => resp.data)
                  .catch( (err) => console.log(err)),   


};

export default dialogflow;