import React from "react";
import { connect } from "react-redux";
import { Segment } from "semantic-ui-react";
import { CSVLink } from "react-csv";
// import "bootstrap-daterangepicker/daterangepicker.css";
import DatePicker from "react-datepicker";

import {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setSubHeaderTitle,
  setNavigationName,
  toggleBreadcrum
} from "../../../store/Page";
import DateRangePicker from "react-bootstrap-daterangepicker";
import api from "../../../api";
import GridList from "../../Grid";
import './toggle.css'

class TeamActivity extends React.Component {
  constructor(props) {
    super(props);

    let today = new Date();

    let date = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    let finalEndDate = month + "/" + date + "/" + year; //month + "/" + date + "/" + year;
    let finalStartDate = finalEndDate;

    this.state = {
      loading: false,
      selectedtext: "Date Range",
      teamMembers: null,
      otherParams: {
        teamMemberId: 0,
        type: 0,
        isOpened: 0,
        isReviewed: 0,
      },
      csvData: [],
      initialSettings: {
        startDate: finalStartDate,
        endDate: finalEndDate,
      },
      teamInfo: null,
      modal: false,
      switchValue1: 'Schedule',
      switchValue2: 'Sent',
      switchValue: 0,
      schedulemodal: false,
      schedule_delete: false,
      s_auto_id: 0,
      del_msg: '',
      schedule_start_stop: '',
      scheduleStartDate: new Date(),
      up_auto_id: '',
    };
    this.columnLists = [
      {
        text: "S.NO",
        key: "id",
        sort: false,
      },

      { text: "Customers Name/Status", key: "MR.customer_name" },
      { text: "Date", key: "MR.created_date" },
      {
        text: "Team Member",
        key: "MT.member_name",
        event: [
          {
            key: "viewTeamMemberDetail",
            params: ["teamId", "reviewID"],
            func: (teamId, reviewID) =>
              this.viewTeamMemberDetail(teamId, reviewID),
          },
        ],
      },
      { text: "Email", key: "MR.customer_email" },
      { text: "Phone Number", key: "MR.customer_phone" },
      { text: "Request type", key: "'MR.workflow_type'" },


    ];
    this.columnListsAuto = [
      {
        text: "S.NO",
        key: "id",
        sort: false,
      },
      { text: "Customers Name", key: "MS.name" },
      { text: "Schedule Date", key: "MS.schedule_date" },
      { text: "Email", key: "MS.email" },
      { text: "Phone Number", key: "MS.phone" },
      { text: "Schedule Status", key: "MS.send_status" },
      {
        text: "Action", key: "MS.auto_id",
        event: [
          {
            key: "scheduleUpdate",
            params: ["auto_id"],
            func: (auto_id) =>
              this.scheduleUpdate(auto_id),
          },
          {
            key: "scheduleDelete",
            params: ["auto_id"],
            func: (auto_id) =>
              this.scheduleDelete(auto_id),
          },
          {
            key: "scheduleStartStop",
            params: ["auto_id", "send_status"],
            func: (auto_id, send_status) =>
              this.scheduleStartStop(auto_id, send_status),
          },
        ],

      },

    ];
    this.dateRef = React.createRef;
  }
  viewTeamMemberDetail = (teamId, reviewID) => {
    if (teamId) {
      this.setState({ loading: true });
      api.reviews
        .getTeamInfo({ teamId, reviewID })
        .then((resp) => {
          this.setState({ teamInfo: resp.teamInfo }, () => {
            this.setState({ modal: true });
            //console.log(this.state.teamInfo);
          });
        })
        .catch((err) => this.setState({ loading: false }));
    }
  };



  toggleModal = () => {
    this.setState({ modal: false, teamInfo: null, loading: false });
  };
  teamInfoModal = () => {
    const teamInfo = this.state.teamInfo;
    return (
      <div
        className={
          this.state.modal
            ? "modal yr-upgrade-popup white-bg new-model-popup fade show "
            : "modal fade"
        }
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: this.state.modal ? "block" : "none" }}
        id="kt_modal_6"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.toggleModal}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{"Team Info"}</h5>
            </div>
            <div className="modal-body">
              <div className="kt-portlet__body">
                <div className="kt-section kt-section--first">
                  <div className="kt-section__body">
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Team Member Name :
                      </label>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={teamInfo.member_name}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Phone Number :
                      </label>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={
                            teamInfo.country_code
                              ? teamInfo.country_code + teamInfo.phone
                              : "" + teamInfo.phone
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">Email :</label>
                      <div className="col-lg-8">
                        <input
                          type="email"
                          className="form-control"
                          required
                          value={teamInfo.email}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Template to request a review :
                      </label>
                      <div className="col-lg-8">
                        <textarea className="form-control" rows="5">
                          {teamInfo.template}
                        </textarea>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-12 col-form-label">
                        Receive review notifications via text or email?
                        (Optional)
                      </label>
                      <div className="col-lg-12">
                        <div className="kt-checkbox-inline">
                          <label className="kt-checkbox">
                            <input
                              type="checkbox"
                              checked={
                                teamInfo.notification_text > 0 ? true : false
                              }
                            />{" "}
                            Text
                            <span />
                          </label>
                          <label className="kt-checkbox">
                            <input
                              type="checkbox"
                              checked={
                                teamInfo.notification_email > 0 ? true : false
                              }
                            />{" "}
                            Email
                            <span />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  togglescheduleModal = () => {
    this.setState({ schedulemodal: false, loading: false });
  };
  togglescheduleDeleteModal = () => {
    this.setState({ schedule_delete: false, loading: false });
  };

  togglescheduleStartStopModal = () => {
    this.setState({ schedule_start_stop: false, loading: false });
  };


  scheduleModal = () => {

    //alert(auto_id);
    //this.setState({ up_auto_id: auto_id });

    let myCurrentDate = new Date()
    let date = myCurrentDate.getDate();
    let month = myCurrentDate.getMonth() + 1;
    let year = myCurrentDate.getFullYear();
    let todayDate = year + '-0' + month + '-0' + date;

    return (
      <div
        className={
          this.state.schedulemodal
            ? "modal yr-upgrade-popup white-bg new-model-popup fade show "
            : "modal fade"
        }
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: this.state.schedulemodal ? "block" : "none" }}
        id="kt_modal_6"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.togglescheduleModal}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{"Update schedule date"}</h5>
            </div>
            <div className="modal-body">
              <div className="kt-portlet__body">
                <div className="kt-section kt-section--first">
                  <div className="kt-section__body">
                    <div className="form-group row">
                      <label className="col-lg-4 col-form-label">
                        Schedule date :
                      </label>
                      <div className="col-lg-8">

                        <DatePicker
                          selected={this.state.schedule_date}
                          onChange={this.setStartDate}
                          minDate={this.state.scheduleStartDate}
                          timeInputLabel="Time:"
                          dateFormat="MMMM d yyyy  ,  h:mm aa"
                          showTimeInput
                          className="form-control"
                          name="setDate"
                          excludeOutOfBoundsTimes
                        />
                      </div>
                      <div class="col-lg-12 d-flex justify-content-end pt-4">
                        <button type="submit" class="btn linear-gradient zy-save-btn" id="update_reviebtn" onClick={() => this.saveScheduleDate(this.state.up_auto_id, this.state.schedule_date)}>Update</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  scheduleStartStopModel = () => {
    //alert('hi');
    return (
      <div
        className={
          this.state.schedule_start_stop
            ? "modal yr-upgrade-popup white-bg new-model-popup fade show "
            : "modal fade"
        }
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: this.state.schedule_start_stop ? "block" : "none" }}
        id="kt_modal_6"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.togglescheduleStartStopModal}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{"Confirm Your Action"}</h5>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to {this.state.sent_statusSet}?</p>
            </div>
            {/* start modal-footer */}
            <div className="modal-footer">
              <div className="col-md-12" style={{ textAlign: 'right', marginRight: 6 }}>
                <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" aria-label="Close" data-dismiss="modal" onClick={this.togglescheduleDeleteModal} style={{ lineHeight: 1.8, minWidth: 84 }}>
                  <span>No</span>
                </button>&nbsp;
                <button type="button" class="btn linear-gradient yr-submitbtn btn-sm" onClick={() => this.sStartStop()} style={{ lineHeight: 1.8, minWidth: 84 }}>Yes</button>
              </div>
            </div>
            {/* end modal-footer */}
          </div>
        </div>
      </div>
    );
  };

  scheduleDeleteModel = () => {
    //alert('hi');
    return (
      <div
        className={
          this.state.schedule_delete
            ? "modal yr-upgrade-popup white-bg new-model-popup fade show "
            : "modal fade"
        }
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: this.state.schedule_delete ? "block" : "none" }}
        id="kt_modal_6"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.togglescheduleDeleteModal}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{"Confirm Your Action"}</h5>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete?</p>
            </div>
            {/* start modal-footer */}
            <div className="modal-footer">
              <div className="col-md-12" style={{ textAlign: 'right', marginRight: 6 }}>
                <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" aria-label="Close" data-dismiss="modal" onClick={this.togglescheduleDeleteModal} style={{ lineHeight: 1.8, minWidth: 84 }}>
                  <span>No</span>
                </button>&nbsp;
                <button type="button" class="btn linear-gradient yr-submitbtn btn-sm" onClick={() => this.sDelete()} style={{ lineHeight: 1.8, minWidth: 84 }}>Yes</button>
              </div>
            </div>
            {/* end modal-footer */}
          </div>
        </div>
      </div>
    );
  };

  componentDidMount() {
    const {
      setPageTitle,
      toggleNavigation,
      location,
      PageMessage,
      setMessage,
      setSubHeaderTitle,
      setNavigationName,
      toggleBreadcrum
    } = this.props;
    setPageTitle({
      title: "Team Activity",
      subHeaderTitle: "Review Management",
    });
    setSubHeaderTitle({ subHeaderTitle: "Review Management" });
    setNavigationName({ navigationName: "reviewManagement" });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    //const params = queryString.parse(location.search);

    api.manageTeam.getTeam().then((data) => {
      if (data) {
        this.setState({ teamMembers: data.data });
      }
    });

  }
  renderTeamMember = () => {
    return this.state.teamMembers.map((member) => {
      return (
        <option key={member.id} value={member.id}>
          {member.member_name}
        </option>
      );
    });
  };
  modeType = (e) => {

    let mode_type = e.target.value;
    this.setState({ modetype: mode_type });
  }
  requestType = (e) => {

    let request_type = e.target.value;
    this.setState({ requesttype: request_type });
  }
  getDataSourceM = (params) => api.reviews.teamActivityList(params); //Rohit
  getDataSourceAuto = (params) => api.reviews.teamActivityListAuto(params); //Rohit

  applyDateRange = (event, picker) => {
    let range = {};
    //range["chatfrequency"] = "daterange";
    range["startdaterange"] = picker.startDate.format("MM/DD/YYYY");
    range["enddaterange"] = picker.endDate.format("MM/DD/YYYY");
    let otherParams = this.state.otherParams;
    otherParams.range = range;

    let finalStartDate = range["startdaterange"];
    let finalEndDate = range["enddaterange"];
    let initialSettings = {
      startDate: finalStartDate,
      endDate: finalEndDate,
    };
    this.setState({ otherParams, initialSettings });

    console.log(this.dateRef);
  };

  handlePickerEvent = (event, picker) => {
    console.log(picker);
  };
  handleChange = (e, type) => {
    let otherParams = this.state.otherParams;

    if (type === "team") {
      otherParams.teamMemberId = e.target.value;
    } else if (type === "type") {
      otherParams.type = e.target.value;
    } else if (type === "isOpened") {
      otherParams.isOpened = e.target.value;
    } else if (type === "isReviewed") {
      otherParams.isReviewed = e.target.value;
    } else if (type === "requesttype") {
      otherParams.requesttype = e.target.value;
    }

    if (otherParams && Object.keys(otherParams).length > 0) {
      this.setState(otherParams);
    }
  };

  downloadList = () => {
    let otherParams = this.state.otherParams;
    this.setState({ loading: true });
    api.reviews
      .downloadTeamActivity({ otherParams: otherParams })
      .then((resp) => {
        this.setState({ csvData: resp.list }, () => {
          setTimeout(() => {
            this.setState({ loading: false });
            document.getElementById("csvData").click();
          }, 2000);
        });
      });
  };

  downloadListAuto = () => {
    let otherParams = this.state.otherParams;
    this.setState({ loading: true });
    api.reviews
      .downloadTeamActivityAuto({ otherParams: otherParams })
      .then((resp) => {
        this.setState({ csvData: resp.list }, () => {
          setTimeout(() => {
            this.setState({ loading: false });
            document.getElementById("csvData2").click();
          }, 2000);
        });
      });
  };

  scheduleUpdate(auto_id) {

    //alert(auto_id);
    this.setState({ up_auto_id: auto_id });
    api.reviews.getSchedule({ auto_id }).then((resp) => {
      //alert(resp.schedule_date);
      this.setState({ schedule_date: new Date(resp.schedule_date) }, () => {
        this.setState({ schedulemodal: true, });
        this.scheduleModal();
      });
    });
  }

  setStartDate = (date) => {
    this.setState({
      schedule_date: date
    }, () => {
      //this.setState({modalbody: this.getBody()});
    });
  }

  scheduleDelete(auto_id) {

    //alert(auto_id);
    this.setState({ schedule_delete: true, s_auto_id: auto_id });
    this.scheduleDeleteModel();
  }

  saveScheduleDate(auto_id, schedule_date) {

    api.reviews.updateScheduleDate({ auto_id: auto_id, schedule_date: schedule_date }).then((resp) => {
      this.setState({ del_msg: resp.message, schedulemodal: false, });
      setTimeout(() => {
        this.setState({ del_msg: false });
      }, 3000);
      //alert(resp.schedule_date);
      //this.setState({ schedulemodal:true, });
      //this.scheduleModal();
    });
  }


  scheduleStartStop(auto_id, sent_status) {

    //alert(auto_id);
    if (sent_status == 0) {

      var sent_status_set = "Stop";
    } else if (sent_status == 2) {

      var sent_status_set = "Start";
    }
    this.setState({ schedule_start_stop: true, s_auto_id: auto_id, s_sent_status: sent_status, sent_statusSet: sent_status_set });
    this.scheduleStartStopModel();
  }




  tartSchedule = () => {
    alert('Hi..');
  };
  sDelete = () => {

    //alert(this.state.s_auto_id);
    api.reviews.deleteSchedule({ auto_id: this.state.s_auto_id }).then((resp) => {
      this.setState({ del_msg: resp.message, schedule_delete: false, });
      setTimeout(() => {
        this.setState({ del_msg: false });
      }, 3000);
      //
      //this.scheduleModal();
    });

  }
  sStartStop = () => {

    //alert(this.state.s_auto_id);
    api.reviews.updateStartStop({ auto_id: this.state.s_auto_id, sent_status: this.state.s_sent_status }).then((resp) => {
      this.setState({ del_msg: resp.message, schedule_start_stop: false, });
      setTimeout(() => {
        this.setState({ del_msg: false });
      }, 3000);
      //
      //this.scheduleModal();
    });

  }

  changeSwitch = () => {
    this.setState({ switchValue: 1 });
  };

  changeSwitch2 = () => {
    this.setState({ switchValue: 0 });
  };

  render() {
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content kt-grid__item kt-grid__item--fluid yr-body-p">
          <div className="row">
            {this.state.modal && this.teamInfoModal()}

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="kt-portlet kt-portlet--mobile" style={{ boxShadow: "none", backgroundColor: "transparent" }}
              >

                {/* Start-col-12 */}
                {this.state.switchValue == 0 ? (
                  <div className="col-lg-12 col-md-12 col-sm-12 px-5 ">
                    <div className="yr-manageUser-table">
                      <Segment>
                        {/*begin: Datatable */}
                        {this.state.loading && (
                          <div className="ui loading form"></div>
                        )}

                        <GridList
                          dataSource={(params) => this.getDataSourceM(params)}
                          dataColumns={this.columnLists}
                          otherParams={this.state.otherParams}
                        >
                          {/*  Start Send filter from here */}
                          {/* switch button start here */}

                          <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                            <div className="kt-form__group kt-form__group--inline">
                              <div className="kt-form__label">
                                <label></label>
                              </div>
                              <div className="kt-form__control yr-time-ShowHide">

                                <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check sent-switch">
                                  <label style={{ marginBottom: '-0.7rem' }}>
                                    <input
                                      name="modetype"
                                      type="checkbox"
                                      value='Sent'
                                      onClick={() => this.changeSwitch()}
                                    />
                                    <span />
                                  </label>
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* switch button end here */}
                          <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                            <div className="kt-form__group kt-form__group--inline">
                              <div className="kt-form__label">
                                <label></label>
                              </div>
                              <div className="kt-form__control yr-time-ShowHide">
                                <span class="custom-arrow">
                                  <select
                                    className="form-control bootstrap-select arrow-color mat-style"
                                    name="chatstatus"
                                    id="kt_form_status"
                                    onChange={(e) => this.handleChange(e, "requesttype")}
                                  >
                                    <option value="0">All</option>
                                    <option value="M">Manually</option>
                                    <option value="A">Automated</option>
                                  </select>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                            <div className="kt-form__group kt-form__group--inline">
                              <div className="kt-form__label">
                                <label></label>
                              </div>
                              <div className="kt-form__control yr-time-ShowHide">
                                <span class="custom-arrow">
                                  <select
                                    className="form-control bootstrap-select arrow-color mat-style"
                                    name="chatstatus"
                                    id="kt_form_status"
                                    onChange={(e) => this.handleChange(e, "team")}
                                  >
                                    <option value="0">All Team Member</option>
                                    {this.state.teamMembers &&
                                      this.renderTeamMember()}
                                  </select>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                            <div className="kt-form__group kt-form__group--inline">
                              <div className="kt-form__label">
                                <label></label>
                              </div>
                              <div className="kt-form__control yr-time-ShowHide">
                                <span class="custom-arrow">
                                  <select
                                    className="form-control bootstrap-select arrow-color mat-style"
                                    name="chatstatus"
                                    id="kt_form_status"
                                    onChange={(e) =>
                                      this.handleChange(e, "isOpened")
                                    }
                                  >
                                    <option value="">Opened and Unopened</option>
                                    <option value="1">Opened</option>
                                    <option value="2">Un Opened</option>
                                  </select>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                            <div className="kt-form__group kt-form__group--inline">
                              <div className="kt-form__label">
                                <label></label>
                              </div>
                              <div className="kt-form__control yr-time-ShowHide">
                                <span class="custom-arrow">
                                  <select
                                    className="form-control bootstrap-select arrow-color mat-style"
                                    name="chatstatus"
                                    id="kt_form_status"
                                    onChange={(e) =>
                                      this.handleChange(e, "isReviewed")
                                    }
                                  >
                                    <option value="0">Review Status</option>
                                    <option value="1">Reviewed</option>
                                    <option value="2">Pending</option>
                                  </select>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                            <div className="kt-form__group kt-form__group--inline">
                              <div className="kt-form__label">
                                <label></label>
                              </div>
                              <div className="kt-form__control yr-time-ShowHide">
                                <span class="custom-arrow">
                                  <DateRangePicker
                                    initialSettings={this.state.initialSettings}
                                    onApply={(event, picker) =>
                                      this.applyDateRange(event, picker)
                                    }
                                    // onEvent={this.handlePickerEvent}
                                    onChange={this.handlePickerEvent}
                                    ref={this.dateRef}
                                  >
                                    <div className="showrangepicker">
                                      <div
                                        className="form-control arrow-color mat-style"
                                        style={{ cursor: "pointer", padding: 0 }}
                                      >
                                        {" "}
                                        {this.state.selectedtext}{" "}
                                      </div>
                                    </div>
                                  </DateRangePicker>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                            <button
                              type="button"
                              class="btn linear-gradient fix-btn-size yr-submitbtn btn-sm"
                              onClick={this.downloadList}
                            >
                              Export CSV
                            </button>
                          </div>
                          {/*  End Send filter from here */}

                        </GridList>

                      </Segment>
                      <CSVLink
                        id="csvData"
                        data={this.state.csvData}
                        style={{ display: "none" }}
                        filename="team-activity.csv"
                      >
                        Download me
                      </CSVLink>
                      ;
                    </div>
                  </div>
                ) : (<div className="col-lg-12 col-md-12 col-sm-12 px-5 ">
                  {this.state.schedulemodal && this.scheduleModal()}
                  {this.state.schedule_delete && this.scheduleDeleteModel()}
                  {this.state.schedule_start_stop && this.scheduleStartStopModel()}


                  <div className="yr-manageUser-table">
                    <Segment>
                      {/*begin: Datatable This is a Auto-Rohit */}
                      {this.state.loading && (
                        <div className="ui loading form"></div>
                      )}
                      <GridList
                        dataSource={(params) => this.getDataSourceAuto(params)}
                        dataColumns={this.columnListsAuto}
                        otherParams={this.state.otherParams}
                      >
                        <div className="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                          <div className="kt-form__group kt-form__group--inline">
                            <div className="kt-form__label">
                              <label></label>
                            </div>
                            {/*  Start schedule filter from here */}

                            <div class="tbl-header-opt kt-margin-b-20-tablet-and-mobile">
                              {/* switch button start here */}
                              <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check schedule-switch">
                                <label style={{ marginBottom: '-0.7rem' }}>
                                  <input
                                    name="modetype2"
                                    type="checkbox"
                                    value="Schedule"
                                    onClick={() => this.changeSwitch2()}
                                    checked
                                  />
                                  <span />
                                </label>
                              </span>
                              {/* switch button end here */}
                            </div>
                            <div className="kt-form__control yr-time-ShowHide">
                              <span class="custom-arrow mn-120">
                                <DateRangePicker
                                  initialSettings={this.state.initialSettings}
                                  onApply={(event, picker) =>
                                    this.applyDateRange(event, picker)
                                  }
                                  // onEvent={this.handlePickerEvent}
                                  onChange={this.handlePickerEvent}
                                  ref={this.dateRef}
                                >
                                  <div className="showrangepicker">
                                    <div
                                      className="form-control arrow-color mat-style"
                                      style={{ cursor: "pointer", padding: 0 }}
                                    >
                                      {" "}
                                      {this.state.selectedtext}{" "}
                                    </div>
                                  </div>
                                </DateRangePicker>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="tbl-header-opt kt-margin-b-20-tablet-and-mobile ml-3">
                          <button
                            type="button"
                            class="btn linear-gradient fix-btn-size yr-submitbtn btn-sm "
                            onClick={this.downloadListAuto}
                          >
                            Export CSV
                          </button>
                        </div>
                        {/*  End schedule filter from here */}

                        {this.state.del_msg != '' ? (
                          <div className="col-lg-12 ui green message del-msg-padding">
                            {this.state.del_msg}
                          </div>
                        ) : (<div></div>)
                        }
                      </GridList>

                    </Segment>
                    <CSVLink
                      id="csvData2"
                      data={this.state.csvData}
                      style={{ display: "none" }}
                      filename="Schedule_User.csv"
                    >
                      Download me
                    </CSVLink>
                    ;
                  </div>
                </div>)

                }

                {/* End-col-12 */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PageMessage: state.page.message,
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setSubHeaderTitle,
  setNavigationName,
  toggleBreadcrum
})(TeamActivity);
