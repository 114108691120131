import React from "react";
import api from "../../../../../../api";
import "../../../../../Grid/grid.css";

import { Segment, Table, Pagination } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";

import ChatFilter from "./ChatFilter";
import { DatatableRow } from "./DatatableRow";
import { DatatablePageSizeSelect } from "./DatatablePageSizeSelect";
import Modal from "../../../../ChatSetup/components/Modal";


import { CSVLink, CSVDownload } from "react-csv";

import { throwStatement } from "@babel/types";



const smstableHeader = [
  {
    title: "Actions",
    width: "1",
    sort: "",
  
  },
  {
    title: "Email/Phone",
    width: "1",
    sort: 'phone_number',
  },
  {
    title: "Scheduled Date",
    width: "1",
    sort: 'schedule_date',
  },
  {
    title: "Rule",
    width: "1",
    sort: 'last_applied_rule',
  },
  
];
const tableHeader = [
  {
    title: "Actions",
    width: "1",
    sort: "",
  
  },
  {
    title: "Email/Phone",
    width: "1",
    sort: 'email',
  },
  {
    title: "Scheduled Date",
    width: "1",
    sort: 'schedule_date',
  },
  {
    title: "Rule",
    width: "1",
    sort: 'last_applied_rule',
  },
  
];
const queryParams = [
  "_limit",
  "_order",
  "_sort",
  "q",
  "_page",
  "chatstatus",
  "chatfrequency",
  "time_zone",
  "startdaterange",
  "enddaterange",
];

export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: "auto_id",
      _page: 1,
      _order: "asc",
      _limit: 10,
      q: "",
      chatstatus: "",
      chatfrequency: "",
      startdaterange: "",
      enddaterange: "",
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      modalfooter: "",
      modalBody: "",
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalstatusAddEdit: false,
      modaldata: {},
      modalFormLoader: false,
      selectedOptions: [],
      accessModuleList: [],
      auto_id: "",
      modalTranscript: "",
      csvdata: [],
      hipaaValidateObject: {},
      modalstatusHIPAA:false,
      user: props.user,
	  branch_id:'',
    sendingquemodal:false,
     playbook_id:'',

    };
    this.csvLink = React.createRef();
  }

  componentDidMount() {
    this.loadData({});
  }
  

  componentDidUpdate = (prevProps)=>{
    console.log("prevProps",prevProps.playbookStatus,"currentProps",this.props.playbookStatus)
      if(prevProps.playbookStatus !== this.props.playbookStatus){
        this.loadData({})
      }
      
  }
  

  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }

  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = (filter, val) => {
    console.log("statattata", this.state);
    if (filter == "chatfrequency") {
      this.setState({
        startdaterange: "",
        enddaterange: "",
      });
    }
    //if (filter !== this.state.q) {
    this.loadData({ [filter]: val, _page: 1 });
    // }
  };

  onSubmitFilterDateReange = (parmas) => {
    this.loadData({
      chatfrequency: parmas["chatfrequency"],
      startdaterange: parmas["startdaterange"],
      enddaterange: parmas["enddaterange"],
      _page: 1,
    });
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  modalClose = (flag, fm) => {
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
      selectedOptions: [],
    });
  };

  loadData = (params) => {
    const newState = Object.assign({}, this.state, params, { loading: false });
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;
	 params["branch_id"] = this.props.branch_id;
   params["time_zone"] = time_zone;
   params["playbook_id"] = this.props.playbook_id;
   
   console.log('this.props 2', this.props.playbook_id);
   const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.playbookdetail.getSendingQueuelist(query).then((data) => {
        try {
          let datatableRowstemp;
          this.setState({ lists: data.data.list });

          if (data.data.totalrecord) {
            console.log(data.data.list);
            datatableRowstemp = this.state.lists.map((list, index) => (
              <DatatableRow
                channgeEvent={this.checkboxChangeEvent}
                key={index}
                list={list}
                deleteEntry={this.deleteEntry}
                isWel={data.data.isWel}
                type={this.props.template_type}
              />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={7} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.data.totalrecord,
            isWel: data.data.isWel,
            lists: data.data.list,
          });
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });
    });
  };

  
 



  

  modalFooter = (type) => {};

  modalClose = () => {
    this.setState({
      modalstatus: false,
    });
  };

  modalFooter = (id, type) => {
    return (
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => this.modalClose()}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"
        >
          <span>No</span>
        </button>
        <button
          type="button"
          onClick={() => this.confirmDeleteEntry(this.state.modaldata.id)}
          className="btn linear-gradient yr-submitbtn btn-sm"
        >
          <span>Yes</span>
        </button>
      </div>
    );
  };

  modalBody = () => {
    return ('Arue u sure ?')
  };

  
  
  deleteEntry=(modal,id)=>{
    let modalBody=this.modalBody();
    let modalFooter=this.modalFooter();
     this.setState({
      modalstatus:true,
      modalfooter:modalFooter,
      modaltitle:'Confirm your action',
      modaldata:{id:id}
     
     },function(){
       this.setState({modalbody:'Are you sure you want to delete it? '})
     })
  }

  confirmDeleteEntry=(id)=>{


 api.playbookdetail.confirmDeleteEntry({id:id}).then((data) => {
  try {
    this.setState({modalstatus:false, modaldata:{id:''}})
    this.loadData({});
  } catch (err) {}
});


  }
  

  render() {
    return (
      <div className="completedchats" style={{overflow: 'unset'}}>
        <ChatFilter
          filter={{
            chatstatus: this.state.chatstatus,
            chatfrequency: this.state.chatfrequency,
            strsearch: this.state.q,
            startdaterange: this.state.startdaterange,
            enddaterange: this.state.enddaterange,
          }}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
          onSubmitFilterDateReange={this.onSubmitFilterDateReange}
          csvdata={this.state.csvdata}
          dowloadcsv={this.downloadCSV}
        />
        <Segment style={{ width:"100%", maxWidth:"calc(96vw)"  }} >
          {this.state.loading && <div className="ui loading form"></div>}

          <DatatableTable
            lists={this.state.lists}
            datatableHeader={this.props.template_type==1 ? tableHeader: smstableHeader}
            datatableRows={this.state.datatableRows}
            totalCount={this.state.totalCount}
            totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
            currentPage={this.state._page}
            onChangePage={this.onChangePage}
            column={this.state._sort}
            direction={DatatableList.directionConverter(this.state._order)}
            handleSort={this.handleSort}
            onChangeLimit={this.onChangeLimit}
            limit={this.state._limit.toString()}
            checkAll={this.checkAll}
          />
        </Segment>
        <DatatablePageSizeSelect
          limit={this.state._limit.toString()}
          onChangeLimit={this.onChangeLimit}
        />{" "}
        Showing 1 - {this.state._limit.toString()} of {this.state.totalCount}
        <Pagination
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          activePage={this.state._page}
          onPageChange={this.onChangePage}
        />
        <Modal
          show={this.state.modalstatus}
          footer={this.state.modalfooter}
          onclose={this.modalClose}
          title={this.state.modaltitle}
          body={this.state.modalbody}
          modaltranscript={this.state.modalTranscript}
        />
        <CSVLink
          id="chatcsv"
          data={this.state.csvdata}
          filename="chat.csv"
          className="hidden"
          target="_blank"
        />

         
        
      </div>
    );
  }
}
