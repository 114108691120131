import axios from "../config/axios";
import config from "../config/index";

const chatlist = {
  
  getLists: (params) =>
  axios
      .get(`/chatlist/Chatlist/getchatlist?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

  
    getTeamNotes: (params) =>
      axios
          .post('/chatlist/Chatlist/getTeamNotes', params)
          .then((resp) => resp.data)
          .catch((err) => console.log(err)),   
          
     viewDetails: (params) =>
          axios
              .post('/chatlist/Chatlist/viewDetails', params)
              .then((resp) => resp.data)
              .catch((err) => console.log(err)),    
     
     saveTeamNotes: (params) =>
              axios
                  .post('/chatlist/Chatlist/saveTeamNotes', params)
                  .then((resp) => resp.data)
                  .catch((err) => console.log(err)),

    savechatReport: (params) =>
    axios
        .post('/chatlist/Chatlist/savechatReport', params)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),               

     deleteTeamNotes: (params) =>
                  axios
                      .post('/chatlist/Chatlist/deleteNote', params)
                      .then((resp) => resp.data)
                      .catch((err) => console.log(err)),  
    

    changeVoiceChatStatus: (params) =>
        axios
            .post('/chatlist/Chatlist/changeVoiceChatStatus', params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),                        
     changeNotesStatus: (params) =>
                      axios
                          .post('/chatlist/Chatlist/changestatus', params)
                          .then((resp) => resp.data)
                          .catch((err) => console.log(err)),  
        
    getCSVData: (params) =>
                          axios
                              .post('/chatlist/Chatlist/exportChats', params)
                              .then((resp) => resp.data)
                              .catch((err) => console.log(err)), 
    
    getTrainingLog: (params) =>
        axios
            .get(`/chatlist/Chatlist/getTrainingLog?` + params, {})
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),    

    getTrainingLogVoice: (params) =>
        axios
            .get(`/chatlist/Chatlist/getTrainingLogVoice?` + params, {})
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),                                

    savechatReportVoice: (params) =>
        axios
            .post('/chatlist/Chatlist/savechatReportVoice', params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),     

    
    saveManulPushdata: (params) =>
        axios
            .post(`${config.apiBaseURL2}savechatReportVoice`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),  
                          
           

  
};

export default chatlist;
