import React, { useEffect, useState } from "react";
import { Form, Dropdown } from "formsy-semantic-ui-react";
import { Message } from "semantic-ui-react";
import validationRule from "../../../../../../validation";
import "../../../../../Modal/dialog.css";
import DatePicker from "react-datepicker";
import api from "../../../../../../api";
import util from "../../../../../../util";
import "react-datepicker/dist/react-datepicker.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
validationRule.isRequired();
validationRule.minCustomLength(6);
validationRule.usPhoneLength();

const DialogBoxAddEdit = ({
	obj,
	modalClose,
	onValidSubmit,
	type,
	onValidSubmit2,
	onValidSubmitCSV,
	handleCheck,
	checkEmail,
	checkPhoneSms,
	checkPhone,
	handleDrop,
	handleDropSms,
	onImageLoaded,
	onCropComplete,
	onCropChange,
	handleFile,
	getCroppedImg,
	handleRemovePic,
	handleRemovePicAdd,
	userdata,
	defaultCountryCodeArr,
	remailCountryCodeArr,
	bid,
	stFields,
	checkNumberFormat,
	phoneNumberValue
}) => {

	let stTempVariables = [{
		label: 'Pending Invoices',
		variables: ['name', 'email', 'phone', 'amount']
	},
	{
		label: 'Job Completed',
		variables: ['name', 'email', 'phone', 'address_line_1', 'city', 'state', 'zip_code', 'country', 'job_type', 'amount', 'residential_commerical', 'buiding_age', 'equipment_brand']
	},
	{
		label: 'Pending Estimates',
		variables: ['name', 'email', 'phone', 'job_type', 'estimate_amount']
	}
	];

	let show = obj.modalstatusAddEdit;
	let title = obj.modaltitle;
	let emailError = obj.duplicateEmailError
	let phone_number = obj.phone_number;
	let modaldata = obj.modaldata;
	let opt = modaldata.opt;
	let submitBtnName = modaldata.submitBtnName;
	let modalType = obj.modalType;
	const errorLabel = <div className="red" />;
	let currentContactData = obj.currentContactLabelDataOnEdit;

	const [stDataTrigger, setStDataTrigger] = useState(stTempVariables);
	const [stVariables, setStVariables] = useState([])
	const [startDate, setStartDate] = useState("");
	const [isdCode, setIsdCode] = useState("+1");
	const [modalFormLoader, setModalFormLoader] = useState(false);
	const [modalErrorMsg, setModalErrorMsg] = useState("none");
	const [modalErrorMsg1, setModalErrorMsg1] = useState("none");
	const [modalErrorMsgs, setModalErrorMsgs] = useState({ msg: '', display: 'none' });
	const [modalErrorMsgCSV, setModalErrorMsgCSV] = useState("none");
	const [modalErrorMsgCSVMessage, setModalErrorMsgCSVMessage] = useState("");
	const [browse, setBrowse] = useState("Browse");
	const [csvErrorDisplay, setCsvErrorDisplay] = useState("none");
	const [csvErrorMsg, setCsvErrorMsg] = useState("");
	const [csvFileValue, setCsvFileValue] = useState("");
	const [trigger, setTrigger] = useState('');
	const [saveStVariable, setSaveStVariable] = useState([]);
	const [triggerMsg, setTriggerMsg] = useState('');
	const [formError, setFormError] = useState('');
	const [buttonDisabled, setbuttonDisabled] = useState(false);
	const postcardVariable = ['First Name', 'Last Name', 'Postcard - Address Line 2', 'Postcard - Address Line 1', 'Postcard - City','Postcard - State', 'Postcard - Zip Code', 'Postcard - Country'];
	const mystyle = {
		padding: "3.25rem",
		maxHeight: "500px",
		overflow: "auto",
	};
	let redButton = {
		marginTop: "-11px",
	};

	// check sms number format

	const imageUrl = userdata.assetPath + "/teamImg/" + modaldata.team_src;
	const renderDropDownDefault = (defaultCountryCodeArr, countryId = null) => {
		console.log("KRA", defaultCountryCodeArr);
		return defaultCountryCodeArr.map((option, index) => {
			return (
				<option
					key={index}
					value={option.country_code}
					selected={countryId == option.country_code ? "selected" : ""}
				>
					{option.country_name}
				</option>
			);
		});
	};

	const renderDropDownRemaining = (remailCountryCodeArr, countryId = null) => {
		return remailCountryCodeArr.map((option, index) => {
			return (
				<option
					key={index}
					value={option.country_code}
					selected={countryId == option.country_code ? "selected" : ""}
				>
					{option.country_name}
				</option>
			);
		});
	};

	const drawLabelAndFields = (e) => {
		const currentContactLabelData = obj.currentContactLabelData;
		return currentContactLabelData.map((data, idx) => (
			<div className="form-group row">
				<label className="col-lg-5 col-form-label text-capitalize">{data.title.split('_').join(' ')} :</label>
				<div className="col-lg-7">
					{/* {type == 1 ? idx == 0 ?
						<><Form.Input
							fluid
							type={'email'}
							autoComplete="off"
							onBlur={checkEmail}
							name={data.title}
							validations="isRequired"
							validationErrors={{
								isRequired: "This field is required.",
							}}
							errorLabel={errorLabel}
						/> {obj.duplicateEmailError && (
							<div class="red" style={redButton}>
								{obj.duplicateEmailError}.
							</div>
						)}</>
						: <Form.Input
							fluid
							autoComplete="off"
							name={data.title.trim()}
						/> : (type == 3 && data.title.trim() === 'Phone Number') ?
						<Form.Input
							fluid
							autoComplete="off"
							validations="isRequired"
							validationErrors={{
								isRequired: "This field is required.",
							}}
							onChange={(e) => checkNumberFormat(e, data.title.trim())}
							errorLabel={errorLabel}
							name={data.title.trim()}
							value={phoneNumberValue}
							maxLength={13}
						/> : <Form.Input
							fluid
							autoComplete="off"
							validations="isRequired"
							validationErrors={{
								isRequired: "This field is required.",
							}}
							errorLabel={errorLabel}
							name={data.title.trim()}
						/>} */}
					
						{
							displayInputType(type, data.title)
						}
				</div>
			</div>
		));
	};

	const drawLabelAndFieldsOnEdit = (e) => {
		const currentContactLabelData = obj.currentContactLabelDetailDataOnEdit;
		const restArr = ['is_subscribed', 'field', 'source', 'subscribedText', 'toolTipText', 'status', 'added_datetime', 'unsubscribed_on_domains'];
		return Object.entries(currentContactLabelData).map((element, idx) => {
			return restArr.includes(element[0]) ? '' : <div className="form-group row">
				<label className="col-lg-5 col-form-label text-capitalize">{element[0].split('_').join(' ')} :</label>
				<div className="col-lg-7">
					{/* {type == 1 ? idx == 0 ?
						<><Form.Input
							fluid
							type={'email'}
							autoComplete="off"
							onBlur={(e) => checkEmail(e, element[1])}
							value={element[1]}
							name={element[0]}
							validations="isRequired"
							validationErrors={{
								isRequired: "This field is required.",
							}}
							errorLabel={errorLabel}
						/> {obj.duplicateEmailError && (
							<div class="red" style={redButton}>
								{obj.duplicateEmailError}.
							</div>
						)}
							<input type="hidden" name="existing_email" value={element[1]} />
						</>
						: <Form.Input
							fluid
							autoComplete="off"
							name={element[0].trim()}
							value={element[1]}
						/> : (type == 3 && element[0].trim() === 'Phone Number') ?
						<Form.Input
							fluid
							autoComplete="off"
							validations="isRequired"
							validationErrors={{
								isRequired: "This field is required.",
							}}
							onChange={(e) => checkNumberFormat(e, element[0].trim())}
							errorLabel={errorLabel}
							name={element[0].trim()}
							value={phoneNumberValue}
							maxLength={13}
						/> :
						<Form.Input
							fluid
							autoComplete="off"
							validations="isRequired"
							value={element[1]}
							validationErrors={{
								isRequired: "This field is required.",
							}}
							errorLabel={errorLabel}
							name={element[0].trim()}
						/>} */}
						{ displayInputType(type, element[0], element[1]) }
				</div>
			</div>
		});
	}

	function drawLabelAndFields2() {
		const currentContactLabelData = obj.currentContactLabelData;

		let search = currentContactLabelData.findIndex(v => v.title == "Email");
		if (search != -1) {
			delete currentContactLabelData.splice(search, 1);
		}

		return currentContactLabelData.map((element, idx) => {
			// if (type == 3 && element.title == 'Phone Number') {
			// 	return <div className="form-group row mb-3">
			// 	<label className="col-lg-4 col-form-label  mt-0 text-capitalize">{element.title.split('_').join(' ')} :</label>
			// 	<div className="col-lg-8">
			// 		<select
			// 			className="form-select st-variable-select"
			// 			name="Phone Number"
			// 			disabled
			// 		>
			// 			<option
			// 				value="phone"
			// 			>Phone Number</option>
			// 		</select>
			// 	</div>
			// </div>
			// }else{
				return element.title !== 'email' && 
			<div className="form-group row mb-3">
				<label className="col-lg-4 col-form-label  mt-0 text-capitalize">{element.title.split('_').join(' ')} :</label>
				<div className="col-lg-8">
					<select
						className="form-select st-variable-select"
						onChange={e => changeStValue(idx + 1, e)}
						name={element.title.split('_').join(' ')}
						value={(typeof saveStVariable[idx + 1] != 'undefined') ? saveStVariable[idx + 1].value : ""}
					>
						<option
							value=""
						>select field</option>
						{stVariables.map(el =>
							el !== 'email' &&
							<option
								value={el}
							>{el.split('_').join(' ')}</option>)}
					</select>
				</div>
			</div>
			// }
			
		});
	}

	const displayInputType = (type, text, ivalue = '') => {
		let flag = null;
		if((type == 1 || type == 4 || type == 5 || type == 7) && text == 'Email'){
			flag = true;
			return <>
				<Form.Input
					fluid
					type={'email'}
					autoComplete="off"
					onBlur={e => checkEmail(e, ivalue)}
					name={text}
					value={ivalue}
					validations="isRequired"
					validationErrors={{
						isRequired: "This field is required.",
					}}
					errorLabel={errorLabel}
				/> {obj.duplicateEmailError && (
					<div class="red" style={redButton}>
						{obj.duplicateEmailError}.
					</div>
				)}
			</>
		}
		if((type == 3 || type == 5 || type == 6 || type == 7) && (text == "Phone Number" || text == "Phone_Number")){
			flag = true;
			return <Form.Input
				fluid
				autoComplete="off"
				validations="isRequired"
				validationErrors={{
					isRequired: "This field is required.",
				}}
				onChange={(e) => checkNumberFormat(e, text.trim())}
				errorLabel={errorLabel}
				name={text.trim()}
				defaultValue={ivalue}
				value={phoneNumberValue}
				maxLength={13}
			/>
		}		
		if((type == 2 || type == 4 || type == 6 || type == 7) && (postcardVariable.includes(text.trim()))){
			flag = true;
			return <Form.Input
				fluid
				autoComplete="off"
				validations="isRequired"
				value={ivalue}
				validationErrors={{
					isRequired: "This field is required.",
				}}
				errorLabel={errorLabel}
				name={text.trim()}
			/>
		}

		if(flag == null){
			return <Form.Input
				fluid
				autoComplete="off"
				name={text.trim()}
				value={ivalue}
			/>
		}
	}

	const FiledsArr = (e) => {
		const currentContactLabelData = obj.currentContactLabelData;
		return currentContactLabelData.map(({ title, type, hid }, idx) => (
			<span class="csvColumnFields">{title}</span>
		));
	}

	const handleDropHere = (e) => {
		setIsdCode(e.target.value);
	}

	const onValidSubmitHere = (sendData) => {
		let loginVia=util.getCookie("loginVia");
        let fsendData=Object.assign({},sendData,{loginvia:loginVia});
		setModalFormLoader(true);
		api.marketing.addContactList(fsendData).then((data) => {
			// this.setState({ modalFormLoader: false });
			setModalFormLoader(false);

			try {
				console.log(data);
				if (data.status == false && data.message == "alreadyExist") {
					setModalErrorMsg("block");
					setTimeout(function () {
						setModalErrorMsg("none");
					}, 5000);
					document.getElementById("AddEditContactFormModalBody").scroll({
						top: 0,
						behavior: "smooth", // 👈
					});
				} else if (data.status == false && data.message == "InvalidEmail") {
					setModalErrorMsg1("block");
					setTimeout(function () {
						setModalErrorMsg1("none");
					}, 5000);
					document.getElementById("AddEditContactFormModalBody").scroll({
						top: 0,
						behavior: "smooth", // 👈
					});
				} else {
					if (data.status == false) {
						setModalErrorMsg1("block");
						setFormError(data.data);
						setTimeout(function () {
							setModalErrorMsg1("none");
						}, 5000);
						document.getElementById("AddEditContactFormModalBody").scroll({
							top: 0,
							behavior: "smooth", // 👈
						});
					} else {
						onValidSubmit(sendData);
					}
				}
			} catch (err) {
				console.log(err);
			}
		});
	};

	const saveStVariables = () => {
		console.log("onsave", saveStVariable);
		var stVar = saveStVariable.map(el => { if (typeof el != 'undefined') return { bid: bid, key: el.title, value: el.title !== "email" ? el.value : 'email' } });
		if (trigger == '' || trigger == null) {
			setTriggerMsg('This field is required');
		} else {
			if (stVar.length == 0) {
				stVar = [{ bid: bid, title: 'Email', value: 'email' }];
			}
			api.marketing.saveServiceTItanContact({ variableValue: stVar })
				.then(res => {
					if (res.status) {
						onValidSubmit2(res.msg);
						api.marketing.saveServiceTitanBucketTrigger({ bid: bid, dataTrigger: trigger })
							.then(res => {
								console.log(res)
							}).catch(err => console.log(err))
					} else {
						onValidSubmit2(res.msg);
					}
				}).catch(err => console.log('err'))

		}
	}

	const updateStVariables = () => {
		api.marketing.saveServiceTItanContact({ variableValue: saveStVariable })
			.then(res => {
				if (res.status) {
					onValidSubmit2(res.msg);
				} else {
					onValidSubmit2(res.msg);
				}
			})
			.catch(err => console.log('err'))
	}

	const onValidSubmitEditHere = (sendData) => {
		sendData["bid"] = bid;
		sendData["cid"] = currentContactData.cid;
		sendData["user_id"] = userdata.user_id;
		sendData["source"] = "static";

		// console.log(sendData)
		// return;

		// if (modalErrorMsg == "block" || modalErrorMsg1 == "block") {
		if (!emailError) {
			setModalFormLoader(true);
			setbuttonDisabled(true);
			api.marketing.editContact(sendData).then((data) => {
				// this.setState({ modalFormLoader: false });
				setModalFormLoader(false);

				try {
					console.log(data);
					setbuttonDisabled(false);
					if (data.status == false && data.message == "alreadyExist") {
						setModalErrorMsg("block");
						setTimeout(function () {
							setModalErrorMsg("none");
						}, 5000);
						document.getElementById("AddEditContactFormModalBody").scroll({
							top: 0,
							behavior: "smooth", // 👈
						});
					} else if (data.status == false && data.message == "InvalidEmail") {
						setModalErrorMsg1("block");
						setTimeout(function () {
							setModalErrorMsg1("none");
						}, 5000);
						document.getElementById("AddEditContactFormModalBody").scroll({
							top: 0,
							behavior: "smooth", // 👈data.message == "InvalidEmail"
						});
					} else {
						if (data.status == false) {
							setModalErrorMsg1("block");
							setFormError(data.message);
							setTimeout(function () {
								setModalErrorMsg1("none");
							}, 5000);
							document.getElementById("AddEditContactFormModalBody").scroll({
								top: 0,
								behavior: "smooth", // 👈data.message == "InvalidEmail"
							});
						} else {
							onValidSubmit(sendData);
						}
					}
				} catch (err) {
					setbuttonDisabled(false);
					console.log(err);
				}
			});
		}
		// onValidSubmit(e)
	};

	const onValidSubmitHereForCSV = (e) => {
		let loginVia=util.getCookie("loginVia");
		setModalFormLoader(true);
		setbuttonDisabled(true);
		if (csvErrorDisplay == "block") {
			setbuttonDisabled(false);
			return true;
		}

		if (csvFileValue == "") {
			setCsvErrorDisplay("block");
			setCsvErrorMsg("Please upload csv file.");
			setbuttonDisabled(false);
			return true;
		}

		var myform = document.getElementById("AddEditContactFormModalCSV");
		var formData = new FormData(myform);

		formData.append("bid", bid);
		formData.append("user_id", userdata.user_id);
		formData.append("loginvia", loginVia);

		api.marketing.addContactListByCSV(formData).then(data => {
			setModalFormLoader(false);
			setbuttonDisabled(false);
			console.log(data.data.status, data.data.message, 'error field data')
			try {
				if (
					data.data.status === false &&
					data.data.message == "headingMismatch"
				) {
					setModalErrorMsgCSVMessage(
						"CSV heading are not matching as given below titles."
					);
					setModalErrorMsgCSV("block");
					setTimeout(function () {
						setModalErrorMsgCSV("none");
					}, 5000);
					document.getElementById("AddEditContactFormModalBody").scroll({
						top: 0,
						behavior: "smooth", // 👈
					});
				}else if(
					data.data.status === false &&
					data.data.message ==  "limitExceeds"
				) {
					setModalErrorMsgCSVMessage(
						data.data.data
					);
					setModalErrorMsgCSV("block");
					setTimeout(function () {
						setModalErrorMsgCSV("none");
					}, 5000);
					document.getElementById("AddEditContactFormModalBody").scroll({
						top: 0,
						behavior: "smooth", // 👈
					});
				} else {
					document.getElementById("file-upload").value = "";

					setCsvErrorDisplay("none");
					setBrowse("Browse");
					setCsvFileValue("");
					onValidSubmitCSV(data.data);
				}
			} catch (err) {
				console.log(err);
			}
		});
	};

	const modalCloseHere = (e) => {
		setCsvErrorDisplay("none");
		setBrowse("Browse");
		setCsvFileValue("");
		modalClose(false, "AddEditContactFormModal");
		try {
			document.getElementById("file-upload").value = "";
		} catch (e) { }
	};

	const changeFileInputCSV = (e) => {
		setCsvErrorDisplay("none");

		try {
			var fileName = e.target.files[0].name;
			var index = fileName.split(".")[fileName.split(".").length - 1];
			if (index.toLowerCase() == "csv") {
			} else {
				setCsvErrorDisplay("block");
				setCsvErrorMsg("Please upload only csv file.");
			}
			setCsvFileValue(e.target.files[0].name);
			setBrowse(e.target.files[0].name);
		} catch (e) {
			setBrowse("Browse");
			setCsvFileValue("");
		}
		// console.log(e.target.files[0].name)
	};

	const changeStValue = (idx, values) => {
		let temp = [...saveStVariable];
		console.log('f',temp);
		console.log("1", idx, temp, values.target.name, values.target.value);
		if (typeof temp[idx] == 'undefined') {

			temp[idx] = {};
			temp[0] = { title: type == 3 ? 'Phone Number' : 'Email', value: type == 3 ? 'phone' : 'email' }
		}
		temp[idx].title = values.target.name;
		temp[idx].value = values.target.value;
		setSaveStVariable(temp)
	}

	const changeSelectOption = e => {
		setStVariables(stDataTrigger[e - 1].variables);
		setTrigger(e)
		setTriggerMsg('');
	}
	
	useEffect(() => {
		api.marketing
			.getContactLabel({
				bid: bid,
				user_id: userdata.user_id,
			})
			.then((data) => {
				let currentData = data.data;

				// setSaveStVariable(data.data);
				api.marketing.getServiceTitanBucketHeading({ bid: bid })
					.then((res) => {
						console.log("curd", currentData, res.headingList)
						if (res.status) {
							let temp1 = res.headingList;
							let tempST = currentData.map((ev, idd) => { let ind = idd; return { ...ev, value: (typeof temp1[ind] == 'undefined' || temp1[ind] == null) ? '' : temp1[ind].value } });
							console.log("sther", tempST, currentData, temp1);
							setSaveStVariable(tempST);
						} else {
							setSaveStVariable(currentData);
						}
					}).catch(err => console.log(err))

				api.marketing.getServiceTitanBucketTrigger({ bid: bid }).then((res) => {
					if (res.status) {
						let dataTriggerValue = res.bucketTrigger;
						setTrigger(dataTriggerValue);
						setStVariables(stDataTrigger[dataTriggerValue - 1].variables);
					}
				}).catch(err => console.log(err))
			});
	}, [])

	return (
		<React.Fragment>
			<div
				className={show ? "modal fade show" : "modal fade"}
				style={{ display: show ? "block" : "none" }}
				id="AddEditContactModal"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalCloseHere()}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
						</div>
						{modalType == "Add" ? (
							opt == "static" ? (
								<Form
									noValidate
									autoComplete="off"
									id="AddEditContactFormModal"
									onValidSubmit={onValidSubmitHere}
								>
									{modalFormLoader && <div className="ui loading form"></div>}
									<div
										id="AddEditContactFormModalBody"
										className="modal-body"
										style={mystyle}
									>
										<div className="kt-portlet__body py-0">
											<div className="kt-section kt-section--first">
												<Message color="red" style={{ display: modalErrorMsg }}>
													This email-id already exists.
												</Message>
												<Message color="red" style={{ display: modalErrorMsg1 }}>
													{formError != '' ? formError : 'This email-id is invalid.'}
												</Message>
												<div className="kt-section__body">
													{drawLabelAndFields()}
												</div>
											</div>
										</div>
									</div>
									<div className="kt-portlet__foot">
										<div className="kt-form__actions">
											<div className="row">
												<div className="col-lg-12 d-flex justify-content-end">
													<button
														type="button"
														onClick={() => modalClose(false, "formManageUser")}
														className="btn linear-gradient-cancel yr-cancelbtn mg-r"
													>
														<span> Cancel </span>
													</button>
													<button type="submit" className="btn linear-gradient yr-submitbtn">
														{submitBtnName}
													</button>
													<Form.Input type="Hidden" name="bid" value={bid} />
													<Form.Input
														type="Hidden"
														name="user_id"
														value={userdata.user_id}
													/>
													<Form.Input
														type="Hidden"
														name="source"
														value={"static"}
													/>
												</div>
											</div>
										</div>
									</div>
								</Form>

							) : opt == "ServiceTitan" ? (<Form
								noValidate
								autoComplete="off"
								id="AddEditContactFormModal"
								onValidSubmit={saveStVariables}
							>
								{modalFormLoader && <div className="ui loading form"></div>}
								<div
									id="AddEditContactFormModalBody"
									className="modal-body"
									style={mystyle}
								>
									<div className="kt-portlet__body py-0">
										<div className="kt-section kt-section--first">

											<div className="kt-section__body">
												<label>Select Data Trigger</label>
												<select className="form-control" value={trigger} onChange={e => changeSelectOption(e.target.value)}>
													<option selected disabled>Choose data trigger</option>
													{
														stDataTrigger.map((el, index) => (
															<option value={index + 1}>{el.label}</option>
														))
													}
												</select>
												{triggerMsg != "" && <div className="col-md-12 px-0 py-2" style={{ color: "red" }}>{triggerMsg}</div>}
												<br />
												{type != 3 &&
													<div className="form-group row mb-3">
														<label className="col-lg-4 col-form-label  mt-0">Email :</label>
														<div className="col-lg-8">
															<select className="form-select" disabled>
																<option value="email">Email</option>
															</select>
														</div>
													</div>
												}
												
												{drawLabelAndFields2()}
											</div>
										</div>
									</div>
								</div>
								<div className="kt-portlet__foot">
									<div className="kt-form__actions">
										<div className="row">
											<div className="col-lg-12 d-flex justify-content-end">
												<button
													type="button"
													onClick={() => modalClose(false, "formManageUser")}
													className="btn linear-gradient-cancel yr-cancelbtn mg-r"
												>
													<span> Cancel </span>
												</button>
												<button type="submit" className="btn linear-gradient yr-submitbtn">
													{submitBtnName}
												</button>
												<Form.Input type="Hidden" name="bid" value={bid} />
												<Form.Input
													type="Hidden"
													name="user_id"
													value={userdata.user_id}
												/>
												<Form.Input
													type="Hidden"
													name="source"
													value={"static"}
												/>
											</div>
										</div>
									</div>
								</div>
							</Form>) : (
								<Form
									noValidate
									autoComplete="off"
									id="AddEditContactFormModalCSV"
									onValidSubmit={onValidSubmitHereForCSV}
								>
									<div class="kt-portlet__body">
										<div class="kt-section kt-section--first">
											<div class="kt-section__body" style={{ padding: 20 }}>
												<Message
													color="red"
													style={{ display: modalErrorMsgCSV }}
												>
													{modalErrorMsgCSVMessage}
												</Message>
												<div class="form-group row">
													<label
														class="col-lg-12 col-form-label"
														style={{ color: "#20536b" }}
													>
														Please browse CSV. Before uploading CSV please make
														sure your CSV must have column heading and your
														column heading must match below titles.{" "}
													</label>
													<label
														class="col-lg-12"
														style={{ maxHeight: 104, overflow: "auto" }}
													>
														{FiledsArr()}
													</label>
												</div>

												<div class="form-group row automatedMarketing">
													<div class="col-lg-12">
														<div class="yr-workspace">
															<div class="uploadfile">
																<label
																	for="file-upload"
																	class="custom-file-upload"
																>
																	<i class="fa fa-cloud-upload"></i>
																	<div class="mh-CSVbrowse" title={browse}>
																		{browse}
																	</div>
																	<div
																		class="mh-CSVbrowseError"
																		style={{ display: csvErrorDisplay }}
																	>
																		{csvErrorMsg}
																	</div>
																</label>
																<input
																	id="file-upload"
																	onChange={changeFileInputCSV}
																	name="contact_csv"
																	type="file"
																	onClick={(event) => {
																		event.target.value = null
																	}}
																	style={{ display: "none" }}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="kt-portlet__foot">
										<div class="kt-form__actions">
											<div class="row">
												<div class="col-lg-12 d-flex justify-content-end">
													<button
														onClick={() => modalCloseHere()}
														type="reset"
														class="btn yr-cancelbtn linear-gradient-cancel mg-r"
													>
														<span> Cancel </span>
													</button>
													<button disabled={buttonDisabled} type="submit" class="btn linear-gradient yr-submitbtn">
														{submitBtnName}
													</button>
												</div>
											</div>
										</div>
									</div>
								</Form>
							)
						) : (
							<Form
								noValidate
								autoComplete="off"
								id="AddEditContactFormModalEdit"
								onValidSubmit={onValidSubmitEditHere}
							>
								{modalFormLoader && <div className="ui loading form"></div>}
								<div
									id="AddEditContactFormModalBody"
									className="modal-body"
									style={mystyle}
								>
									<div className="kt-portlet__body py-0">
										<div className="kt-section kt-section--first">
											<Message color="red" style={{ display: modalErrorMsg }}>
												This email-id already exists.
											</Message>
											<Message color="red" style={{ display: modalErrorMsg1 }}>
												{formError != '' ? formError : 'This email-id is invalid.'}
											</Message>
											<div className="kt-section__body">
												{drawLabelAndFieldsOnEdit()}
											</div>
										</div>
									</div>
								</div>
								<div className="kt-portlet__foot">
									<div className="kt-form__actions">
										<div className="row">
											<div className="col-lg-12 d-flex justify-content-end">
												<button
													type="button"
													onClick={() => modalClose(false, "formManageUser")}
													className="btn linear-gradient-cancel yr-cancelbtn mg-r"
												>
													<span> Cancel </span>
												</button>
												<button disabled={buttonDisabled} type="submit" className="btn linear-gradient yr-submitbtn">
													{submitBtnName}
												</button>
											</div>
										</div>
									</div>
								</div>
							</Form>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogBoxAddEdit;
