import React, { useCallback } from "react";
import CustomErrorField from "../../../Form/CustomErrorField";
import ContentEditable from 'react-contenteditable'

const editorConfiguration = {
    toolbar: ['bold', 'fontSize', 'fontFamily', 'fontColor', 'fontBackground', 'undo', 'redo']
};

class Rules extends React.Component {

    renderOption = () => {
        let wplist = this.props.customizeinfo.workspaceList;
        if (typeof wplist != 'undefined') {
            return Object.keys(wplist).map(function (key) {
                return <option key={key} value={key} >{wplist[key]}</option>;
            });
        }
    };

    renderOptionPopup = () => {
        let popupStartList = this.props.customizeinfo.chatPopupStartList;
        if (typeof popupStartList != 'undefined') {
            return Object.keys(popupStartList).map(function (key) {
                return <option key={key} value={key} >{popupStartList[key] > 0 ? popupStartList[key] + ' Seconds' : popupStartList[key]}</option>;
            });
        }
    };


    render() {
        const { errors } = this.props
        return (
            <div className="yr-chat-round-box width-30 yr-max-height">
                <div className="box-title">Other Rules</div>
                <div className="form-group row" style={{ marginBottom: 10 }}>
                    <label className="col-8 col-form-label" style={{ lineHeight: "2" }}>Chat auto pop up</label>
                    <div className="col-4">
                        <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                            <label>
                                <input type="checkbox" name="chatPopup" defaultChecked={this.props.customizeinfo.chatPopup == 'ON' || this.props.customizeinfo.chatPopup == '' ? true : false} onChange={this.props.onchange}  dataOnText="normal"
       dataOffText="abnormal" />
                                <span />
                            </label>
                        </span>
                    </div>
                </div>

                {/* <div className="form-group row" style={{ marginBottom: 10 }}>
                    <label className="col-8 col-form-label" style={{ lineHeight: "2" }}>Send incompleted chats</label>
                    <div className="col-4">
                        <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                            <label>
                                <input type="checkbox" name="gptIncompletePopup" defaultChecked={this.props.customizeinfo.gptIncompletePopup == 'ON' ? true : false} onChange={this.props.onchange}  dataOnText="normal"
       dataOffText="abnormal" />
                                <span />
                            </label>
                        </span>
                    </div>
                </div> */}
                <div className="form-group">
                    <label className="bold">How long will it take to pop up?</label>
                    <div className="yr-selectArrow">
                        <select className="form-control" name='chatPopupStart' value={this.props.customizeinfo.chatPopupStart} onChange={this.props.onchange}>
                            {this.renderOptionPopup()}
                        </select>
                    </div>
                </div>
                <div className="form-group" style={{ marginBottom: 10 }}>
                    <label className="bold">Pop up</label>
                    <div className="kt-checkbox-list">
                        <label className="kt-radio inalign">
                            <input type="radio" name='chatPopupState' value='0' defaultChecked={this.props.customizeinfo.chatPopupState == 0 ? true : false} onChange={this.props.onchange} /> Once during a web session                                <span />
                        </label>
                        <label className="kt-radio inalign">
                            <input type="radio" name='chatPopupState' value='1' defaultChecked={this.props.customizeinfo.chatPopupState == 1 ? true : false} onChange={this.props.onchange} /> Everytime the site loads
                            <span />
                        </label>
                    </div>
                </div>
                
                <div className="form-group row" style={{ marginBottom: 10 }}>
                    <label className="col-8 col-form-label" style={{ lineHeight: "2" }}>Let's Talk auto pop up</label>
                    <div className="col-4">
                        <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                            <label>
                                <input type="checkbox" name="letsTalkPopup" defaultChecked={this.props.customizeinfo.letsTalkPopup == 'ON' || this.props.customizeinfo.letsTalkPopup == '' ? true : false} onChange={this.props.onchange} />
                                <span />
                            </label>
                        </span>
                    </div>
                </div>
                {this.props.customizeinfo.letsTalkPopup === 'ON' &&
                    <>
                <div className="form-group" style={{ marginBottom: 10 }}>
                    <label className="bold">Let's Talk Pop up (Web)</label>
                    <div className="kt-checkbox-list">
                        <label className="kt-radio inalign">
                            <input type="radio" name='lets_talk_popup_web' value='0' defaultChecked={this.props.customizeinfo.lets_talk_popup_web == 0 ? true : false} onChange={this.props.onchange} /> Once during a web session                                <span />
                        </label>
                        <label className="kt-radio inalign">
                            <input type="radio" name='lets_talk_popup_web' value='1' defaultChecked={this.props.customizeinfo.lets_talk_popup_web == 1 ? true : false} onChange={this.props.onchange} /> Everytime the site loads
                            <span />
                        </label>
                    </div>
                    <div className="form-group">
                        <label style={{ marginBottom: 10 }}>Display Text</label>
                        {/* <SunEditor
                            setOptions={{
                                height: 100,
                                width: '100%',
                                buttonList: [["fontSize"], ["fontColor"]],
                            }}
                            name="lets_talk_web"
                            defaultValue={this.props.customizeinfo.lets_talk_web}
                            setContents={this.props.customizeinfo.lets_talk_web}
                            onChange={(content) => this.props.handleSunEditor(content, 'lets_talk_web')}
                            
                        /> */}
                        <div className="txtEditorTab">
                            <div className="tab--header">
                                        <select title="select font size" onChange={(e) => this.props.changeTextStyle(e, 'text_style_web', 'fontSize')}>
                                    <option selected disabled>--size--</option>
                                    <option value="10">10</option>
                                    <option value="10">11</option>
                                    <option value="12">12</option>
                                    <option value="14">14</option>
                                    <option value="16">16</option>
                                    <option value="18">18</option>
                                    <option value="20">20</option>
                                </select>
                                        <input type="color" title="select text color" onChange={(e) => this.props.changeTextStyle(e, 'text_style_web', 'color')} />
                            </div>
                            <div className="tab--body">
                                        <input
                                            style={this.props.customizeinfo.text_style_web ? this.props.customizeinfo.text_style_web : {}}
                                            type="text"
                                            name='lets_talk_web'
                                            value={this.props.customizeinfo.lets_talk_web}
                                            onChange={this.props.onchange}
                                            className={
                                                errors && errors['lets_talk_web']
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            }
                                />
                            </div>
                        </div>
                        {errors && errors.hasOwnProperty('lets_talk_web') && (
                            <CustomErrorField
                                message={errors['lets_talk_web']}
                                className="error invalid-feedback d-inline"
                            />
                        )}

                    </div>
                </div>
                <div className="form-group" style={{ marginBottom: 10 }}>
                    <div className="kt-checkbox-list">
                        <label className="kt-checkbox inalign">
                            <input type="checkbox" name='is_different_lets_talk' value='1' defaultChecked={this.props.customizeinfo.is_different_lets_talk == 0 ? false : true} onChange={this.props.onchange} /> I want different rules on mobile. <span />
                        </label>
                    </div>
                </div>
                {this.props.customizeinfo.is_different_lets_talk == 1 &&
                    <div className="form-group" style={{ marginBottom: 10 }}>
                        <label className="bold">Let's Talk Pop up (Mobile)</label>
                        <div className="kt-checkbox-list">
                            <label className="kt-radio inalign">
                                <input type="radio" name='lets_talk_popup_mobile' value='0' defaultChecked={this.props.customizeinfo.lets_talk_popup_mobile == 0 ? true : false} onChange={this.props.onchange} /> Once during a web session                                <span />
                            </label>
                            <label className="kt-radio inalign">
                                <input type="radio" name='lets_talk_popup_mobile' value='1' defaultChecked={this.props.customizeinfo.lets_talk_popup_mobile == 1 ? true : false} onChange={this.props.onchange} /> Everytime the site loads
                                <span />
                            </label>
                        </div>
                        <div className="form-group">
                            <label style={{ marginBottom: 10 }}>Display Text</label>

                                    <div className="txtEditorTab">
                                        <div className="tab--header">
                                            <select title="select font size" onChange={(e) => this.props.changeTextStyle(e, 'text_style_mobile', 'fontSize')}>
                                                <option selected disabled>--size--</option>
                                                <option value="10">10</option>
                                                <option value="10">11</option>
                                                <option value="12">12</option>
                                                <option value="14">14</option>
                                                <option value="16">16</option>
                                                <option value="18">18</option>
                                                <option value="20">20</option>
                                            </select>
                                            <input type="color" title="select text color" onChange={(e) => this.props.changeTextStyle(e, 'text_style_mobile', 'color')} />
                                        </div>
                                        <div className="tab--body">
                                            <input
                                                style={this.props.customizeinfo.text_style_mobile ? this.props.customizeinfo.text_style_mobile : {}}
                                                type="text"
                                                name='lets_talk_mobile'
                                                value={this.props.customizeinfo.lets_talk_mobile}
                                                onChange={this.props.onchange}
                                                className={
                                                    errors && errors['lets_talk_mobile']
                                                        ? "form-control is-invalid"
                                                        : "form-control"
                                                }
                                            />
                                        </div>
                                    </div>
                            {errors && errors.hasOwnProperty('lets_talk_mobile') && (
                                <CustomErrorField
                                    message={errors['lets_talk_mobile']}
                                    className="error invalid-feedback d-inline"
                                />
                            )}
                        </div>
                    </div>
                }
                    </>
                }



                {/* <div className="form-group row" style={{ marginBottom: 10 }}>
                    <label className="col-8 col-form-label" style={{ lineHeight: "2" }}>Dynamic number insertion</label>
                    <div className="col-4">
                        <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                            <label>
                                <input type="checkbox" name="isdynamicCall" defaultChecked={this.props.customizeinfo.isdynamicCall == 'ON' ? true : false} onChange={this.props.onchange} />
                                <span />
                            </label>
                        </span>
                    </div>
                </div> */}
                <div className="form-group row" style={{ marginBottom: 10 }}>
                    <label className="col-8 col-form-label" style={{ lineHeight: "2" }}>Allow a prior website visitor to continue their chat?&nbsp;<span data-inverted=""  data-tooltip="Allow a prior website visitor to continue their chat? If this is marked NO, the chat will reset when they leave your website or browse through different pages." data-variation="mini"><i className="fa fa-info-circle" aria-hidden="true" style={{cursor:'pointer'}}></i></span></label>
                    <div className="col-4">
                        <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                            <label>
                                <input type="checkbox" name="previous_chat" defaultChecked={this.props.customizeinfo.previous_chat == '1'  ? true : false} onChange={this.props.onchange} />
                                <span />
                            </label>
                        </span>
                    </div>
                </div>
                
             
                <div className="form-group" style={{ marginBottom: 10 }}>
                    <label style={{ marginBottom: 10 }}>Company Name</label>
                    <input type="text"
                        name='company'
                        value={this.props.customizeinfo.company}
                        onChange={this.props.onchange}
                        className={
                            errors && errors['company']
                                ? "form-control is-invalid"
                                : "form-control"
                        }
                    />
                    {errors && errors.hasOwnProperty('company') && (
                        <CustomErrorField
                            message={errors['company']}
                            className="error invalid-feedback"
                        />
                    )}
                </div>

                {/* <div className="form-group focusError" style={{marginBottom: 10}}>
                            <label style={{marginBottom: 10}}>First message for your bot</label>
                            <textarea  
                            name='openMessage' 
                            onChange={this.props.onchange} 
                            value={this.props.customizeinfo.openMessage} 
                            className={
                                errors && errors['openMessage']
                                    ? "form-control is-invalid"
                                    : "form-control"
                            }
                            />
                            {errors && errors.hasOwnProperty('openMessage') && (
                                <CustomErrorField
                                    message={errors['openMessage']}
                                    className="error invalid-feedback"
                                />
                            )}
                        </div> */}

                {/* <div className="form-group focusError" style={{ marginBottom: 10 }}>
                    <label style={{ marginBottom: 10 }}>Phone Number</label>
                    {this.props.customizeinfo.phoneList && this.props.customizeinfo.phoneList.map((x, y) => {
                        return (
                            <div key={y} style={{ display: 'flex', marginBottom: 10 }}>
                                <div style={{ width: 47 + '%' }}>
                                    <input type="text"
                                        value={x.phone}
                                        name={"phonelist[" + y + "]"}
                                        onChange={(e) => this.props.handlePhoneformat(y, e)}
                                        placeholder="(_ _ _)_ _ _-_ _ _"
                                        className={
                                            errors && errors["phonelist[" + y + "]"]
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        }
                                        style={{ marginRight: 7 }}
                                    />
                                    {errors && errors.hasOwnProperty("phonelist[" + y + "]") && (
                                        <CustomErrorField
                                            message={errors["phonelist[" + y + "]"]}
                                            className="error invalid-feedback"
                                        />
                                    )}
                                </div>
                                <div style={{ width: 47 + '%', marginLeft: 10 }}>
                                    <input type="text"
                                        className="form-control"
                                        value={x.text}
                                        name={"text[" + y + "]"}
                                        onChange={(e) => this.props.handlePhoneText(y, e)}
                                        className={
                                            errors && errors["text[" + y + "]"]
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        }
                                    />
                                    {errors && errors.hasOwnProperty("text[" + y + "]") && (
                                        <CustomErrorField
                                            message={errors["text[" + y + "]"]}
                                            className="error invalid-feedback"
                                        />
                                    )}
                                </div>
                                <div style={{ width: 6 + '%' }}>
                                    {y != 0 && (<a onClick={(e) => this.props.deleteMorePhone(y, e)}
                                        title='Delete'
                                        className='btn btn-icon gray'
                                        style={{ marginRight: -18 }}
                                    >
                                        <i className='la la-trash'></i>
                                    </a>)}
                                </div>
                            </div>
                        )
                    })}
                </div> */}
                {/* <span className="add-gradient form-group" onClick={this.props.addMorePhone}>+Add Variable</span> */}
                {/* <div className="form-group">
                    <label>Select which automation workspace to use</label>
                    <div className="yr-selectArrow">
                        <select className="form-control" name='cahtVersion' value={this.props.customizeinfo.cahtVersion} onChange={this.props.onchange}>
                            {this.renderOption()}
                        </select>
                    </div>
                    <a className="add-gradient" href="/automation">Click to edit</a>
                </div> */}


            </div>
        );
    };
}

export default Rules;
