import React from "react";
import { connect } from "react-redux";
import { Table } from 'semantic-ui-react';
import api from "../../../api";
// import DesignRules from './DesignRules';
import Notes from './Notes';
import { Message } from "semantic-ui-react";
import {
    setPageTitle,
    toggleNavigation,
    setMessage,
    setBreadCrum,
} from "../../../store/Page";
const queryString = require("query-string");

class ViewDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data:this.props.detaildata,
            dataold:this.props.detaildata,
            opacity:"100%",
            translate:false
        }

    }
    translate = () => {
        if(this.state.translate) return;
        api.transcriptchatlist.viewVoiceChatDetails({ auto_id: this.props.auto_id, type: 'question_answer',translate:true }).then((data) => {
			try {
				if(data.status){
					this.setState({
						data:data.data.dataTrans,
                        opacity:"20%",
                        translate:true
					});
				}
			} catch (err) {
			 }
		});
    }
    render() {
        const { auto_id }=this.props
        return (
            <div>
            <div className="ui loading form chatstatusloadingdiv" style={{display:"none"}}></div>
            <div className="translate-bn d-flex justify-content-end">
            <button type="button" className="btn linear-gradient " onClick={() => this.translate()} style={{ opacity: this.state.opacity }}>Translate in english</button>
            </div>
            <div className="ui green message chatstatusloadingdivsuccess"  style={{display:"none",marginTop: "30px"}}></div>
            <div >
            <div  dangerouslySetInnerHTML={{__html:this.state.data}}  className="newmodalscroll">
            </div> 
               {!this.props.summary && <Notes auto_id={auto_id} commentform="true" tableref="voice_log" updatenote={this.props.updatenote}></Notes>}
                
            </div>
            </div>
            
        );
    }
}
export default ViewDetails