import React from "react";
import {
    setPageTitle,
    toggleNavigation,
    toggleBreadcrum,
    toggleinnerPageLoader,
} from "../../../../store/Page";
import "../../../Modal/dialog.css";
import api from "../../../../api";
import config from "../../../../config";
import util from "../../../../util";
import utilFunc from "../../../../util_funs";
import CustomErrorField from "../../../Form/CustomErrorField";
import { Input, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import DatatableList from "./Bucketlist/DatatableList";
import CheckboxTree from 'react-checkbox-tree';

import "../../Marketing/yr-style.css";
//import "./callList.css";
import "./contactMultipleDropdown.css";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { arrayMove } from "react-sortable-hoc";
//const options = [];
class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disabledSTButtion: false,
      errors: {},
      bucketList: [],
        contact_email_msg: `Let's import some contacts! Please choose a CSV file and separate each column with relevant information.<br />For each contact, in addition to the Email address, you can include other properties (First Name, Last Name, Company Name, Phone Number, SMS Number, etc.) <br /> <a href='/assets/sample.csv'> Download our sample CSV file</a> to structure your import.`,
        contact_sms_msg: `Let's import some contacts! Please choose a CSV file and separate each column with relevant information.<br />For each contact, you can include some mandatory properties like (First Name, Last Name, Phone Number) <br /> <a href='/assets/sms-sample.csv'> Download our sample CSV file</a> to structure your import.`,
        contact_postcard_msg:
        "Let's import some contacts! Please choose a CSV file and separate each column with relevant information. For each contact, in addition to the Email address, You can only add (First Name, Last Name, Postcard - Address Line 1, Postcard - Address Line 2, Postcard - City, Postcard - State, Postcard - Zip Code, Postcard - Country). <a href='/assets/Postcard-sample.csv'> Download our sample CSV file</a> to structure your import.",
        contact_email_postcard_sms_msg: `Let's import some contacts! Please choose a CSV file and separate each column with relevant information.<br />For each contact, in addition to the Phone number, you can include other properties (First Name, Last Name) <br /> <a href='/assets/email_postcard_sms-sample.csv'> Download our sample CSV file</a> to structure your import.`,
        contact_email_postcard_msg: `Let's import some contacts! Please choose a CSV file and separate each column with relevant information.<br />For each contact, in addition to the Phone number, you can include other properties (Email,First Name, Last Name, Postcard - Address Line 1, Postcard - Address Line 2, Postcard - City, Postcard - State, Postcard - Zip Code, Postcard - Country) <br /> <a href='/assets/email_postcard-sample.csv'> Download our sample CSV file</a> to structure your import.`,
        contact_email_sms_msg: `Let's import some contacts! Please choose a CSV file and separate each column with relevant information.<br />For each contact, in addition to the Phone number, you can include other properties (Email,Phone Number,First Name, Last Name) <br /> <a href='/assets/email_sms-sample.csv'> Download our sample CSV file</a> to structure your import.`,
        contact_postcard_sms_msg: `Let's import some contacts! Please choose a CSV file and separate each column with relevant information.<br />For each contact, in addition to the Phone number, you can include other properties (Email,Phone Number,First Name, Last Name, Postcard - Address Line 1, Postcard - Address Line 2, Postcard - City, Postcard - State, Postcard - Zip Code, Postcard - Country) <br /> <a href='/assets/postcard_sms-sample.csv'> Download our sample CSV file</a> to structure your import.`,
        tab: "",
      title: "",
      uploadCSV: "",
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      heading: [],
      csvheading: [],
      rows: [],
      pval: {},
      checkedProperty: [],
      dialogTitle: "",
      dialogMessage: "",
      dialogLoading: false,
      dialogShow: false,
      newField: "",
      checkTerm: [],
      deleteID: '',
      modalstatus_Variable: '',
      modalstatus_ST: false,
      disableClassName: "mh-ena kt-form__control yr-arrow",
      variabledata: [],
      bucketMsg: '',
      bucket_msg_err: '',
      vmsg: '',
      vmsg_err: '',
      vmsg_err2: '',
      vari: '',
      modalstatus_Add_Variable: false,
      emailErrors: [],
      isEmailError: false,
      checked_option:['email'],
      option: "email",
      bid: "",
      stBucketName: '',
      duplicateCount: 0,
      isSTAvailable: false,
      stExpandVariable: [],
      serviceTitanVariables: [{
        label: 'Pending Invoices',
        variables: ['email', 'name', 'phone', 'amount']
      },
        {
          label: 'Job Completed',
          variables: ['email', 'name', 'phone', 'address_line_1', 'city', 'state', 'zip_code', 'country', 'job_type', 'amount', 'residential_commerical', 'buiding_age', 'equipment_brand']
        },
        {
          label: 'Pending Estimates',
          variables: ['email', 'name', 'phone', 'job_type', 'estimate_amount']
        }
      ],
      stVariables: [],
      dataTriggerType: '',
      checkAllVars:false,
      bucketGroup : [
        ['Email'],
        ['First Name', 'Last Name', 'Postcard - Address Line 2', 'Postcard - Address Line 1', 'Postcard - City','Postcard - State', 'Postcard - Zip Code', 'Postcard - Country'],
        ['First Name', 'Last Name', 'Phone Number', 'Phone_Number'],
        ['Email', 'First Name', 'Last Name', 'Postcard - Address Line 2', 'Postcard - Address Line 1', 'Postcard - City','Postcard - State', 'Postcard - Zip Code', 'Postcard - Country'],
        ['Email', 'First Name', 'Last Name', 'Phone Number', 'Phone_Number'],
        ['First Name', 'Last Name', 'Postcard - Address Line 2', 'Postcard - Address Line 1', 'Postcard - City','Postcard - State', 'Postcard - Zip Code', 'Postcard - Country', 'First Name', 'Last Name', 'Phone Number', 'Phone_Number'],
        ['Email', 'First Name', 'Last Name', 'Postcard - Address Line 2', 'Postcard - Address Line 1', 'Postcard - City','Postcard - State', 'Postcard - Zip Code', 'Postcard - Country', 'First Name', 'Last Name', 'Phone Number', 'Phone_Number']
      ]
    };
  }
  setOptionValue(e) {
    let errList = this.state.errors;
    let checked_option = this.state.checked_option;
    let option = "";
    if(!checked_option.includes(e)){
        checked_option.push(e);
        delete errList["OptionValue"];
    }else{
      if(checked_option.length > 1){
        checked_option = checked_option.filter( opt => opt != e)
        delete errList["OptionValue"];
      }else{
        checked_option = checked_option.filter( opt => opt != e)
        errList['OptionValue'] = "Please select at least one bucket.";
      }
    }
    this.setState({errors:errList});
    //option string set
    if(checked_option.includes('email') && checked_option.includes('postcard') && checked_option.includes('sms')){
        option = 'email_postcard_sms';
    }else if(checked_option.includes('email') && checked_option.includes('postcard')){
        option = 'email_postcard';
    }else if(checked_option.includes('email') && checked_option.includes('sms')){
        option = 'email_sms';
    }else if(checked_option.includes('postcard') && checked_option.includes('sms')){
        option = 'postcard_sms';
    }else if(checked_option.includes('postcard')){
        option = 'postcard';
    }else if(checked_option.includes('email')){
        option = 'email';
    }else if(checked_option.includes('sms')){
        option = 'sms';
    }
    this.setState({ option: option,checked_option:checked_option });
  }
  componentDidMount() {
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Contacts",
      navigationName: "marketing",
      subHeaderTitle: "Engage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.key !== prevProps.location.key) {
      this.loadData();
      //sagar->if user navigates from tab4 page to create bucket page
      this.setState({ pval: {}, checkedProperty: [] });
    }
  }

  loadData = () => {
    api.marketing.getBucketData({}).then((data) => {
    console.log("bucket data", data.data);
      try {
        if (data.status) {
          let tabVal = "";
          if (data.data.length == 0) tabVal = "tab1";

          this.setState({
            loading: false,
            bucketList: data.data,
            isSTAvailable: (data.service_titan_avaialble) ? true : false,
            tab: tabVal,
            bucket_name: '',
          });
        } else {
          if (data.data.length != 0) {
            this.showToaster("red", data.data);
            utilFunc.scrollTop(800);
          }
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  onClickNext = (tab) => {
    this.props.toggleinnerPageLoader({ toggle: true })
    if (tab == "tab3") {
      var obj = this;
      if (obj.state.title != "") {
        this.validateForm(this.state, function (errors) {
          obj.setState({ errors });
          if (Object.keys(errors).length == 0) {
            obj.setState({ loading: true });
            var myform = document.getElementById("uploadContacts");
            var formData = new FormData(myform);
            const tokenInfo = util.getAuthTokens();
            var xhr = new XMLHttpRequest();
            var login_via=util.getCookie("loginVia");
            formData.append("loginvia",login_via);
            xhr.open("POST", config.apiBaseURL2 + "contacts/uploadcsv", true);
            xhr.setRequestHeader(
              config.access_token_name,
              tokenInfo.access_token
            );
            xhr.setRequestHeader(
              config.refresh_token_name,
              tokenInfo.refresh_token
            );
            xhr.onreadystatechange = function () {
              if (xhr.readyState == XMLHttpRequest.DONE) {
                const resp = JSON.parse(xhr.response);
                console.log(resp,resp.data,"respp")
                if (resp.status) {
                  //obj.showToaster('green', 'Data saved successfully.');
                  //utilFunc.scrollTop(800);
                  obj.props.toggleinnerPageLoader({ toggle: false })
                  let emerr = resp.errors.length > 0 ? true : false;
                  console.log("error count", resp.data.row);
                  
                  var aa = resp.data.head,
                      a = resp.data.variables,
                      b = a.map(e => e.title);
                  var c = aa.filter(x => !b.includes(x));
                  var hdx = a[a.length - 1].hid;
                  c.forEach(el => {
                    a.push({bid: '0', hid: hdx, title: el, type: 'text'});
                    hdx++;
                  });
                  let filtered = a.filter(({title}, index) => !b.includes(title, index + 1))
                  let pvals = {};
                  
                  let checkValues = obj.state.checkedProperty;
                  resp.data.head.map(el => {
                    let bsType = obj.getBucketType(obj.state.option);
                    let selectedElem = obj.getSelectedVariable(bsType, el);
                    pvals[el] = (selectedElem) ? el : obj.state.option == 'postcard' ? el : '';
                    if(selectedElem){
                      checkValues.push(el);
                    }
                  });
                  
                  obj.setState({
                    loading: false,
                    title: "",
                    uploadCSV: "",
                    tab: tab,
                    heading: resp.data.head,
                    csvheading: filtered,
                    pval:pvals,
                    rows: resp.data.row,
                    isEmailError: emerr,
                    emailErrors: resp.errors,
                    bid: resp.bid,
                    checkedProperty: checkValues
                  });
                } else {
                  obj.props.toggleinnerPageLoader({ toggle: false })
                  obj.setState({
                    loading: false,
                  });
                  obj.showToaster("red", resp.data);
                  utilFunc.scrollTop(800);
                }
              }
            };
            xhr.send(formData);
          } else {
            let scrolltop =
              document.querySelector(".focusError").offsetTop -
              document.body.scrollTop;
            window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
          }
        });
      } else {
        // Added by rohit mmm
        obj.props.toggleinnerPageLoader({ toggle: false })
        const errors = {};
        this.setState({
          bucket_msg_err: "This field is required.",
        });
        errors["title"] = "This field is required.";
        //alert(this.state.bucket_msg_err);
        setTimeout(
          function () {
            this.setState({ bucket_msg_err: false, errors: false });
          }.bind(this),
          5000
        );
      }
    } else if (tab == "tab4") {
      new Promise((resolve) => {
        let t = 0;
        document
          .querySelectorAll(".yr-tableSelect")
          .forEach(function (userItem1) {
            userItem1.style.border = "none";
            if (t == document.querySelectorAll(".yr-tableSelect").length - 1)
              resolve(true);

            t++;
          });
      }).then((status) => {
        var obj = this;
        this.validateForm3(this.state, function (status) {
          if (status) {
            obj.setState({ loading: true });
            obj.props.toggleinnerPageLoader({ toggle: true })
            api.marketing.savebucket(obj.state).then((data) => {
              obj.setState({ loading: false });
              obj.props.toggleinnerPageLoader({ toggle: false })
              try {

                if (data.status) {
                  obj.setState({
                    tab: tab,
                    heading: [],
                    csvheading: [],
                    checkedProperty: [],
                    pval: {},
                  });
                } else {
                  obj.showToaster("red", data.data);
                  utilFunc.scrollTop(800);
                  // obj.setState({
                  //   checkedProperty: [],
                  //   pval:{}
                  // });
                }
              } catch (err) {
                obj.props.toggleinnerPageLoader({ toggle: false })
                console.log(err);
              }
            });
          } else {
            document
              .querySelectorAll(".yr-tableSelect")
              .forEach(function (userItem) {
                // let Fname = userItem.getAttribute("name");
                if (userItem.value == "") {
                  userItem.style.border = "1px solid red";
                }
              });
          }
        });
      });
    } else if (tab == "tab5") {
      this.setState({ loading: true });
      this.props.toggleinnerPageLoader({ toggle: false })
      api.marketing.savefinal(this.state).then((data) => {
        this.setState({ loading: false });
        try {
          if (data.status) {
            let bList = this.state.bucketList;
            bList.push(data.data);
            this.setState({
              tab: "",
              bucketList: bList,
              checkedProperty: [],
              checkTerm: [],
              pval: {},
            });
          } else {
            this.showToaster("red", data.data);
            utilFunc.scrollTop(800);
          }
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      this.props.toggleinnerPageLoader({ toggle: false })
      this.setState({
        tab: tab,
      });
    }
  };

  validateForm3 = (data, callback) => {
    var rlen = this.state.heading.length;
    if(this.state.checked_option.length == 1 && this.state.checked_option == 'postcard'){
      var allVal = []; 
      document.querySelectorAll('.yr-tableSelect').forEach(el => allVal.push(el.value))
      this.setState({checkedProperty: allVal});
      callback(true); 
    }else{ 
      if (
        this.state.checkedProperty.length !== rlen ||
        this.state.checkedProperty.includes("")
      ) {
        this.props.toggleinnerPageLoader({ toggle: false });
        this.showToaster("red", "Please categorize each columns.");
        utilFunc.scrollTop(800);
        callback(false);
      } else {
        var allVal = []; 
        document.querySelectorAll('.yr-tableSelect').forEach(el => allVal.push(el.value))
        this.setState({checkedProperty: allVal});
        callback(true);
      }
    }
    // const errors = {};
    // if (data.title=='') {
    //     errors['title'] = "This field is required.";
    // }
    // callback(errors);
    //return errors;
  };

  validateForm = (data, callback) => {
    const errors = {};
    if (data.title == "") {
      errors["title"] = "This field is required.";
    }
    if (data.uploadCSV == "") {
      errors["uploadCSV"] = "This field is required.";
    }
    callback(errors);
    //return errors;
  };

  handleCSV = (event) => {
    this.setState({ loading: true });
    var myform = document.getElementById("uploadContacts");
    var formData = new FormData(myform);
    const tokenInfo = util.getAuthTokens();
    var xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      config.apiBaseURL + "/marketing/contacts/chkvalidation",
      true
    );
    xhr.setRequestHeader(config.access_token_name, tokenInfo.access_token);
    xhr.setRequestHeader(config.refresh_token_name, tokenInfo.refresh_token);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        this.setState({ loading: false });
        try {
          const resp = JSON.parse(xhr.response);
          if (resp.status) {
            let errList = this.state.errors;
            delete errList["uploadCSV"];
            this.setState({
              errors: errList,
              uploadCSV: formData.get("uploadCSV").name,
            });
          } else {
            let errList = this.state.errors;
            errList["uploadCSV"] = resp.data;
            this.setState({ errors: errList, uploadCSV: "" });
          }
        } catch (err) {
          console.log(err);
          let errList = this.state.errors;
          errList["uploadCSV"] = xhr.response;
          this.setState({ errors: errList, uploadCSV: "" });
        }
      }
    };
    xhr.send(formData);
  };

  onchange = (e) => {
    if (e.target.value != "") {
      let errList = this.state.errors;
      delete errList[e.target.name];
      this.setState({ errors: errList });
    } else {
      let errList = this.state.errors;
      errList[e.target.name] = "This field is required.";
      this.setState({ errors: errList });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  oncheckTerm = (e) => {
    console.log(e.target);
    let chk = this.state.checkTerm;
    if (e.target.checked) {
      chk.push(e.target.value);
      this.setState({
        checkTerm: chk,
      });
    } else {
      let ckey = chk.indexOf(e.target.value);
      if (ckey > -1) chk.splice(ckey, 1);
      this.setState({
        checkTerm: chk,
      });
    }
    console.log(this.state.checkTerm);
  };

  showToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ userToaster: false });
      }.bind(this),
      5000
    );
  };
  createServiceTitanBucket = () => {
    this.setState({
      modalstatus_ST: true
    })
  }
  renderHeading = (child) => {
    let hlist = [...this.state.csvheading];

    if(typeof hlist != "undefined") {
      return hlist.map(data => {
        let selectedFlag = false;
        if(this.state.checkedProperty.length > 0){
          selectedFlag = this.state.checkedProperty.includes(data.title);
        }

        console.log(data.title, ': title')
        return (
            <option
              key={data.hid}
              value={data.title}
              disabled={selectedFlag}
            >
              {data.title}
            </option>
          );
      });
    }
  };

  onchangeHeading = (e, data) => {
    let checkValues = this.state.checkedProperty;
    let pvals = this.state.pval;
    let prevValue = pvals[data];
    
    if(prevValue != '' && prevValue != e.target.value){
      checkValues.splice(checkValues.indexOf(prevValue), 1);
    }

    pvals[data] = e.target.value;
    if(checkValues.length > 0){
      if(checkValues.indexOf(e.target.value) == -1){
        (e.target.value != '') && checkValues.push(e.target.value);
      }
    }else{
      (e.target.value != '') && checkValues.push(e.target.value);
    }



    this.setState({
      pval: pvals,
      checkedProperty: checkValues
    })
    // e.target.value = e.target.value;
  };

  addColumn = (action, data) => {
    var dis = this;
    if (action == "Add") {
      this.setState({
        dialogTitle: "Add",
        dialogMessage: "",
        dialogLoading: false,
        dialogShow: true,
        newField: "",
        errors: {},
        deleteID: "",
      });
    }
    if (action == "Close") {
      this.setState({
        dialogTitle: "",
        dialogMessage: "",
        dialogLoading: false,
        dialogShow: false,
        newField: "",
        errors: {},
        deleteID: "",
      });
    }
    if (action == "Close1") {
      this.setState({
        isEmailError: false,
      });
    }
    if (action == "Save") {
      var obj = this;

      this.validateForm2(this.state, function (errors) {
        obj.setState({ errors });
        if (Object.keys(errors).length == 0) {
          obj.setState({ dialogLoading: true });
          api.marketing
            .addfield({ bid: obj.state.bid, title: obj.state.newField })
            .then((data) => {
              if (data.status) {
                let btList = dis.state.csvheading;
                let newOb = {
                  bid: obj.state.bid,
                  hid: btList.length + 1,
                  title: data.data.title,
                  type: "text",
                  user_id: data.data.user_id,
                };
                btList.push(newOb);
                console.log("new Btlist", btList);
                obj.setState({
                  csvheading: btList,
                  dialogTitle: "",
                  dialogMessage: "",
                  dialogShow: false,
                  newField: "",
                });
                obj.setState({ dialogLoading: false });
              } else {
                let errList = obj.state.errors;
                errList["newField"] = data.data;
                obj.setState({ errors: errList });
                obj.setState({ dialogLoading: false });
              }
            });
        }
      });
    }

    if (action == "Delete") {
      this.setState({ dialogLoading: true });
      var dis = this;
      api.marketing
        .deletecolumn({
          bid: this.state.bid,
          field: this.state.heading[this.state.deleteID],
        })
        .then((data) => {
          try {
            if (data.status) {
              let preArr = dis.state.heading;
              preArr.splice(dis.state.deleteID, 1);
              let newColumn = this.state.rows.map((el) => {
                let a = Object.entries(el);
                a.splice(dis.state.deleteID, 1);
                return Object.fromEntries(a);
              });

              this.setState({
                dialogTitle: "",
                dialogMessage: "",
                dialogLoading: false,
                dialogShow: false,
                newField: "",
                errors: {},
                deleteID: "",
                rows: newColumn,
                heading: preArr,
              });
            } else {
              dis.setState({
                dialogTitle: "",
                dialogMessage: "",
                dialogLoading: false,
                dialogShow: false,
                newField: "",
                errors: {},
                deleteID: "",
              });
              this.showToaster("red", "Something wents wrong.");
              utilFunc.scrollTop(800);
            }
          } catch (err) {
            console.log(err);
          }
        });
    }
  };

  validateForm2 = (data, callback) => {
    const errors = {};
    if (data.newField.trim() == "") {
      errors["newField"] = "This field is required.";
      callback(errors);
    }
    this.state.csvheading.map((x, y) => {
      if (this.state.csvheading.length > 1) {
        if (x.title.toLowerCase() == data.newField.toLowerCase()) {
          errors["newField"] = "Duplicate field.";
        }
      }
      if (this.state.csvheading.length == y + 1) callback(errors);
    });
    //callback(errors);
    //return errors;
  };

  createBucket = () => {
    this.setState({
      tab: "tab2",
      option: "email",
    });
  };

  checkallvar=(e)=>{
    console.log(e.target.checked,e);
    this.setState({checkAllVars:e.target.checked});
    let activeBucket = this.getBucketType(this.state.option);
    var shownVar = null, shownVar2 = null;
    shownVar = this.state.variable.map(el => {
      return (activeBucket == 2 && el.label == 'Email') ? {...el, value: 'Email'} : el
    })
    shownVar2 = this.state.variable.filter(el => {
      return (this.state.bucketGroup[activeBucket - 1].includes(el.label)) && ({...el, value: '*'})
    });
    
    if(e.target.checked){
      this.setState({variable2:shownVar});
    }else{
      this.setState({variable2:shownVar2});
    }
  }

  deleteColumn = (key) => {
    /* Sagar */
    const newCheckedProperty = [...this.state.checkedProperty];
    newCheckedProperty.splice(key, 1);
    /* End of code */

    this.setState({
      dialogTitle: "Delete",
      dialogMessage: "Are you sure you want to delete it?",
      dialogLoading: false,
      dialogShow: true,
      newField: "",
      errors: {},
      deleteID: key,
      checkedProperty: newCheckedProperty,
    });
  };

  getAllVariable = () => {
    console.log(this.state.option);
    api.marketing.getVariable({ type: this.state.option }).then((data) => {
      try {
        this.setState({
          modalstatus_Variable: true,
          variable: data.data.listvariable == 0 ? null : data.data.listvariable,
          //variable2: (data.data.listvariable == 0) ? null : data.data.listvariable,
          loading: false,
          // disableClassName:(this.options.length==0)?"kt-form__control yr-arrow mh-dis":"mh-ena kt-form__control yr-arrow"
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  Next = () => {
    if (this.state.title != "") {
      // if (this.state.option == "email") {
      if (true) {
        this.setState({
          loading: true,
          variable: [],
          variableValue: [],
        });
        let bucket__type = this.state.option, dis = this;
        api.marketing.getVariable({ type: bucket__type }).then(data => {
          try {
            let activeBucket = dis.getBucketType(bucket__type);
            var shownVar = null, shownVar2 = null;
            shownVar = [...data.data.listvariable].map(el => {
              return (activeBucket == 2 && el.label == 'Email') ? {...el, value: 'Email'} : el
            })
            shownVar2 = [...data.data.listvariable].filter(el => {
              return (dis.state.bucketGroup[activeBucket - 1].includes(el.label)) && ({...el, value: '*'})
            })

            dis.setState({
              modalstatus_Variable: true,
              variable: shownVar,
              variable2: shownVar2, 
              loading: false,
            });
          } catch (err) {
            console.log(err);
          }
        });
            }/*  else if (this.state.option === "sms") {
                this.setState({
                    loading: true,
                });
                api.marketing.saveSmsVariables({
                    bucket_name: this.state.title,
                    type: this.state.option,
                }).then((res) => {
                    console.log(res);
                    this.setState({
                        modalstatus_Variable: false,
                        bucketMsg: res.msg,
                        loading: false,
                        tab: "",
                        bucketList: res.data,
                        checkedProperty: [],
                        checkTerm: [],
                        pval: {},
                        title: "",
                        variableValue: [],
                    });
                    setTimeout(
                        function () {
                            this.setState({ bucketMsg: false, variableValue: false });
                        }.bind(this),
                        5000
                    );
                }).catch((err) => {
                    console.log(err);
                    this.setState({
                        bucketMsg: 'Something went wrong.',
                        loading: false,
                    });
                    setTimeout(
                        function () {
                            this.setState({ bucketMsg: false, variableValue: false });
                        }.bind(this),
                        5000
                    );
                })
      } else {
        this.setState({
          loading: true,
        });
        api.marketing
          .savePostcardVariable({
            bucket_name: this.state.title,
            type: this.state.option,
          })
          .then((data) => {
            try {
              this.setState({
                modalstatus_Variable: false,
                bucketMsg: data.msg,
                loading: false,
                tab: "",
                bucketList: data.data,
                checkedProperty: [],
                checkTerm: [],
                pval: {},
                title: "",
                variableValue: [],
              });
              setTimeout(
                function () {
                  this.setState({ bucketMsg: false, variableValue: false });
                }.bind(this),
                100
              );
            } catch (err) {
              console.log(err);
            }
          });
      } */
    } else {
      const errors = {};
      this.setState({
        bucket_msg_err: "This field is required.",
      });
      errors["title"] = "This field is required.";
      //alert(this.state.bucket_msg_err);
      setTimeout(
        function () {
          this.setState({ bucket_msg_err: false, errors: false });
        }.bind(this),
        5000
      );
    }
  };

  modalClose_Variable = () => {
    let vars=this.state.variable;
    this.setState({
      modalstatus_Variable: false,
      checkAllVars:false,
      variable2:vars
    });
  };

  save_var = () => {
    //alert('hi');
    document.getElementById("save_var").click();
  };
  //Email Id is invalid: sjacob.v#@gmail.com (Row: 2)
  displayEmailErrors = () => {
    if (this.state.emailErrors.length > 0) {
      return this.state.option == "email" ? (
        <div className="list">
          {this.state.emailErrors.map((item) => {
            return (
              <div class="form-group" style={{ paddingLeft: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Email Id is invalid:{" "}
                  <b style={{ color: "#de0a0a" }}>{item.email}</b> (Row:{" "}
                  <b style={{ color: "#de0a0a" }}>{item.row})</b>
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="list">
          {this.state.emailErrors.map((item) => {
            return (
              <div class="form-group" style={{ paddingLeft: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                                    {item.msg} {" "}
                                    {this.state.option == "sms" &&
                                        <b style={{ color: "#de0a0a" }}>{item.val}</b>} (Row:{" "}
                  <b style={{ color: "#de0a0a" }}>{item.row})</b>
                </p>
              </div>
            );
          })}
        </div>
      );
    }
  };
  save = () => {
    //added by rohit
    //alert('hi');
    this.setState({
      loading: true,
    });
    var bucket_name = this.state.title;
    var variableValue = this.state.variable2;
    //alert(bucket_name);
    this.props.toggleinnerPageLoader({ toggle: true });
    api.marketing
      .saveVariable({
        bucket_name: bucket_name,
        variableValue: variableValue,
        type: this.state.option,
      })
      .then((data) => {
        try {
          this.props.toggleinnerPageLoader({ toggle: false })

          if (data.status) {
            this.setState({
              modalstatus_Variable: false,
              bucketMsg: data.msg,
              loading: false,
              tab: "",
              bucketList: data.data,
              checkedProperty: [],
              checkTerm: [],
              pval: {},
              title: "",
              variableValue: [],
              checkAllVars:false,
            });
            setTimeout(
              function () {
                this.setState({ bucketMsg: "", variableValue: false });

                //this.loadData();
              }.bind(this),
              1000
            );
          } else {
            this.setState({
              modalstatus_Variable: false,
              bucketMsg: data.data,
              loading: false,
              checkAllVars:false

            });
            utilFunc.scrollTop(800);
            setTimeout(
              function () {
                this.setState({ bucketMsg: false, variableValue: false });

                //this.loadData();
              }.bind(this),
              2000
            );
          }
        } catch (err) {
          this.props.toggleinnerPageLoader({ toggle: false })
          console.log(err);
        }
      });
  };

  saveST = () => {
    var dis = this;
    
      var bucket_name = this.state.stBucketName;
      var variableValue = this.state.stVariables;
    var dataTriggerType = this.state.dataTriggerType;
    this.setState({ disabledSTButtion: true });
      if (bucket_name == '') {
        this.setState({
          vmsg_err: 'This field is required.',
          vmsg_err2: '',
          disabledSTButtion: false
        })
      } else if (dataTriggerType == '' || dataTriggerType == 0) {
        this.setState({
          vmsg_err: '',
          vmsg_err2: 'This field is required.',
          disabledSTButtion: false
        })
      }
      else {
        this.setState({
          loading: true,
          disabledSTButtion: true
        });
        api.marketing.saveVariable({ bucket_name: bucket_name, variableValue: variableValue, bucket_source: '1',type:'email' }).then(data => {
          if (data.status) {
            let ff = this.state.flagCount + 1;
            this.setState({
              bucketList: data.data,
              loading: false,
              variableValue: [],
              vmsg_err: "",
              stBucketName: "",
              dataTriggerType: "",
              vmsg_err2: '',
              userToasterMsg: 'Bucket added successfullly',
              userToasterColor: 'green',
              userToaster: true,
              modalstatus_ST: false,
              flagCount: ff,
              disabledSTButtion: false
            });

            api.marketing.saveServiceTitanBucketTrigger({ bid: data.data[data.data.length - 1], dataTrigger: dataTriggerType })
            .then(res => {
             
            }).catch(err => console.log(err))  

            setTimeout(() => {
              this.setState({
                userToasterMsg: '',
                userToasterColor: '',
                userToaster: false,
                stVariables: []
              });
            }, 1000)
            dis.loadData();
          }else {
            this.setState({vmsg_err: data.msg})
          }
        });
      }
  }

  onClose = () => {
    //alert("hi");
  };

  VariableChange = (value, event) => {
    var valv = event.option.value;
    //alert( valv );
    if (valv != "*") {
      this.setState({ variable2: value });
    }
  };

  onchangev = (e) => {
    //alert(e.target.value);
    this.setState({ vari: e.target.value });
  };

  saveV = () => {
    //added by rohit
    //var vari = this.state.vari;
    var vari = this.state.vari.trim();
    if (vari != "") {
      //alert(bucket_name);
      this.setState({
        loading: true,
      });
      this.props.toggleinnerPageLoader({ toggle: true })
      api.marketing.addVariable({ vari: vari }).then((data) => {

        try {
          this.props.toggleinnerPageLoader({ toggle: false })
          this.getAllVariable();

          this.setState({
            loading: false,
            vmsg: (data.vmsg.length != 0) ? data.vmsg : '',
            vmsg_err: data.vmsg_err,
            vari: "",
          });
          setTimeout(
            function () {
              this.setState({ vmsg: false, vmsg_err: false });
            }.bind(this),
            5000
          );



          let varlist2 = this.state.variable2;
          //let varlist = this.state.variable;
          var addnew = { label: vari, value: vari };
          //console.log( addnew );
          if (data.status) {
            varlist2.push(addnew);
            console.log("vars2",varlist2);
            this.setState({ variable2: varlist2 });
          }

          if (data.vmsg_1 == 0) {
            this.modalClose_AddVariable();
          }
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      this.props.toggleinnerPageLoader({ toggle: false })
      this.setState({
        loading: false,
        vmsg: "",
        vmsg_err: "This field is required.",
      });
      setTimeout(
        function () {
          this.setState({ vmsg: false, vmsg_err: false });
        }.bind(this),
        5000
      );
    }
  };

  viewVariable = () => {
    //alert('hi');
    this.setState({
      //loading: true,
      modalstatus_Add_Variable: true,
    });
  };

  modalClose_AddVariable = () => {
    this.setState({
      modalstatus_Add_Variable: false,
      vari: ''
    });
  };

  modalStyle = () => {
    document.getElementById("backModal").style.zIndex = 0;
  };
  changeSTVChecked = (pr, c) => {
    var ns = this.state.serviceTitanVariables.map((el, i) => {
      if (i == pr) {
        var cs = el.children.map((elem, id) => {
          return (id == c) ? { ...elem, checked: !elem.checked } : elem
        })

        return { ...el, children: cs }
      } else {
        return el
      }
    });

    this.setState({ serviceTitanVariables: ns });
    let arr = [];
    ns.map(el => {
      el.children.map(ele => {
        if (ele.checked) {
          arr.push({ label: ele.value, value: ele.value })
        }
      })
    })

    this.setState({ stVariables: arr });
  }

  fetchDataTypeST = (e) => {
    var temp = this.state.serviceTitanVariables[e.target.value - 1].variables;
    temp = temp.map(el => {
      return { label: el, value: el }
    })
    this.setState({ stVariables: temp, dataTriggerType: e.target.value , vmsg_err2: '' });
  }

  modalClose_ST = () => {
    this.setState({
      modalstatus_ST: false,
      disabledSTButtion:false
    })
  }

  getBucketType = bc => {
    let value = 1;
    switch(bc){
      case 'email':
        value = 1;
      break;
      case 'postcard':
        value = 2;
      break;
      case 'sms':
        value = 3;
      break;
      case 'email_postcard':
        value = 4;
      break;
      case 'email_sms':
        value = 5;
      break;
      case 'postcard_sms':
        value = 6;
      break;
      case 'email_postcard_sms':
        value = 7;
      break;
    }

    return value;
  }

  getSelectedVariable = (bucketFlag, element) => {
    var flag = false;
    if((bucketFlag == 1 || bucketFlag == 4 || bucketFlag == 5 || bucketFlag == 7) && element == 'Email'){
      flag = true;
    }
    if((bucketFlag == 3 || bucketFlag == 5 || bucketFlag == 6 || bucketFlag == 7) && element == 'Phone_Number'){
      flag = true;
    }
    if((bucketFlag == 3 || bucketFlag == 5 || bucketFlag == 6 || bucketFlag == 7) && element == 'Phone Number'){
      flag = true;
    }

    return flag;
  }


  render() {
    const { errors } = this.state;

    return (
      <div
        className="kt-container kt-body  kt-grid kt-grid--ver completedchatmain"
        id="kt_body"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-manage-contact">
            {this.state.loading && <div className="ui loading form"></div>}
            <Message
              color={
                this.state.userToasterColor
                  ? this.state.userToasterColor
                  : "teal"
              }
              style={{
                display: this.state.userToaster ? "block" : "none",
                marginTop: 30,
              }}
            >
              {this.state.userToasterMsg}
            </Message>
            {this.state.bucketMsg != "" ? (
              <div class="col-lg-12 ui green message del-msg-padding">
                {this.state.bucketMsg}
              </div>
            ) : (
              <div></div>
            )}
            {this.state.bucketList.length == 0 ? (
              <div
                className="yr-body-p yr-mt-20 automatedMarketing"
                style={{
                  display: this.state.tab == "tab1" ? "inline-block" : "none",
                }}
              >
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p className="kt-margin-t-30">
                      <strong>
                        All Contacts must be placed "buckets". By creating, we
                        know which users to send each email to. You could have
                        one bucket with all of your users or 100 buckets for
                        every specific customer types. The choice is all yours!
                      </strong>
                    </p>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 yr-mt-20">
                    <div className="yr-workspace">
                      <ul>
                        <li
                          onClick={() => this.onClickNext("tab2")}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="row">
                            <div className="col-md-3 kt-align-center">
                              <img
                                src="../assets/media/icons/plus.svg"
                                alt=""
                              />
                            </div>
                            <div className="col-md-9">
                              <h2>Click here to create your first bucket</h2>
                            </div>
                            <div className="col-md-12  yr-mt-20 kt-align-center">
                              <img
                                src="../assets/media/icons/automated-marketing.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              this.state.tab == "" && (
                <div>
                  {" "}
                  <p
                    className="kt-margin-t-30"
                    style={{
                      color: "#959393 !important",
                      fontSize: "13px",
                      fontWeight: "bold",
                    }}
                  >
                    <strong>
                      All Contacts must be placed "buckets". By creating, we
                      know which users to send each email to. You could have one
                      bucket with all of your users or 100 buckets for every
                      specific customer types. The choice is all yours!
                    </strong>
                  </p>
                  <DatatableList
                    bucketList={this.state.bucketList}
                    createBucket={this.createBucket}
                    isServiceTitan={this.state.isSTAvailable}
                    createServiceTitanBucket={this.createServiceTitanBucket}
                    userdata={this.props.user}
                  />
                </div>
              )
            )}

            <div
              className="yr-body-p yr-mt-20 automatedMarketing"
              style={{
                display: this.state.tab == "tab2" ? "inline-block" : "none",
              }}
            >
              <div className="row">
                <form
                  id="uploadContacts"
                  name="uploadContactsForm"
                  encType="multipart/form-data"
                  method="post"
                >
                  <div className="col-lg-12 col-md-12 col-sm-12 focusError">
                    <p>What would you like to name your bucket?</p>
                    <div class="yr-line-input" style={{ marginBottom: "30px" }}>
                      <input
                        type="text"
                        name="title"
                        onChange={(e) => this.onchange(e)}
                        value={this.state.title}
                        style={{ marginBottom: 2, height: 20 }}
                        className={
                          errors && errors["title"]
                            ? "form-control"
                            : "form-control"
                        }
                      />
                      <div style={{ color: "#fd2727", marginBottom: 30 }}>
                        {this.state.bucket_msg_err}
                      </div>
                      {errors && errors.hasOwnProperty("title") && (
                        <CustomErrorField
                          message={errors["title"]}
                          className="error invalid-feedback"
                        />
                      )}
                    </div>
                    <p>
                      <div className="form-group row">
                        <input type="hidden" value={this.state.option} name="opt"/>
            {errors && errors.hasOwnProperty("OptionValue") && (
                          <CustomErrorField
                            message={errors["OptionValue"]}
                            className="error text-danger"
                          />
                          )}
                        <label className="col-lg-12 col-form-label">
                        Which pieces of content will you be sending with this bucket?
                        </label>
                        <div className="col-lg-12">
                          <div className="kt-radio-inline">
                            <div className="mb-1">
                              <label className="kt-checkbox inalign pl-0 mb-0">
                                <input
                                  type="checkbox"
                                  onClick={() => this.setOptionValue("email")}
                                  checked={
                                    this.state.option.search('email') > -1
                                      ? "checked"
                                      : ""
                                  }
                                  value="email"
                                />
                                <span></span>
                              </label>
                              <span
                                className="align-label"
                                style={{ marginLeft: 24 }}
                              >
                                Emails{" "}
                              </span>
                            </div>
                            <div className="mb-1">
                              <label className="kt-checkbox inalign pl-0 mb-0">
                                <input
                                  type="checkbox"
                                  onClick={() =>
                                    this.setOptionValue("postcard")
                                  }
                                  checked={
                                    this.state.option.search('postcard') > -1
                                      ? "checked"
                                      : ""
                                  }
                                  value="postcard"
                                />
                                <span></span>
                              </label>
                              <span
                                className="align-label"
                                style={{ marginLeft: 24 }}
                              >
                                Postcards
                              </span>
                            </div>
                            {/* for sms bucket */}
                            <div className="mb-1">
                                <label className="kt-checkbox inalign pl-0  mb-0">
                                    <input
                                        type="checkbox"
                                        onClick={() =>
                                            this.setOptionValue("sms")
                                        }
                                        checked={
                                            this.state.option.search('sms') > -1
                                                ? "checked"
                                                : ""
                                        }
                                        value="sms"
                                    />
                                    <span></span>
                                </label>
                                <span
                                    className="align-label"
                                    style={{ marginLeft: 24 }}
                                >
                                    Text messages
                                </span>
                            </div>
                            {/* for sms bucket */}
                        </div>
                      </div>
                    </div>

                      <div
                        dangerouslySetInnerHTML={{
                          __html:this.state[`contact_${this.state.option}_msg`]
                        }}
                      />
                    </p>
                  </div>
                  <div className="col-lg-7 col-md-9 col-sm-12 yr-mt-20 focusError">
                    <div className="yr-workspace">
                      <div className="uploadfile">
                        <label for="file-upload" className="custom-file-upload">
                          <div>
                            <i className="fa fa-cloud-upload"></i>
                          </div>
                          <div>Browse</div>
                        </label>
                        <input
                          id="file-upload"
                          name="uploadCSV"
                          type="file"
                          onChange={(e) => this.handleCSV(e)}
                          accept="text/csv"
                          onClick={(event) => {
                            event.target.value = null
                          }}
                          style={{ display: "none" }}
                        />
                      </div>
                      <p>{this.state.uploadCSV}</p>
                      {errors && errors.hasOwnProperty("uploadCSV") && (
                        <div style={{ color: "red" }}>
                          {errors["uploadCSV"]}
                        </div>
                      )}
                      <div className="yr-mt-20 text-right">
                        {this.state.uploadCSV == "" && this.state.checked_option.length ? (
                          <input
                            className="btn linear-gradient zy-save-btn width-100"
                            type="button"
                            onClick={() => this.Next()}
                            value="Continue"
                          />
                        ) : (
                          this.state.checked_option.length > 0 &&
                          <input
                            className="btn linear-gradient zy-save-btn width-100"
                            type="button"
                            onClick={() => this.onClickNext("tab3")}
                            value="Continue csv"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div
              className="yr-body-p yr-mt-20 automatedMarketing"
              style={{
                display: this.state.tab == "tab3" ? "inline-block" : "none",
              }}
            >
              <h4>Let's categorize each column!</h4>
              <table className="table myTable2">
                <thead>
                {
                  <tr>
                    {this.state.heading.map((el, i) => {
												let bsType = this.getBucketType(this.state.option);
                        let selectedFlag = this.getSelectedVariable(bsType, el);
                        let willDisabled = bsType == 2 ? true : selectedFlag ? true : false;
                        return <th>
													<div>
														<select
															disabled={willDisabled}
															className="form-control yr-tableSelect"
															name={"property[" + el + "]"}
                              value={this.state.pval[el]}
                              onChange={e => this.onchangeHeading(e, el)}
														>
                              <option key={0} value="">
                                Not Recognized
                              </option>
														{this.renderHeading(el)}
                            {/* <option key={1} value="First_Name">
                            First_Name
                              </option>
                            <option key={2} value="Company_Name">
                            Company_Name
                              </option>
                            <option key={1} value="Last_Name">
                            Last_Name
                              </option> */}
														</select>
													</div>
												</th>
											})}
										</tr>
									}
                </thead>
                <tbody>
                  {this.state.rows.map((el) => (
                    <tr>
                      {Object.keys(el).map((e) =>
                        [
                          "is_subscribed",
                          "source",
                          "status",
                          "field",
                          "unsubscribed_on_domains",
                          "added_datetime",
                          "isd_code",
                          "phone",
                        ].includes(e) ? (
                          ""
                        ) : (
                          <td name={e}>{el[e]}</td>
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="text-right">
                <button
                  className="btn linear-gradient zy-save-btn width-100"
                  onClick={() => this.onClickNext("tab4")}
                  style={{ marginTop: "1rem" }}
                >
                  Continue
                </button>
              </div>

              {/* Mosal 1 Start here Add varible */}

              <div
                className={
                  this.state.modalstatus_Add_Variable
                    ? "modal fade yr-upgrade-popup white-bg new-model-popup show"
                    : "modal fade yr-upgrade-popup white-bg new-model-popup"
                }
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="staticBackdrop"
                aria-hidden="true"
                style={{
                  display: this.state.modalstatus_Add_Variable
                    ? "block"
                    : "none",
                }}
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  {this.state.loading && (
                    <div className="ui loading form"></div>
                  )}
                  <div
                    className="modal-content white-bg yr-campaigns-model"
                    style={{ maxWidth: 540, margin: "auto" }}
                  >
                    <button
                      type="button"
                      className="close linear-gradient"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => this.modalClose_AddVariable()}
                    >
                      X
                    </button>
                    <div className="modal-header border-bottom-0">
                      <h5 className="modal-title pt-0">Add Variables</h5>
                    </div>
                    <div className="modal-body new-modal-body pb-4">
                      <div className="col-lg-12 col-md-12 col-sm-12 focusError">
                        <div className="row mt-4">
                          <div className="col-lg-12 pr-0">
                            <input
                              type="text"
                              id="notes_team_member"
                              placeholder="Enter variable"
                              class="form-control"
                              value={this.state.vari}
                              onChange={(e) => this.onchangev(e)}
                            />
                            {this.state.vmsg_err != "" ? (
                              <div
                                class="col-lg-12 pl-0"
                                style={{ color: "red" }}
                              >
                                {this.state.vmsg_err}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="md-footer d-flex justify-content-end align-items-center mt-5">
                        <button
                          type="button"
                          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                          data-dismiss="modal"
                          style={{
                            minWidth: 90,
                            height: 35,
                            position: "relative",
                            zIndex: 10,
                          }}
                        >
                          <span
                            style={{ paddingTop: 5, paddingBottom: 5 }}
                            onClick={() => this.modalClose_AddVariable()}
                          >
                            Cancel
                          </span>
                        </button>
                        <button
                          type="button"
                          id="save_var"
                          className="ml-2 btn linear-gradient yr-submitbtn btn-sm"
                          onClick={() => this.saveV()}
                          style={{
                            minWidth: 90,
                            height: 35,
                            position: "relative",
                            zIndex: 10,
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End hare veribale field */}

            <div
              className="yr-body-p yr-mt-20 automatedMarketing"
              style={{
                display: this.state.tab == "tab4" ? "inline-block" : "none",
              }}
            >
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <p>
                    <strong>
                      We're almost done creating your bucket! Please confirm the
                      following:
                    </strong>
                  </p>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 yr-mt-20">
                  <div className="yr-workspace  yr-mt-20">
                    <p>
                      <label className="kt-checkbox chat-checkbox">
                        <input
                          type="checkbox"
                          name="fTerm"
                          value="1"
                          checked={
                            this.state.checkTerm.indexOf("1") > -1
                              ? true
                              : false
                          }
                          onClick={(e) => this.oncheckTerm(e)}
                        />{" "}
                        I've done prior business with users on this list OR ther
                        have subscribed to received email communication.
                        (Required for email sending)
                        <span />
                      </label>
                    </p>
                    <p>
                      <label className="kt-checkbox chat-checkbox">
                        <input
                          type="checkbox"
                          name="sTerm"
                          value="2"
                          checked={
                            this.state.checkTerm.indexOf("2") > -1
                              ? true
                              : false
                          }
                          onClick={(e) => this.oncheckTerm(e)}
                        />{" "}
                        These users have provided explicit consent to recive SMS
                        communication (Required for outbound SMS messages)
                        <span />
                      </label>
                    </p>
                    <div className="yr-mt-20">
                      <button
                        className="btn linear-gradient zy-save-btn width-100"
                        onClick={() => this.onClickNext("tab5")}
                        disabled={
                          this.state.checkTerm.length == 2 ? false : true
                        }
                      >
                        I'm done!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                this.state.dialogShow ? "modal fade show" : "modal fade"
              }
              style={{ display: this.state.dialogShow ? "block" : "none" }}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
                style={{ maxWidth: 600 }}
              >
                {this.state.dialogLoading && (
                  <div className="ui loading form"></div>
                )}
                <div className="modal-content">
                  <button
                    type="button"
                    className="close linear-gradient"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => this.addColumn("Close", "")}
                  >
                    X
                  </button>
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {this.state.dialogTitle == "Delete"
                        ? "Confirm your action"
                        : "Add Field"}
                    </h5>
                  </div>
                  <div className="modal-body">
                    <div className="px-4">
                      {this.state.dialogTitle == "Add" ? (
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Field :
                          </label>
                          <div className="col-lg-8">
                            <div className="field">
                              <div className="ui fluid input">
                                <input
                                  autocomplete="off"
                                  name="newField"
                                  placeholder=""
                                  type="text"
                                  value={this.state.newField}
                                  onChange={(e) => this.onchange(e)}
                                />
                              </div>
                              {errors && errors.hasOwnProperty("newField") && (
                                <div className="red">{errors["newField"]}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        this.state.dialogMessage
                      )}
                    </div>
                  </div>
                  {this.state.dialogTitle == "Delete" ? (
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                        onClick={(e) => this.addColumn("Close", "")}
                        data-dismiss="modal"
                      >
                        <span>No</span>
                      </button>
                      <button
                        type="button"
                        className="btn linear-gradient yr-submitbtn btn-sm"
                        onClick={(e) => this.addColumn("Delete", "")}
                      >
                        Yes
                      </button>
                    </div>
                  ) : (
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                        onClick={(e) => this.addColumn("Close", "")}
                        data-dismiss="modal"
                      >
                        <span>Cancel</span>
                      </button>
                      <button
                        type="button"
                        className="btn linear-gradient yr-submitbtn btn-sm"
                        onClick={(e) => this.addColumn("Save", "")}
                      >
                        Add
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* email errors */}

            <div
              className={
                this.state.modalstatus_Variable
                  ? "modal fade yr-upgrade-popup white-bg new-model-popup show"
                  : "modal fade yr-upgrade-popup white-bg new-model-popup"
              }
              id="backModal"
              style={{
                display: this.state.modalstatus_Variable ? "block" : "none",
              }}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                {this.state.loading && <div className="ui loading form"></div>}
                <div
                  className="modal-content white-bg yr-campaigns-model"
                  style={{ maxWidth: 540, margin: "auto" }}
                >
                  <button
                    type="button"
                    className="close linear-gradient"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.modalClose_Variable()}
                  >
                    X
                  </button>
                  <div className="modal-header border-bottom-0">
                    <h5 className="modal-title pt-0">
                      Customers added to this bucket posses the following
                      variables:
                    </h5>
                  </div>
                  <div className="modal-body new-modal-body pb-4 px-4">
                    <div
                      className={
                        this.state.vmsg == ""
                          ? "dp--height"
                          : "dp--height maxHeight-320"
                      }
                    >
                      <div className="col-sm-12 yr-mt-20">
                        {this.state.vmsg != "" ? (
                          <div class="col-lg-12 ui green message del-msg-padding">
                            {this.state.vmsg}
                          </div>
                        ) : (
                          <div></div>
                        )}

                        <div
                          className="yr-workspace"
                          style={{ position: "relative" }}
                        >
                          <div
                            id="teamMemberCallList"
                            className={this.state.disableClassName + ' contactVariableList'}
                          >
                            <ReactMultiSelectCheckboxes
                              options={this.state.variable}
                              placeholderButtonLabel="Select variable"
                              onChange={this.VariableChange}
                              value={this.state.variable2}
                              setState={this.state.variable2}
                            />
                          </div>
                          <a>&nbsp;</a>
                          <div
                            className="addicon"
                            style={{
                              position: "absolute",
                              top: 10,
                              left: 334,
                              zIndex: 2,
                            }}
                            onClick={this.modalStyle}
                          >
                            <div
                              className="pointer"
                              title="Add Variable"
                              onClick={() => this.viewVariable()}
                            >
                              <img
                                src="../../assets/images/plus-blue.svg"
                                alt=""
                                width="10"
                                height="10"
                              />
                            </div>
                          </div>
                          {/* </div> */}

                          <div
                            className="addicon1"
                            style={{
                              position: "absolute",
                              top: "5px !important",
                              left: "331px !important",
                              zIndex: 4,
                            }}
                           
                          >
                           
                            <input
                            type="checkbox"
                            id="checkallvar"
                            placeholder="check uncheck variables"
                            class="form-control"
                            checked={this.state.checkAllVars}
                            onChange={(e) => this.checkallvar(e)}
                            defaultChecked={this.state.checkAllVars}
                            title="Click to check/uncheck all variables"
                          />
                           
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="md-footer d-flex justify-content-end align-items-center mt-5">
                      <button
                        type="button"
                        className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                        data-dismiss="modal"
                        style={{
                          minWidth: 90,
                          height: 35,
                          position: "relative",
                          zIndex: 10,
                        }}
                      >
                        <span
                          style={{ paddingTop: 5, paddingBottom: 5 }}
                          onClick={() => this.modalClose_Variable()}
                        >
                          Cancel
                        </span>
                      </button>
                      <button
                        type="button"
                        id="save_var"
                        className="ml-2 btn linear-gradient yr-submitbtn btn-sm"
                        onClick={() => this.save()}
                        style={{
                          minWidth: 90,
                          height: 35,
                          position: "relative",
                          zIndex: 10,
                          marginRight: "9.82rem",
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Mosal 1 Start here Add varible */}

            <div
              className={
                this.state.modalstatus_Add_Variable
                  ? "modal fade yr-upgrade-popup white-bg new-model-popup show"
                  : "modal fade yr-upgrade-popup white-bg new-model-popup"
              }
              style={{
                display: this.state.modalstatus_Add_Variable ? "block" : "none",
              }}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                {this.state.loading && <div className="ui loading form"></div>}
                <div
                  className="modal-content white-bg yr-campaigns-model"
                  style={{ maxWidth: 540, margin: "auto" }}
                >
                  <button
                    type="button"
                    className="close linear-gradient"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.modalClose_AddVariable()}
                  >
                    X
                  </button>
                  <div className="modal-header border-bottom-0">
                    <h5 className="modal-title pt-0">Add Variables</h5>
                  </div>
                  <div className="modal-body new-modal-body pb-4">
                    <div className="col-lg-12 col-md-12 col-sm-12 focusError">
                      <div className="row mt-4">
                        <div className="col-lg-12 pr-0">
                          <input
                            type="text"
                            id="notes_team_member"
                            placeholder="Enter variable"
                            class="form-control"
                            value={this.state.vari}
                            onChange={(e) => this.onchangev(e)}
                          />
                          {this.state.vmsg_err != "" ? (
                            <div
                              class="col-lg-12 pl-0"
                              style={{ color: "red" }}
                            >
                              {this.state.vmsg_err}
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="md-footer d-flex justify-content-end align-items-center mt-5">
                      <button
                        type="button"
                        className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                        data-dismiss="modal"
                        style={{
                          minWidth: 90,
                          height: 35,
                          position: "relative",
                          zIndex: 10,
                        }}
                      >
                        <span
                          style={{ paddingTop: 5, paddingBottom: 5 }}
                          onClick={() => this.modalClose_AddVariable()}
                        >
                          Cancel
                        </span>
                      </button>
                      <button
                        type="button"
                        id="save_var"
                        className="ml-2 btn linear-gradient yr-submitbtn btn-sm"
                        onClick={() => this.saveV()}
                        style={{
                          minWidth: 90,
                          height: 35,
                          position: "relative",
                          zIndex: 10,
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Mosal 1 End hare veribale */}
{/* service titan modal */}


 {/* Service titan modal */}
 <div
              className={this.state.modalstatus_ST ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
              style={{ display: this.state.modalstatus_ST ? "block" : "none" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                {this.state.loading && <div className="ui loading form"></div>}
                <div className="modal-content white-bg" style={{ maxWidth: 650, margin: 'auto' }}>
                  <button
                    type="button"
                    className="close linear-gradient"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.modalClose_ST()}
                  >
                    X
                  </button>
                  <div className="modal-header border-bottom-0 px-5">
                    <h5 className="modal-title pt-0 pl-0">Add Bucket for Service Titan</h5>
                  </div>
                  <div className="modal-body new-modal-body py-4 px-5">
                    <div className="focusError d-flex flex-sm-wrap flex-md-nowrap my-2">
                      <label className="col-md-3 align-self-center mb-0">Bucket Name: </label>
                      <div className="w-100">
                        <input type="text"
                          placeholder=""
                          value={this.state.stBucketName}
                          class="form-control mb-2 col"
                          onChange={(e) => this.setState({ stBucketName: e.target.value })}
                        />
                        {this.state.vmsg_err != '' ? (<div class="col-lg-12 pl-0" style={{ color: 'red' }} >{this.state.vmsg_err}</div>) : (<div></div>)}
                      </div>
                    </div>
                    <div className="d-flex flex-sm-wrap flex-md-nowrap my-4">
                      <label className="col-md-3 align-self-end">Data Trigger</label>
                      <div className="col-md-9 px-0">
                        <select className="form-control data-trigger-dropdown" onChange={this.fetchDataTypeST} style={{ paddingTop: '10px !important', paddingBottom: '10px !important'}} value={this.state.dataTriggerType}>
                          <option selected disabled value={''}>Choose data trigger</option>
                          <option value={1}>Pending Invoices</option>
                          <option value={2}>Job Completed</option>
                          <option value={3}>Pending Estimates</option>
                        </select>
                        {this.state.vmsg_err2 != '' ? (<div class="col-lg-12 pl-0" style={{ color: 'red' }} >{this.state.vmsg_err2}</div>) : (<div></div>)}
                      </div>
                    </div>
                    <div className="d-flex flex-sm-wrap flex-md-nowrap my-2">
                      <label className="col-md-3">Variables</label>
                      <ul className="varListing">
                        {this.state.stVariables.length > 0 ? this.state.stVariables.map((el, index) => (
                          <li key={index}>{el.value.split('_').join(' ')}</li>
                        )) : 'Please choose data type first to see their variables'}
                      </ul>
                    </div>
                    <div className="md-footer d-flex justify-content-end align-items-center mt-5">
                      <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" data-dismiss="modal" style={{ minWidth: 90, height: 35, position: 'relative', zIndex: 10 }}>
                        <span style={{ paddingTop: 5, paddingBottom: 5 }} onClick={() => this.modalClose_ST()}>Cancel</span></button>
                      <button type="button" id="save_var" className="ml-2 btn linear-gradient yr-submitbtn btn-sm" onClick={() => this.saveST()} style={{ minWidth: 90, height: 35, position: 'relative', zIndex: 10 }} disabled={this.state.disabledSTButtion}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            { /* Start here Add varible field */}

{/* end modal */}
            <div
              className={
                this.state.isEmailError ? "modal fade show" : "modal fade"
              }
              style={{ display: this.state.isEmailError ? "block" : "none" }}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
                style={{ maxWidth: 600 }}
              >
                {this.state.dialogLoading && (
                  <div className="ui loading form"></div>
                )}
                <div className="modal-content">
                  <button
                    type="button"
                    className="close linear-gradient"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => this.addColumn("Close1", "")}
                  >
                    X
                  </button>
                  <div className="modal-header">
                    <h5 className="modal-title">CSV Errors</h5>
                  </div>
                  <div className="modal-body">
                    <div
                      class="kt-section__body"
                      style={{ maxHeight: "500px", overflow: "auto" }}
                    >
                      {this.displayEmailErrors()}
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                      onClick={(e) => this.addColumn("Close1", "")}
                      data-dismiss="modal"
                    >
                      <span>Cancel</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* end email errors */}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
    return {
        user: state.user.company,
    };
};
export default connect(mapStateToProps, {
    setPageTitle,
    toggleNavigation,
    toggleBreadcrum,
    toggleinnerPageLoader,
})(Contacts);
