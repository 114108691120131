import React , { useState } from 'react';
import './OpenQuickText.css';
import { Button, Checkbox, Icon, Table, Message } from 'semantic-ui-react'
import DatatableList from './DatatableList';

    class OpenQuickText extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				userToaster: false,
				userToasterMsg: "",
				userToasterColor: "",
			}
		}
		hideToaster = () => {			
			this.setState({ userToaster: false });
		};
		showToaster = (color, msg) => {
			this.setState({
			  userToaster: true,
			  userToasterMsg: msg,
			  userToasterColor: color,
			});
			setTimeout(
			  function () {
				this.setState({ userToaster: false });
			  }.bind(this),
			  5000
			);
		  };
        render() {
    return(
        <div className="popup-content">            
            <p>Create reusable text message that you can use to quickly send to your customers.</p> 
            <div className="row">
			<Message
                              color={
                                this.state.userToasterColor
                                  ? this.state.userToasterColor
                                  : "green"
                              }
                              style={{
                                display: this.state.userToaster
                                  ? "block"
								  : "none",
								  width: "100%"
                              }}
                            >
                              {this.state.userToasterMsg}
                            </Message>
				<div className="col-lg-12 col-md-12 col-sm-12">
					{/*begin: Search Form */}
					{/*end: Search Form */}
					<div className="kt-portlet__body kt-portlet__body--fit">
						{/*begin: Datatable */}
						<DatatableList inputTitle={this.props.inputTitle} quickTextAddEditDelete = {this.props.quickTextAddEditDelete} ref={child => { this.child = child; }} sessionUser={this.props.sessionUser} showToaster = {this.showToaster}  />
						{/*end: Datatable */}
					</div>
				</div>
			</div>  
            
        </div>
    )
        }
}


export default OpenQuickText;