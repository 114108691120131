import React from "react";
import './dialog.css';

class DialogBox extends React.Component {
  toggleModal = () => this.props.toggleModal();
  render() {
    let { show, title, children, width, height, message, modalBodyId } = this.props;
    if (!width) width = "500px";
    if (!height) height = "300px";

    return (
      <React.Fragment>
        <div
          className={show ? "modal fade show " : "modal fade"}
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdrop"
          aria-hidden="true"
          style={{ display: show ? "block" : "none" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: width }}
          >
            <div className="modal-content">
              <button
                type="button"
                className="close linear-gradient"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.toggleModal}
              >
                X
              </button>
              <div className="modal-header border-bottom" style={{padding: 0, margin: 20}}>
                  <h5 className="modal-title" style={{ padding: 0 }}>{title}</h5>
                  <div>
                    <button onClick={() => this.props.addQuickText()} type="button" className="btn linear-gradient btn-secondary yr-submitbtn btn-sm mr-2"><i className="plus icon"></i> Add</button>
                    <button type="button" onClick={() => this.props.deleteAllQuickText()} className="btn linear-gradient btn-secondary yr-submitbtn btn-sm"><i className="trash alternate outline icon"></i> Delete</button>
                  </div>
              </div>
              <div
                className="modal-body"
                style={{ maxHeight: height, overflowX: "auto", paddingTop:0, paddingBottom:0 }}   id = {modalBodyId}

              >
                {children ? children : null}
                {message ? message : null} 

              </div>
              {/* <div className="modal-footer"> */}
                {/* <button
                  type="button"
                  className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                  data-dismiss="modal"
                >
                  <span>Cancel</span>
                </button> */}
                {/* <button
                  type="button"
                  onClick={this.toggleModal}
                  className="btn linear-gradient yr-submitbtn btn-sm"
                >
                  OK
                </button> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DialogBox;
