import React, { useEffect, useState } from "react";
import { Form, Dropdown } from "formsy-semantic-ui-react";
import { Message } from "semantic-ui-react";
import validationRule from "../../../../../validation";
//import "../../../../../Modal/dialog.css";
import "./modl.css";
import DatePicker from "react-datepicker";
import api from "../../../../../api";
import config from "../../../../../config";
import util from "../../../../../util";
import utilFunc from "../../../../../util_funs";
import "react-datepicker/dist/react-datepicker.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Portal from "./dateTimePickerPortal";
import jstz from "jstz";
validationRule.isRequired();
validationRule.minCustomLength(6);
validationRule.usPhoneLength();

const AddStaticContact = ({
	obj,
    title,
	oncloseForm,
    loading,
	userdata,
	onValidSubmitall,
	onValidSubmitCSV
	}) => {
	const errorLabel = <div className="red" />;
	let currentContactData = obj.contactLabels;
	const phoneNumberValue='';
	const type='add';
	const [isdCode, setIsdCode] = useState("+1");
	const [modalFormLoader, setModalFormLoader] = useState(false);
	const [modalErrorMsg, setModalErrorMsg] = useState("none");
	const [modalErrorMsg1, setModalErrorMsg1] = useState("none");
	const [modalErrorMsgs, setModalErrorMsgs] = useState({ msg: '', display: 'none' });
	const [modalErrorMsgCSV, setModalErrorMsgCSV] = useState("none");
	const [modalErrorMsgCSVMessage, setModalErrorMsgCSVMessage] = useState("");
	const [browse, setBrowse] = useState("Browse");
	const [csvErrorDisplay, setCsvErrorDisplay] = useState("none");
	const [csvErrorMsg, setCsvErrorMsg] = useState("");
	const [csvFileValue, setCsvFileValue] = useState(""); 
	const [formError, setFormError] = useState('');
	const [formdateerror, setformdateerror] = useState('');
	const [formemailerror, setformemailerror] = useState('');
	const [duplicateEmailError, setduplicateEmailError] = useState(""); 
	const [buttonDisabled, setbuttonDisabled] = useState(false);
	const [buckettype, setbuckettype] = useState(['Email']);
	const [startDate, setStartDate] = useState(new Date());
	const [bucketname,setBucketname] = useState("");
	const [bucketname1,setBucketname1] = useState("");
	const [duplicateBucket,setduplicatebucket] = useState("");
	let d1 = new Date (),
    d2 = new Date ( d1 );
    let minDate=d2.setMinutes ( d1.getMinutes() + 4 );
	console.log("timee",minDate)
	let minTime=d1.getHours()+":"+d1.getMinutes();

	var d = new Date();
    d.setHours(24,0,0,0);
	let maxDate=d.getMinutes();
	
	const postcardVariable = ['First Name', 'Last Name', 'Postcard - Address Line 2', 'Postcard - Address Line 1', 'Postcard - City','Postcard - State', 'Postcard - Zip Code', 'Postcard - Country'];
	const mystyle = {
		padding: "3.25rem",
		maxHeight: "500px",
		overflow: "auto",
	};
	let redButton = {
		marginTop: "-11px",
	};
	const setLocalTime = () => {
		var timezoneObj = jstz.determine();
		api.marketing
		  .getTimezoneData({ timezonename: timezoneObj.name() })
		  .then((data) => {
			const timezone = data.data.timezone;
			try {
			//   this.setState(
			// 	{
			// 	  timeZone: data.data.timezone,
			// 	  currenttime: data.data.currenttime,
			// 	  currentSelectedTimezone: timezoneObj.name(),
			// 	},
			// 	() => {
			// 	  this.setState({
			// 		modalbody: modalType ? this.getCampaignBody() : this.getBody(),
			// 	  });
			// 	}
			//   );
			 
			} catch (err) {
			  console.log(err);
			}
		  });
	  }
	
     
        // check sms number format
   
	useEffect(() => {
	  if(obj.pbookDetails){
		let buckettypes=JSON.parse(obj.pbookDetails.bucket_type).map((item)=>{
			return item.name;

		});
		setbuckettype(buckettypes);
		
	  }
	  setBucketname(obj.bucktitle);
	  setBucketname1(obj.bucktitle);
	}, []);
    const setStartDateval=(v)=>{
		setStartDate(v);
	}

	const fixTimezoneOffset = (date) => {
		if(!date) return "";
		return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
	  }
	const renderDropDownDefault = (defaultCountryCodeArr, countryId = null) => {
		console.log("KRA", defaultCountryCodeArr);
		return defaultCountryCodeArr.map((option, index) => {
			return (
				<option
					key={index}
					value={option.country_code}
					selected={countryId == option.country_code ? "selected" : ""}
				>
					{option.country_name}
				</option>
			);
		});
	};

	const renderDropDownRemaining = (remailCountryCodeArr, countryId = null) => {
		return remailCountryCodeArr.map((option, index) => {
			return (
				<option
					key={index}
					value={option.country_code}
					selected={countryId == option.country_code ? "selected" : ""}
				>
					{option.country_name}
				</option>
			);
		});
	};

	const  checkEmail = (key) => {
        let sendData = {
          email: key.target.value,
          modalType: 'Add',
          id: 1,
		  branch_id:obj.selectedEditor,
		 
        };
		if(key.target.value!==""){
			api.marketing.checkEmail1(sendData).then((data) => {
			  try {
				console.log(data);
				if (data.data == 1) {
					setModalErrorMsg1("block");
					setFormError("This email id  already exits");
					//setduplicateEmailError("This email id  already exits");
				  
				}else if (data.data == 500) {
					setModalErrorMsg1("block");
					setFormError("This email id is invalid");
					//setduplicateEmailError("This email id is invalid");
				  
				} else {
					setModalErrorMsg1("none");
					setFormError("");
				}
			  } catch (err) {
				console.log(err);
			  }
			  setTimeout(()=>{
				setModalErrorMsg1("none");
					setFormError("");
			  },3000)
			});
		}
      };

	  const  checkbucket = (key) => {
		setBucketname(key);
        let sendData = {
		  bucket_name: key,
          user_id:obj.pbookDetails.user_id,
		  pid:parseInt(obj.pid)
        };
		if(key!==""){
			api.marketing.checkBucketName(sendData).then((data) => {
			  try {
				console.log(data);
				if (data.data == 1) {
					//ssetBucketname("");
					setduplicatebucket("Bucket name already exits");
				  return false;
				} else {
					setduplicatebucket("");
					return true;
				}
			  } catch (err) {
				console.log(err);
			  }
			  setTimeout(()=>{
				setduplicatebucket("")
			  },3000)
			});
		}
		return true;
      };
   const checkPhone = (key) => {
		let sendData = {
			phone_number: key.target.value,
			modalType: this.state.modalType,
			cid: this.state.modaldata.cid,
			bid: "1",
		};
		api.marketing.checkPhone(sendData).then((data) => {
			try {
				if (data.data > 0) {
					this.setState({
						duplicatePhoneError: "This phone number is already exits",
					});
				} else {
					this.setState({
						duplicatePhoneError: "",
					});
				}
			} catch (err) {
				console.log(err);
			}
		});
		this.setState({
			phone_number: key.target.value,
		});
		this.phoneFormat(key);
	};
	const checkPhoneSms = (key) => {
		let sendData = {
			sms_number: key.target.value,
			modalType: this.state.modalType,
			cid: this.state.modaldata.cid,
			bid: "1",
		};
		api.marketing.checkPhoneSms(sendData).then((data) => {
			try {
				if (data.data > 0) {
					this.setState({
						duplicatePhoneErrorSms: "This phone number is already exits",
					});
				} else {
					this.setState({
						duplicatePhoneErrorSms: "",
					});
				}
			} catch (err) {
				console.log(err);
			}
		});
		this.setState({
			sms_number: key.target.value,
		});
		this.phoneFormatSms(key);
	};

	const checkPhoneFormat = (e) => {
		this.setState({
			phoneNumberValue: e.target.value
		})
		this.phoneFormat(e);
	}

	const phoneFormat = (e) => {
		let phone = e.target.value;
		let p = phone;
		var obj = this;
		console.log('pph is the', p);
		p = p.replace(/[^\d]*/gi, "");
		console.log('pph is the', p);
		if (p.length < 3) {
			setTimeout(function () {
				obj.setState({ phoneNumberValue: p });
			}, 100);
		} else if (p.length == 3) {
			let pp = p;
			let d4 = p.indexOf("(");
			let d5 = p.indexOf(")");
			if (d4 == -1) {
				pp = "(" + pp;
			}
			if (d5 == -1) {
				pp = pp + ")";
			}
			obj.setState({ phoneNumberValue: pp });
		} else if (p.length > 3 && p.length < 7) {
			p = "(" + p;
			let l30 = p.length;
			let p30 = p.substring(0, 4);
			p30 = p30 + ")";

			let p31 = p.substring(4, l30);
			var pp = p30 + p31;

			setTimeout(function () {
				obj.setState({ phoneNumberValue: pp });
			}, 100);
		} else if (p.length >= 7) {
			p = "(" + p;
			let l30 = p.length;
			let p30 = p.substring(0, 4);
			p30 = p30 + ")";

			let p31 = p.substring(4, l30);
			let pp = p30 + p31;

			let l40 = pp.length;
			let p40 = pp.substring(0, 8);
			p40 = p40 + "-";

			let p41 = pp.substring(8, l40);
			let ppp = p40 + p41;
			let maxphonelength = 13;
			var finalPhone = ppp.substring(0, maxphonelength);
			setTimeout(function () {
				obj.setState({ phoneNumberValue: finalPhone });
			}, 100);
		}
	};

	const phoneFormatSms = (e) => {
		let phone = e.target.value;
		let p = phone;
		var obj = this;

		p = p.replace(/[^\d]*/gi, "");
		if (p.length < 3) {
			setTimeout(function () {
				obj.setState({ sms_number: p });
			}, 100);
		} else if (p.length == 3) {
			let pp = p;
			let d4 = p.indexOf("(");
			let d5 = p.indexOf(")");
			if (d4 == -1) {
				pp = "(" + pp;
			}
			if (d5 == -1) {
				pp = pp + ")";
			}
			obj.setState({ sms_number: pp });
		} else if (p.length > 3 && p.length < 7) {
			p = "(" + p;
			let l30 = p.length;
			let p30 = p.substring(0, 4);
			p30 = p30 + ")";

			let p31 = p.substring(4, l30);
			let pp = p30 + p31;

			setTimeout(function () {
				obj.setState({ sms_number: pp });
			}, 100);
		} else if (p.length >= 7) {
			p = "(" + p;
			let l30 = p.length;
			let p30 = p.substring(0, 4);
			p30 = p30 + ")";

			let p31 = p.substring(4, l30);
			let pp = p30 + p31;

			let l40 = pp.length;
			let p40 = pp.substring(0, 8);
			p40 = p40 + "-";

			let p41 = pp.substring(8, l40);
			let ppp = p40 + p41;
			let maxphonelength = 13;
			let finalPhone = ppp.substring(0, maxphonelength);
			setTimeout(function () {
				obj.setState({ sms_number: finalPhone });
			}, 100);
		}
	};

	const onValidSubmit = (sendData) => {
		onValidSubmitall();
			var $msg = "User has been added successfully.";	
	};

	


	const drawLabelAndFields = (e) => {
		const currentContactLabelData = obj.contactLabels;
		return currentContactLabelData.map((data, idx) => (
			<div className="form-group row">
				<label className="col-lg-5 col-form-label text-capitalize">{data.title.split('_').join(' ')} :</label>
				<div className="col-lg-7">
						{
							displayInputType(type, data.title)
						}
				</div>
			</div>
		));
	};

	const onValidSubmitCSV1 = (e) => {
		onValidSubmitCSV(e);
	};
	

	
	const displayInputType = (type, text, ivalue = '') => {
		let flag = null;
		if(( buckettype.includes('Email')) && text == 'Email'){
			flag = true;
			return <>
				<Form.Input
					fluid
					type={'email'}
					autoComplete="off"
					onBlur={e => checkEmail(e, ivalue)}
					name={text}
					value={ivalue}
					validations="isRequired"
					validationErrors={{
						isRequired: "This field is required.",
					}}
					errorLabel={errorLabel}
				/> {duplicateEmailError && (
					<div class="red" style={redButton}>
						{duplicateEmailError}.
					</div>
				)}
			</>
		}
		if((buckettype.includes('SMS')) && (text == "Phone Number" || text == "Phone_Number")){
			flag = true;
			return <Form.Input
				fluid
				autoComplete="off"
				validations="isRequired"
				validationErrors={{
					isRequired: "This field is required.",
				}}
				onChange={(e) => checkPhoneFormat(e, text.trim())}
				errorLabel={errorLabel}
				name={text.trim()}
				defaultValue={ivalue}
				value={phoneNumberValue}
				maxLength={13}
			/>
		}		
		if((buckettype.includes('postcard')) && (postcardVariable.includes(text.trim()))){
			flag = true;
			return <Form.Input
				fluid
				autoComplete="off"
				validations="isRequired"
				value={ivalue}
				validationErrors={{
					isRequired: "This field is required.",
				}}
				errorLabel={errorLabel}
				name={text.trim()}
			/>
		}

		if(flag == null){
			return <Form.Input
				fluid
				autoComplete="off"
				name={text.trim()}
				value={ivalue}
				data-flag="none"
			/>
		}
	}

	const FiledsArr = (e) => {
		const currentContactLabelData = obj.contactLabels;
		return currentContactLabelData.map(({ title, type, hid }, idx) => (
			<span class="csvColumnFields">{title}</span>
		));
	}

	const handleDropHere = (e) => {
		setIsdCode(e.target.value);
	}

	const onValidSubmitHere = (sendData) => {
		if(startDate=='' || startDate==null || typeof startDate=='undefined'){
			setModalErrorMsg1("block");
			 setformdateerror("Schedule date is required");
			
			 setTimeout(function () {
				 setModalErrorMsg1("none");
				 setformdateerror("");
			 }, 5000);
			 document.getElementById("AddEditContactFormModalBody").scroll({
				 top: 0,
				 behavior: "smooth", // 👈
			 });
			 return false;
          }
		  
		  if(startDate!='' && startDate!=null && typeof startDate!='undefined' && checkbucket(bucketname)){
			let scdate=startDate;
		    scdate=fixTimezoneOffset(scdate);
				let sendDataobj=Object.assign({},sendData,{branch_id:obj.selectedEditor,pid:obj.pid,user_id:obj.pbookDetails.user_id,template_type:obj.template_type,startdate:scdate,timezone:obj.pbookDetails.selected_time_zone_value});
						setModalFormLoader(true);
					
						api.marketing.addQueueContactList(sendDataobj).then((data) => {
							// this.setState({ modalFormLoader: false });
							setModalFormLoader(false);

							try {
								console.log(data);
								if (data.status == false && data.message == "alreadyExist") {
									setModalErrorMsg1("block");
									setFormError(data.data);
									setTimeout(function () {
										setModalErrorMsg1("none");
									}, 5000);
									document.getElementById("AddEditContactFormModalBody").scroll({
										top: 0,
										behavior: "smooth", // 👈
									});
								} else if (data.status == false && data.message == "InvalidEmail") {
									setModalErrorMsg1("block");
									setFormError("Email is Invalid");
									setTimeout(function () {
										setModalErrorMsg1("none");
									}, 5000);
									document.getElementById("AddEditContactFormModalBody").scroll({
										top: 0,
										behavior: "smooth", // 👈
									});
								} else if (data.status == false && data.message == "scheduledate") {
                                    setModalErrorMsg1("block");
									setFormError(data.data);
									setTimeout(function () {
										setModalErrorMsg1("none");
									}, 5000);
									document.getElementById("AddEditContactFormModalBody").scroll({
										top: 0,
										behavior: "smooth", // 👈
									});
								} else {
									if (data.status == false) {
										setModalErrorMsg1("block");
										setFormError(data.data);
										setTimeout(function () {
											setModalErrorMsg1("none");
											setFormError('');
										}, 5000);
										document.getElementById("AddEditContactFormModalBody").scroll({
											top: 0,
											behavior: "smooth", // 👈
										});
									} else {
										onValidSubmit(sendData);
									}
								}
							} catch (err) {
								console.log(err);
							}
						});
	            }
		
		
	};

	

	

	const onValidSubmitHereForCSV = (e) => {
		
		setModalFormLoader(true);
		setbuttonDisabled(true);
		if (csvErrorDisplay == "block") {
			setbuttonDisabled(false);
			return true;
		}

		if (csvFileValue == "") {
			setCsvErrorDisplay("block");
			setCsvErrorMsg("Please upload csv file.");
			setbuttonDisabled(false);
			return true;
		}
		if(startDate=='' || typeof startDate=='undefined'){
			setModalErrorMsg1("block");
			setformdateerror("Schedule date is required");
			 setTimeout(function () {
				 setModalErrorMsg1("none");
				 setformdateerror("");
			 }, 5000);
			 setbuttonDisabled(false);
			 return false;
		}
		if(bucketname==""){
			setModalErrorMsg1("block");
			setformdateerror("Bucket Name is required");
			 setTimeout(function () {
				 setModalErrorMsg1("none");
				 setformdateerror("");
			 }, 5000);
			 setbuttonDisabled(false);
			 return false;
		}
		if(!checkbucket(bucketname)){
			
			 return false;
		}

		var myform = document.getElementById("AddEditContactFormModalCSV");
		var formData = new FormData(myform);
		let scdate=startDate;
		scdate=fixTimezoneOffset(scdate);
		formData.append("pid", obj.pid);
		formData.append("user_id", obj.pbookDetails.user_id);
		formData.append("template_type", obj.template_type);
		formData.append("branch_id", obj.selectedEditor);
		formData.append("startDate", scdate);
		formData.append("bucketname", bucketname);
		formData.append("timezone",obj.pbookDetails.selected_time_zone_value);
		
		api.marketing.addqueueListByCSV(formData).then(data => {
			setModalFormLoader(false);
			setbuttonDisabled(false);
			try {
				if (
					data.data.status === false &&
					data.data.message == "headingMismatch"
				) {
					setModalErrorMsgCSVMessage(
						"CSV heading are not matching as given below titles."
					);
					setModalErrorMsgCSV("block");
					setTimeout(function () {
						setModalErrorMsgCSV("none");
					}, 5000);
					document.getElementById("AddEditContactFormModalBody").scroll({
						top: 0,
						behavior: "smooth", // 👈
					});
				}else if (data.status == false && data.message == "scheduledate") {
					setModalErrorMsgCSVMessage(
						data.data
					);
					setModalErrorMsgCSV("block");
					setTimeout(function () {
						setModalErrorMsgCSV("none");
					}, 5000);
					document.getElementById("AddEditContactFormModalBody").scroll({
						top: 0,
						behavior: "smooth", // 👈
					});
				}else if (data.status === false && data.message == "Error") {
					
					setModalErrorMsgCSVMessage(
						data.data
					);
					setModalErrorMsgCSV("block");
					setTimeout(function () {
						setModalErrorMsgCSV("none");
					}, 5000);
					document.getElementById("AddEditContactFormModalBody").scroll({
						top: 0,
						behavior: "smooth", // 👈
					});
				} else {
					document.getElementById("file-upload").value = "";
					setCsvErrorDisplay("none");
					setBrowse("Browse");
					setCsvFileValue("");
					onValidSubmitCSV1(data);
				}
			} catch (err) {
				console.log(err);
			}
		});
	};

	const modalCloseHere = (e) => {
		setCsvErrorDisplay("none");
		setBrowse("Browse");
		setCsvFileValue("");
		oncloseForm();
		try {
			document.getElementById("file-upload").value = "";
		} catch (e) { }
	};
	

	const changeFileInputCSV = (e) => {
		
		try {
			
			var fileName = e.target.files[0].name;
			var index = fileName.split(".")[fileName.split(".").length - 1];
			console.log(fileName,"filename")
			if (index.toLowerCase() == "csv") {
			} else {
				setCsvErrorDisplay("block");
				setCsvErrorMsg("Please upload only csv file.");
			}
			setCsvFileValue(e.target.files[0].name);
			setBrowse(e.target.files[0].name);
		} catch (e) {
			console.log("fff");
			setBrowse("Browse");
			setCsvFileValue("");
		}
		// console.log(e.target.files[0].name)
	};

	return (
		<React.Fragment>
			<div
				className={obj.addEditContactModal ? "modal fade show" : "modal fade"}
				style={{ display: obj.addEditContactModal ? "block" : "none" }}
				id="AddEditContactModal"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => oncloseForm()}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
						</div>
							{obj.optType=='static' ? (<Form
									noValidate
									autoComplete="off"
									id="AddEditContactFormModal"
									onValidSubmit={onValidSubmitHere}
								>
									{loading && <div className="ui loading form"></div>}
									<div
										id="AddEditContactFormModalBody"
										className="modal-body"
										style={mystyle}
									>
										<div className="kt-portlet__body py-0">
											<div className="kt-section kt-section--first">
												<Message color="red" style={{ display: modalErrorMsg }}>
													This email-id already exists.
												</Message>
												{formError!='' && <Message color="red" style={{ display: modalErrorMsg1 }}>
													{ formError }
												</Message>}
												<div className="kt-section__body">
													{drawLabelAndFields()}
			<div className="form-group row">
				<label className="col-lg-5 col-form-label text-capitalize">Schedule Date :</label>
				<div className="col-lg-7">
				<DatePicker
					onChange={setStartDateval}
					selected={startDate}
					minDate={minDate}
					minTime={minDate}
					maxTime={d}
					timeInputLabel="Time:"
					dateFormat="MMMM d, yyyy h:mm aa"
					timeFormat="HH:mm"
					showTimeInput
					className="form-control"
					popperContainer={Portal}
					name="datetime"
					excludeOutOfBoundsTimes
          />
		  <div class="red" style={{ display: modalErrorMsg1 }}>
		  {formdateerror != '' ? formdateerror : ''}
		</div>
				</div>
			</div>
			<div className="form-group row">
				<label className="col-lg-5 col-form-label text-capitalize">Bucket Name :</label>
				<div className="col-lg-7">
				<Form.Input
				fluid
				autoComplete="off"
				 validations="isRequired"
				 value={bucketname}
				 onChange={(e)=>checkbucket(e.target.value)}
				  readOnly={bucketname1!=="" ? true :false}
				validationErrors={{
					isRequired: "This field is required.",
				}}
				errorLabel={errorLabel}
				name='bucketname'
			/>
		 
		{duplicateBucket!="" && <div class="red">
		  {duplicateBucket }
		</div>}
				</div>
			</div>
												</div>
											</div>
										</div>
									</div>
									<div className="kt-portlet__foot">
										<div className="kt-form__actions">
											<div className="row">
												<div className="col-lg-12 d-flex justify-content-end">
													<button
														type="button"
														onClick={() => oncloseForm()}
														className="btn linear-gradient-cancel yr-cancelbtn mg-r"
													>
														<span> Cancel </span>
													</button>
													<button type="submit" className="btn linear-gradient yr-submitbtn">
														Add
													</button>
													<Form.Input type="Hidden" name="pid" value={obj.pid} />
													<Form.Input
														type="Hidden"
														name="user_id"
														value={userdata.user_id}
													/>
													<Form.Input
														type="Hidden"
														name="source"
														value={"static"}
													/>
												</div>
											</div>
										</div>
									</div>
								</Form>):(
								<Form
									noValidate
									autoComplete="off"
									id="AddEditContactFormModalCSV"
									onValidSubmit={onValidSubmitHereForCSV}
								>
									<div class="kt-portlet__body">
										<div class="kt-section kt-section--first">
											<div class="kt-section__body" style={{ padding: 20 }}>
												<Message
													color="red"
													style={{ display: modalErrorMsgCSV }}
												>
													{modalErrorMsgCSVMessage}
												</Message>
												<div class="form-group row">
													<label
														class="col-lg-12 col-form-label"
														style={{ color: "#20536b" }}
													>
														Please browse CSV. Before uploading CSV please make
														sure your CSV must have column heading and your
														column heading must match below titles.{" "}
														<a href='/assets/sample.csv'>Download our sample CSV</a> file to structure your import. 
													</label>
													<label
														class="col-lg-12"
														style={{ maxHeight: 104, overflow: "auto" }}
													>
														{FiledsArr()}
													</label>
												</div>

												<div class="form-group row automatedMarketing">
													<div class="col-lg-12">
														<div class="yr-workspace">
															<div class="uploadfile">
																<label
																	for="file-upload"
																	class="custom-file-upload"
																>
																	<i class="fa fa-cloud-upload"></i>
																	<div class="mh-CSVbrowse" title={browse}>
																		{browse}
																	</div>
																	<div
																		class="mh-CSVbrowseError"
																		style={{ display: csvErrorDisplay }}
																	>
																		{csvErrorMsg}
																	</div>
																	
																</label>
																<input
																	id="file-upload"
																	onChange={changeFileInputCSV}
																	name="contact_csv"
																	type="file"
																	onClick={(event) => {
																		event.target.value = null;
																		setCsvErrorDisplay("none");
																			setBrowse("Browse");
																			setCsvFileValue("");
																	}}
																	style={{ display: "none" }}
																/>
															</div>
														</div>
														
{/* schedule */}

<div className="form-group row">
				<label className="col-lg-5 col-form-label text-capitalize">Schedule Date :</label>
				<div className="col-lg-7">
				<DatePicker
            onChange={setStartDateval}
			selected={startDate}
            minDate={minDate}
            timeInputLabel="Time:"
            dateFormat="MMMM d, yyyy h:mm aa"
            showTimeInput
            className="form-control"
            name="datetime"
            excludeOutOfBoundsTimes
          />
		  <div class="red" style={{ display: modalErrorMsg1 }}>
		  {formdateerror != '' ? formdateerror : ''}
		</div>
		</div>
				
				
				</div>
				<div className="form-group row">
				<label className="col-lg-5 col-form-label text-capitalize">Bucket Name :</label>
				<div className="col-lg-7">
				<Form.Input
				fluid
				autoComplete="off"
				validations="isRequired"
				 value={bucketname}
				 onChange={(e)=>checkbucket(e.target.value)}
				validationErrors={{
					isRequired: "This field is required.",
				}}
				 readOnly={bucketname1!="" ? true:false}
				errorLabel={errorLabel}
				name='Bucket name'
			/>
		 
		{duplicateBucket!="" && <div class="red">
		  {duplicateBucket }
		</div>}
				</div>
			</div>
														{/* end schedule date */}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="kt-portlet__foot">
										<div class="kt-form__actions">
											<div class="row">
												<div class="col-lg-12 d-flex justify-content-end">
													<button
														onClick={() => modalCloseHere()}
														type="reset"
														class="btn yr-cancelbtn linear-gradient-cancel mg-r"
													>
														<span> Cancel </span>
													</button>
													<button disabled={buttonDisabled} type="submit" class="btn linear-gradient yr-submitbtn">
														Upload
													</button>
												</div>
											</div>
										</div>
									</div>
								</Form>
							)}

							
					
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default AddStaticContact;
