import React from "react";
import { ReactSortable } from "react-sortablejs";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import CustomErrorField from "../../Form/CustomErrorField";
import CreateBranch from "./CreateBranch";
import CreateLink from "./CreateLink";
import Modal from "../ChatSetup/components/Modal";

import {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setSubHeaderTitle,
  setNavigationName,
  toggleBreadcrum,
} from "../../../store/Page";
import { connect } from "react-redux";
import api from "../../../api";
import util_funs from "../../../util_funs";
const queryString = require("query-string");

class ManuallyCustomization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: "widget",
      fbData: null,
      pageData: null,
      selectedPage: null,
      pages: [],
      faceBookLogin: true,
      pageModal: false,
      subscribedPages: [],
      loading: true,
      message: null,
      reputationStart: 0,
      reputationType: "",
      ReviewAllCount: 0,
      StarCount: 0,
      AvgRating: 0,
      star_one: "star_custom_crs",
      star_two: "star_custom_crs",
      star_three: "star_custom_crs",
      star_four: "star_custom_crs",
      star_five: "star_custom_crs",
      reviewToaster: false,
      reviewToasterMsg: "",
      reviewToasterColor: "",
      subscribedLink: [],
      errors: [],
      BranchArea: false,
      create_modal_status: "",
      create_modal_link_status: "",
      branchList: [],
      button_respond_on_branch: "",
      button_respond_on: "",
      buttontitle: "",
      FacebookLink: "",
      GoogleLink: "",
      allowforreviews: "",
      reviewValidFacebookToaster: false,
      reviewValidFacebookToasterMsg: "",
      reviewValidFacebookToasterColor: "",
      reviewValidGoogleToaster: false,
      reviewValidGoogleToasterMsg: "",
      reviewValidGoogleToasterColor: "",
      linkToaster: false,
      linkToasterMsg: "",
      linkToasterColor: "",
      sno: 1,
      rm_bid: "",
      rm_bdescription: "",
      buttonDetails: [],
      editor_state: [],
      buttontitle: "",
      button_respond_on_branch: "",
      hiddenid: "",
      modalstatus: false,
      modalfooter: "",
      modaltitle: "",
      modalbody: "",
      teamData: [],
      msg_rm: "",
      msg_rm_error: "",
      editorstatedata: "",
      mrwbrl_id: 0,
      review_link_msg: "",
      mrwbrl_allow_for_reviews: "",
      mrwbrl_checked: "",
      update_branch_msg: "",
      branch_team_id: 0,
      deletemsg: "",
      review_error_msg: "",
      review_error_msg_fb: "",
      review_error_msg_google: "",
      add_branch_via: "",
      googleList: [],
      facebookList: [],
      facebook_respond: "",
      google_respond: "",
      show_center_review: 1,
      selectedlocation: 0,
      selectedBranchStatus: true,
      dropbranch: "",
    };
    this.GoogleData();
    this.FacebookData();
  }

  componentDidMount() {
    const {
      setPageTitle,
      toggleNavigation,
      setMessage,
      setSubHeaderTitle,
      setNavigationName,
      toggleBreadcrum,
      user,
    } = this.props;

    //alert( this.props.match.params.id );
    // this.setState({ wf_id: this.props.match.params.id });
    const wf_id = util_funs.dcrypt_url(this.props.match.params.id)
    this.setState({ wf_id: wf_id});

    setPageTitle({ title: "Your Review" });
    setSubHeaderTitle({ subHeaderTitle: "Review Management" });
    setNavigationName({ navigationName: "reviewManagement" });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    //const params = queryString.parse(location.search);

    // Write API Here Rohit
    api.reviews
      // .getManuallyreviewbranch({ wf_id: this.props.match.params.id })
      .getManuallyreviewbranch({ wf_id: wf_id })
      .then((data) => {
        try {
          //console.log( data.teamdata.teamlist );
          if(data.status===401){
            console.log(this.props.history)
            this.props.history.push('/notAllowed')
          }
          let FacebookValue = "";
          let GoogleValue = "";
          let html = data.data2.list2[0].mrwb_branch_description;
          let contentBlock = htmlToDraft(html);
          if (contentBlock) {
            let contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            let editorState = EditorState.createWithContent(contentState);
            let sta = this.state.editor_state;
            sta[0] = editorState;

            // console.log('onload run');
            // console.log(data.reviewLink[0].mrwbrl_facebook_link);

            //         let googleList = this.state.googleList;
            // let facebookList = this.state.facebookList;

            // let GoogleKey = googleList.findIndex((list) => list.id == google);
            // let FacebookKey = facebookList.findIndex((list) => list.id == fb);

            // let GoogleValue = googleList[GoogleKey].link;
            // let FacebookValue = facebookList[FacebookKey].link;

            if (data.reviewLink != "") {
              //alert(data.GetFacebookUrl);
              if (data.GetFacebookUrl == "" || data.GetFacebookUrl === null) {
                FacebookValue = "Hide facebook as an option";
              } else {
                FacebookValue = data.GetFacebookUrl.link;
              }
              if (data.GetGoogleUrl == "" || data.GetGoogleUrl === null) {
                GoogleValue = "Hide google as an option";
              } else {
                GoogleValue = data.GetGoogleUrl.link;
              }

              this.setState({
                branchdata: data.data.list,
                allteamlist: data.teamdata.teamlist,
                branchList: data.data.list,
                // buttonDetails: data.data.list[0].branch_buttons,
                current_branch_id: data.data.list[0].mrwb_id,
                branch_team_id: data.tm_id,

                rm_bid: data.data2.list2[0].mrwb_id,
                rm_btittle: data.data2.list2[0].mrwb_branch_tittle,
                editor_state: sta,
                buttonDetails: data.data2.list2[0].branch_buttons,

                mrwbrl_id: data.reviewLink[0].mrwbrl_id,
                mrwbrl_allow_for_reviews:
                  data.reviewLink[0].mrwbrl_allow_for_reviews,
                mrwbrl_facebook_link: FacebookValue,
                facebook_respond: data.reviewLink[0].mrwbrl_facebook_link,
                mrwbrl_google_link: GoogleValue,
                google_respond: data.reviewLink[0].mrwbrl_google_link,
                mrwbrl_custom_link: data.reviewLink[0].mrwbrl_custom_link,
              });
            } else {
              this.setState({
                branchdata: data.data.list,
                allteamlist: data.teamdata.teamlist,
                branchList: data.data.list,
                // buttonDetails: data.data.list[0].branch_buttons,
                current_branch_id: data.data.list[0].mrwb_id,

                rm_bid: data.data2.list2[0].mrwb_id,
                rm_btittle: data.data2.list2[0].mrwb_branch_tittle,
                editor_state: sta,
                buttonDetails: data.data2.list2[0].branch_buttons,
                branch_team_id: data.tm_id,

                mrwbrl_id: "",
                mrwbrl_allow_for_reviews: "",
                mrwbrl_facebook_link: "",
                facebook_respond: "",
                mrwbrl_google_link: "",
                google_respond: "",
                mrwbrl_custom_link: "",
              });
            }
          }
        } catch (err) {
          console.log(err);
        }
        this.setState({
          loading: false,
        });
      });

    // Write API Here

    if (user && user.user_id) {
      this.setState({ loading: true });
      api.reviews
        .getSubscribedPages({ userId: user.user_id })
        .then((resp) => {
          if (resp) {
            this.setState({ loading: false, subscribedPages: resp.pageList });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    }
    this.loadData();
  }
  loadData = () => {};
  togglePageModal(obj) {
    obj.setState({ pageModal: false });
  }
  handleClick = (selectedMenu) => this.setState({ activeMenu: selectedMenu });

  //ANKIT START -------

  GoogleData() {
    this.setState({
      loadinggoogle: true,
    });
    api.googleReview.getLocationList().then((data) => {
      try {
        this.setState({
          googleList: data.list,
        });
      } catch (err) {
        console.log(err);
      }
    });
  }
  FacebookData() {
    this.setState({
      loadingfb: true,
    });
    api.reviews.getFacebookList().then((data) => {
      // console.log(data);
      try {
        this.setState({
          facebookList: data.list,
        });
      } catch (err) {
        console.log(err);
      }
    });
  }

  openTab = (BoxType) => {
    //Rohit
    if (BoxType == "button") {
      this.setState({
        buttontitle: "",
        button_respond_on_branch: "",
        hiddenid: "",
        BranchArea: true,
        ReviewArea: false,
      });
      let scrolltop =
        document.querySelector(".rightbotbuilder").offsetTop -
        document.body.scrollTop;
      window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
    } else if (BoxType == "review") {
      if (this.state.mrwbrl_facebook_link == "") {
        document.getElementById("FacebookLink").value = "";
      } else {
        document.getElementById("FacebookLink").value =
          this.state.mrwbrl_facebook_link;
      }
      if (this.state.mrwbrl_google_link == "") {
        document.getElementById("GoogleLink").value = "";
      } else {
        document.getElementById("GoogleLink").value =
          this.state.mrwbrl_google_link;
      }
      this.setState({
        BranchArea: false,
        ReviewArea: true,
      });

      //Start Rview
      let params = { mrwb_b_id: this.state.rm_bid };
      api.reviews.getEditreviewlink(params).then((data) => {
        if (data.reviewLinkEdit != "") {
          let googleList = this.state.googleList;
          let facebookList = this.state.facebookList;
          let FacebookValue = "";
          let GoogleValue = "";

          let GoogleKey = googleList.findIndex(
            (list) => list.id == data.reviewLinkEdit[0].mrwbrl_google_link
          );
          let FacebookKey = facebookList.findIndex(
            (list) => list.id == data.reviewLinkEdit[0].mrwbrl_facebook_link
          );

          if (data.reviewLinkEdit[0].mrwbrl_google_link != 0) {
            if (GoogleKey != -1) {
              GoogleValue = googleList[GoogleKey].link;
            } else {
              GoogleValue = "Hide google as an option";
            }
          } else {
            GoogleValue = "Hide google as an option";
          }

          if (data.reviewLinkEdit[0].mrwbrl_facebook_link != 0) {
            if (FacebookKey != -1) {
              FacebookValue = facebookList[FacebookKey].link;
            } else {
              FacebookValue = "Hide facebook as an option";
            }
          } else {
            FacebookValue = "Hide facebook as an option";
          }

          this.setState({
            mrwbrl_id: data.reviewLinkEdit[0].mrwbrl_id,
            mrwbrl_allow_for_reviews:
              data.reviewLinkEdit[0].mrwbrl_allow_for_reviews,
            mrwbrl_facebook_link: FacebookValue,
            facebook_respond: data.reviewLinkEdit[0].mrwbrl_facebook_link,
            mrwbrl_google_link: GoogleValue,
            google_respond: data.reviewLinkEdit[0].mrwbrl_google_link,
            mrwbrl_custom_link: data.reviewLinkEdit[0].mrwbrl_custom_link,
          });
        } else {
          this.setState({
            mrwbrl_id: "",
            mrwbrl_allow_for_reviews: "",
            mrwbrl_facebook_link: "",
            facebook_respond: "",
            mrwbrl_google_link: "",
            google_respond: "",
            mrwbrl_custom_link: "",
          });
        }
      });

      //End Review
      let scrolltop =
        document.querySelector(".rightbotbuilder").offsetTop -
        document.body.scrollTop;
      window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
    }
  };

  popupClose = (BoxType) => {
    //alert(BoxType);
    if (BoxType == "button") {
      const errors = {};
      errors["buttontitle"] = "";
      errors["Branch"] = "";
      this.setState({
        BranchArea: false,
        ReviewArea: false,
      });
    } else if (BoxType == "review") {
      this.setState({
        BranchArea: false,
        ReviewArea: false,
        mrwbrl_custom_link: "",
      });
    }
  };

  createNewBranch = (modal, via = null) => {
    let params = { workspace_id: this.state.workspace_id };
    this.setState(
      { create_modal_status: modal, add_branch_via: via },
      function () {}
    );
  };

  createNewLink = (modal, via = null) => {
    let params = { workspace_id: this.state.workspace_id };
    this.setState(
      { create_modal_link_status: modal, add_branch_via: via },
      function () {}
    );
  };

  modalCloseCreate = () => {
    this.setState({
      create_modal_status: "",
      add_branch_via: "",
    });
  };

  onEditorStateChange = (editorState, index) => {
    let sta = this.state.editor_state;
    sta[index] = editorState;
    let editorSourceHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    this.setState({
      editorstatedata: editorSourceHTML,
    });
  };
  updateBranch = (rm_bid, rm_btittle, rm_des) => {
    this.setState({ loading: true });
    let rm_branch_tittle = document.getElementById("rm_branch_tittle").value;
    let editorVal = draftToHtml(convertToRaw(rm_des.getCurrentContent()));
    //  let teamId    =  document.getElementById("rm_tm_id").value;
    let params = {
      // wf_id: this.props.match.params.id,
      wf_id: util_funs.dcrypt_url(this.props.match.params.id),
      rm_bid: rm_bid,
      rm_btittle: rm_branch_tittle,
      editorVal: editorVal,
    };
    api.reviews.updateBranch(params).then((data) => {
      //console.log( data.data );

      this.setState({
        branchdata: data.branchdata.branchlist,
        branchList: data.branchdata.branchlist,
        update_branch_msg: "Branch updated successfully.",
        loading: false,
      });
      setTimeout(
        function () {
          this.setState({ update_branch_msg: false });
        }.bind(this),
        3000
      );
    });
    let scrolltop =
      document.querySelector(".addEditBranch").offsetTop -
      document.body.scrollTop;
    window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
  };
  saveBranch = () => {
    //alert('hi...');
  };

  saveNewBranch = (data, button, callback) => {
    let params = {
      // wf_id: this.props.match.params.id,
      wf_id: util_funs.dcrypt_url(this.props.match.params.id),
      workspace_id: this.state.workspace_id,
      data: data,
    };
    api.reviews.addNewBranch(params).then((data) => {
      let branch_id = data.data[0].mrwb_id;
      let branchList = this.state.branchList;
      branchList.push(data.data[0]);
      if (data.reviewLink != "") {
        this.setState({
          mrwbrl_id: data.reviewLink[0].mrwbrl_id,
          mrwbrl_allow_for_reviews: data.reviewLink[0].mrwbrl_allow_for_reviews,
          mrwbrl_facebook_link: data.reviewLink[0].mrwbrl_facebook_link,
          facebook_respond: data.reviewLinkEdit[0].mrwbrl_facebook_link,
          mrwbrl_google_link: data.reviewLink[0].mrwbrl_google_link,
          google_respond: data.reviewLink[0].mrwbrl_google_link,
          branch_team_id: data.tm_id,

          branchdata: branchList,
          button_action_id: "create_branch",
          branch_new_id: branch_id,
          button_respond_on_branch: branch_id,
          loading: false,
        });
      } else {
        this.setState(
          {
            branchdata: branchList,
            button_action_id: "create_branch",
            branch_new_id: branch_id,
            button_respond_on_branch: branch_id,
            loading: false,
          },
          function () {
            this.modalCloseCreate();
            if (this.state.add_branch_via == "main") {
              //alert( data.lastrow);
              this.editBranch(data.insert_id);
              this.onSelectBranch(data.lastrow);
            }
            callback("success");
          }
        );
      }
    });
  };

  saveNewLink = (data, button, callback) => {
    // console.log("passed data");
    // console.log(data.social_link);

    if (data.social_account == 1) {
      const sendData = { link: data.social_link };
      api.reviews.addReviewsLinkFacebook(sendData).then((data) => {
        //console.log(data);
        let mrwbrl_facebook_link = data.data.id;
        let facebookList = this.state.facebookList;
        facebookList.push(data.data);
        try {
          this.setState({
            phone: "",
            submitButton: 0,
            loading: false,
            facebookList: facebookList,
            //mrwbrl_facebook_link: mrwbrl_facebook_link,
            facebook_respond: mrwbrl_facebook_link,
            link_selected: 0,
          });
          this.modalLinkCloseCreate();
          // this.showToasterReviews(
          //   "green",
          //   "Review link has been added successfully."
          // );
          //document.getElementById("reviewForm").reset();
        } catch (err) {
          console.log(err);
        }
      });
    } else if (data.social_account == 2) {
      const sendData = { link: data.social_link };
      api.reviews.addReviewsLinkGoogle(sendData).then((data) => {
        let mrwbrl_google_link = data.data.id;
        let googleList = this.state.googleList;
        googleList.push(data.data);
        try {
          this.setState({
            link: "",
            submitButton: 0,
            loading: false,
            googleList: googleList,
            //mrwbrl_google_link: mrwbrl_google_link,
            google_respond: mrwbrl_google_link,
            link_selected: 0,
          });
          this.modalLinkCloseCreate();
          // this.showToasterReviews(
          //   "green",
          //   "Review link has been added successfully."
          // );
          //document.getElementById("reviewForm2").reset();
        } catch (err) {
          console.log(err);
        }
      });
    }
  };

  changeBranch = (e) => {
    this.setState({ button_respond_on_branch: e.target.value });
  };

  onchange = (e) => {
    this.setState({ buttontitle: e.target.value });
  };

  onchangeBranch = (e) => {
    this.setState({ rm_btittle: e.target.value });
  };

  onchangereviewfb = (e) => {
    this.setState({
      facebook_respond: e.target.value,
      show_center_review: 1,
    });
  };

  customlink = (e) => {
    this.setState({
      mrwbrl_custom_link: e.target.value,
    });
  };

  onchangereviewgoogle = (e) => {
    this.setState({
      google_respond: e.target.value,
      show_center_review: 1,
    });
  };

  saveButton = (c_branch_id) => {
    const errors = {};
    let buttontitle_erro = "";
    let branch_erro = "";
    this.setState({ loading: true });
    if (this.state.buttontitle == "") {
      buttontitle_erro = "This field is required.";
      this.setState({ loading: false, button_title_erro: buttontitle_erro });
      setTimeout(
        function () {
          this.setState({ button_title_erro: false });
        }.bind(this),
        3000
      );
    } else if (this.state.button_respond_on_branch == "") {
      branch_erro = "This field is required.";
      this.setState({ loading: false, branch_button_erro: branch_erro });
      setTimeout(
        function () {
          this.setState({ branch_button_erro: false });
        }.bind(this),
        3000
      );
    } else {
      this.setState({ errors: {}, loading: false }, function () {
        let data_set = {
          dbid: this.state.hiddenid,
          buttontitle: this.state.buttontitle,
          button_respond_on_branch: this.state.button_respond_on_branch,
          current_branch_id: this.state.current_branch_id,
        };
        let params = {
          rm_bid: c_branch_id,
          workspace_id: this.state.workspace_id,
          data: data_set,
        };
        api.reviews.addNewButton(params).then((data) => {
          //console.log( data.buttons_de_active );
          // this.setState(function () {
          //   this.popupClose("button");
          // });
          //let buttonDetails = this.state.buttonDetails;
          let buttonDetails = data.branch_buttons;

          //buttonDetails.push(data_set);
          this.setState(
            { buttonDetails: buttonDetails, loading: false },
            function () {
              this.popupClose("button");
            }
          );
          //console.log(buttonDetails);
        });
      });
      let scrolltop =
        document.querySelector(".buttonlistbtbuilder").offsetTop -
        document.body.scrollTop;
      window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
      this.popupClose("button");
    }
  };
  assignTeam = (rm_bid) => {
    if (rm_bid != "") {
      let rm_tm_id = document.getElementById("rm_tm_id").value;
      let params = { rm_tm_id: rm_tm_id, current_branch_id: rm_bid };
      api.reviews.aadTeammember(params).then((data) => {
        //console.log( data.data );
        this.setState({ msg_rm: data.data, branch_team_id: data.tm_id });
        setTimeout(
          function () {
            this.setState({ msg_rm: false });
          }.bind(this),
          3000
        );
      });
    } else {
      this.setState({ msg_rm_error: "Branch field is empty." });
      setTimeout(
        function () {
          this.setState({ msg_rm_error: false });
        }.bind(this),
        3000
      );
    }
  };
  saveReview = (mrwbrl_id) => {
    //alert('hi');

    let fb = document.getElementById("FacebookLink").value;
    let google = document.getElementById("GoogleLink").value;
    let custom_link = document.getElementById("custom_link").value;
    //alert(custom_link);

    // console.log(this.state.googleList);
    // console.log(this.state.facebookList);
    // console.log(fb);
    // console.log(google);

    if (fb != "" || google != "") {
      this.setState({ loading: true }, function () {
        let data_set = {
          FacebookLink: fb,
          GoogleLink: google,
          allowforreviews: this.state.mrwbrl_allow_for_reviews,
          custom_link: custom_link,
          branch_id: "8",
        };
        let params = {
          mrwbrl_id: mrwbrl_id,
          rm_bid: this.state.rm_bid,
          data: data_set,
        };
        api.reviews.addReviewLink(params).then((data) => {
          let googleList = this.state.googleList;
          let facebookList = this.state.facebookList;
          let FacebookValue = "";
          let GoogleValue = "";

          let GoogleKey = googleList.findIndex((list) => list.id == google);
          let FacebookKey = facebookList.findIndex((list) => list.id == fb);

          if (google != 0) {
            GoogleValue = googleList[GoogleKey].link;
          } else {
            GoogleValue = "Hide google as an option";
          }
          if (fb != 0) {
            FacebookValue = facebookList[FacebookKey].link;
          } else {
            FacebookValue = "Hide facebook as an option";
          }

          if (data.reviewLink != "") {
            this.setState({
              review_link_msg: data.message,
              mrwbrl_id: data.reviewLink[0].mrwbrl_id,
              mrwbrl_allow_for_reviews:
                data.reviewLink[0].mrwbrl_allow_for_reviews,
              mrwbrl_facebook_link: FacebookValue,
              facebook_respond: data.reviewLink[0].mrwbrl_facebook_link,
              mrwbrl_google_link: GoogleValue,
              google_respond: data.reviewLink[0].mrwbrl_google_link,
              mrwbrl_custom_link: data.reviewLink[0].mrwbrl_custom_link,
              show_center_review: 1,
              loading: false,
            });
            this.popupClose("review");
          } else {
            this.setState({
              review_link_msg: data.message,
              mrwbrl_id: "",
              mrwbrl_allow_for_reviews: "",
              mrwbrl_facebook_link: "",
              facebook_respond: "",
              mrwbrl_google_link: "",
              google_respond: "",
              show_center_review: 0,
              loading: false,
            });
          }
          setTimeout(
            function () {
              this.setState({ review_link_msg: false, review_error_m: false });
            }.bind(this),
            5000
          );

          let scrolltop =
            document.querySelector(".showReviewtab").offsetTop -
            document.body.scrollTop;
          window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
        });
      });
    } else {
      this.setState({
        review_error_msg: "Please fill at least one field.",
      });
      setTimeout(
        function () {
          this.setState({ review_error_msg: false });
        }.bind(this),
        5000
      );
    }
  };

  showToasterLink = (color, msg) => {
    this.setState({
      linkToaster: true,
      linkToasterMsg: msg,
      linkToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ linkToaster: false });
      }.bind(this),
      3000
    );
  };

  handleCheck = (key) => {
    const target = key.target;
    //alert(target.checked);
    if (target.checked) {
      this.setState({
        [target.name]: "checked",
        mrwbrl_allow_for_reviews: "checked",
      });
    } else {
      this.setState({
        [target.name]: "",
        mrwbrl_allow_for_reviews: "",
      });
    }
  };

  buttonAction = (action, buttontitle, button_respond_on_branch, hiddenid) => {
    if (action == "edit") {
      this.setState(
        {
          BranchArea: true,
          ReviewArea: false,
          buttontitle: buttontitle,
          button_respond_on_branch: button_respond_on_branch,
          hiddenid: hiddenid,
        },
        function () {
          let scrolltop =
            document.querySelector(".rightbotbuilder").offsetTop -
            document.body.scrollTop;
          window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
        }
      );
    }
  };
  deleteButton = (bid, rm_bid) => {
    //alert(bid);
    this.popupClose();
    this.setState({
      modalstatus: true,
      modaltitle: "Confirm Your Action",
      modalbody: "Are you sure you want to delete?",
      del_branch_id: bid,
      button_branch_id: rm_bid,
    });
  };

  modalFooter = (index) => {
    if (index == "finalsave") {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() => this.confirmFinalSave()}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    } else if (index == "multiplechoicedelete") {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() => this.confirmdeleteMultipleChoiceQuestion()}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    } else {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() =>
              this.confirmDeleteButton(
                this.state.del_branch_id,
                this.state.button_branch_id
              )
            }
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    }
  };

  modalFooterBranch = () => {
    return (
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => this.modalCloseBranch()}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"
        >
          <span>No</span>
        </button>
        <button
          type="button"
          onClick={() =>
            this.confirmDeleteBranch(this.state.branch_id, this.state.rm_bid)
          }
          className="btn linear-gradient yr-submitbtn btn-sm"
        >
          <span>Yes</span>
        </button>
      </div>
    );
  };

  modalFooterReview = () => {
    return (
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => this.modalCloseReview()}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"
        >
          <span>No</span>
        </button>
        <button
          type="button"
          onClick={() => this.confirmDeleteReview(this.state.Review_id)}
          className="btn linear-gradient yr-submitbtn btn-sm"
        >
          <span>Yes</span>
        </button>
      </div>
    );
  };

  confirmDeleteButton = (id, branch_id) => {
    //alert(id);
    let params = { id: id, branch_id: branch_id };
    api.reviews.deleteButton(params).then((data) => {
      //  data.branch_buttons.splice(id, 1);
      let buttonDetails = data.branch_buttons;
      this.setState({ buttonDetails: buttonDetails }, function () {
        this.modalClose();
        this.popupClose("button");
      });
      //alert(id)
    });
  };
  modalClose = () => {
    this.setState({
      modalstatus: false,
      modaltitle: "",
      modalbody: "",
      modalindex: "",
    });
  };

  modalCloseCreate = () => {
    this.setState({
      create_modal_status: "",
    });
  };

  modalLinkCloseCreate = () => {
    this.setState({
      create_modal_link_status: "",
    });
  };
  //ANKIT END -------
  // @Rohit
  editBranch = (mrwb_b_id) => {
    //alert(mrwb_b_id);

    this.setState({ loading: true, mrwbrl_custom_link: "" });
    let params = { mrwb_b_id: mrwb_b_id };
    api.reviews.getBranchdetails(params).then((data) => {
      let html = data.data.list[0].mrwb_branch_description;
      let contentBlock = htmlToDraft(html);
      if (contentBlock) {
        let contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        let editorState = EditorState.createWithContent(contentState);
        let sta = this.state.editor_state;
        sta[0] = editorState;

        if (data.reviewLink != "") {
          let googleList = this.state.googleList;
          let facebookList = this.state.facebookList;
          let FacebookValue = "";
          let GoogleValue = "";

          let GoogleKey = googleList.findIndex(
            (list) => list.id == data.reviewLink[0].mrwbrl_google_link
          );
          let FacebookKey = facebookList.findIndex(
            (list) => list.id == data.reviewLink[0].mrwbrl_facebook_link
          );

          if (data.reviewLink[0].mrwbrl_google_link != 0) {
            if (GoogleKey != -1) {
              GoogleValue = googleList[GoogleKey].link;
            } else {
              GoogleValue = "Hide google as an option";
            }
          } else {
            GoogleValue = "Hide google as an option";
          }
          if (data.reviewLink[0].mrwbrl_facebook_link != 0) {
            if (FacebookKey != -1) {
              FacebookValue = facebookList[FacebookKey].link;
            } else {
              FacebookValue = "Hide facebook as an option";
            }
            //FacebookValue = facebookList[FacebookKey].link;
          } else {
            FacebookValue = "Hide facebook as an option";
          }

          this.setState({
            rm_bid: data.data.list[0].mrwb_id,
            rm_btittle: data.data.list[0].mrwb_branch_tittle,
            editor_state: sta,
            buttonDetails: data.data.list[0].branch_buttons,

            mrwbrl_id: data.reviewLink[0].mrwbrl_id,
            mrwbrl_allow_for_reviews:
              data.reviewLink[0].mrwbrl_allow_for_reviews,
            mrwbrl_facebook_link: FacebookValue,
            facebook_respond: data.reviewLink[0].mrwbrl_facebook_link,
            mrwbrl_google_link: GoogleValue,
            google_respond: data.reviewLink[0].mrwbrl_google_link,
            mrwbrl_custom_link: data.reviewLink[0].mrwbrl_custom_link,
            branch_team_id: data.tm_id,
            loading: false,
          });
          let scrolltop =
            document.querySelector(".addEditBranch").offsetTop -
            document.body.scrollTop;
          window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
        } else {
          this.setState({
            rm_bid: data.data.list[0].mrwb_id,
            rm_btittle: data.data.list[0].mrwb_branch_tittle,
            editor_state: sta,
            buttonDetails: data.data.list[0].branch_buttons,
            branch_team_id: data.tm_id,

            mrwbrl_id: "",
            mrwbrl_allow_for_reviews: "",
            mrwbrl_facebook_link: "",
            facebook_respond: "",
            mrwbrl_google_link: "",
            google_respond: "",
            mrwbrl_custom_link: "",
            loading: false,
          });
          let scrolltop =
            document.querySelector(".addEditBranch").offsetTop -
            document.body.scrollTop;
          window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
        }
      }
      if (this.state.add_branch_via != "main") {
        let scrolltop =
          document.querySelector(".addEditBranch").offsetTop -
          document.body.scrollTop;
        window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
      }
    });
    this.popupClose("button");
    this.popupClose("review");
  };

  copyBranch = (mrwb_b_id, e) => {
    // e.stopPropagation();
    let params = { wf_id: util_funs.dcrypt_url(this.props.match.params.id), mrwb_b_id: mrwb_b_id };
    api.reviews.addCopybranch(params).then((data) => {
      let branchList = data.data.list;
      this.setState({
        branchdata: branchList,
        branchList: branchList,
      });
    });
  };

  //@Rohit
  deleteBranchPop = (mrwb_b_id, e) => {
    //alert( mrwb_b_id );
    //this.popupCloseBranch();
    e.stopPropagation();
    this.setState({
      modalstatusBranch: true,
      modaltitleBranch: "Confirm Your Action",
      modalbodyBranch: "Are you sure you want to delete?",
      branch_id: mrwb_b_id,
    });
  };

  //@Rohit
  confirmDeleteBranch = (mrwb_b_id, openbranchid) => {
    this.setState({ loading: true });
    let params = {
      wfid: util_funs.dcrypt_url(this.props.match.params.id),
      openbranchid: openbranchid,
      mrwb_b_id: mrwb_b_id,
    };
    api.reviews.deleteBranchbyid(params).then((data) => {
      if (data.isopen == 0) {
        let branchList = data.data.list;
        this.setState({
          branchdata: branchList,
          branchList: branchList,
          loading: false,
        });
      } else {
        let html = data.data2.list2[0].mrwb_branch_description;
        let contentBlock = htmlToDraft(html);
        if (contentBlock) {
          let contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          let editorState = EditorState.createWithContent(contentState);
          let sta = this.state.editor_state;
          sta[0] = editorState;

          if (data.reviewLink != "") {
            this.setState({
              branchdata: data.data.list,
              allteamlist: data.teamdata.teamlist,
              branchList: data.data.list,
              // buttonDetails: data.data.list[0].branch_buttons,
              current_branch_id: data.data.list[0].mrwb_id,
              branch_team_id: data.tm_id,

              rm_bid: data.data2.list2[0].mrwb_id,
              rm_btittle: data.data2.list2[0].mrwb_branch_tittle,
              editor_state: sta,
              buttonDetails: data.data2.list2[0].branch_buttons,

              mrwbrl_id: data.reviewLink[0].mrwbrl_id,
              mrwbrl_allow_for_reviews:
                data.reviewLink[0].mrwbrl_allow_for_reviews,
              mrwbrl_facebook_link: data.reviewLink[0].mrwbrl_facebook_link,
              facebook_respond: data.reviewLink[0].mrwbrl_facebook_link,
              mrwbrl_google_link: data.reviewLink[0].mrwbrl_google_link,
              google_respond: "",
              mrwbrl_custom_link: data.reviewLink[0].mrwbrl_custom_link,
              loading: false,
            });
          } else {
            this.setState({
              branchdata: data.data.list,
              allteamlist: data.teamdata.teamlist,
              branchList: data.data.list,
              // buttonDetails: data.data.list[0].branch_buttons,
              current_branch_id: data.data.list[0].mrwb_id,

              rm_bid: data.data2.list2[0].mrwb_id,
              rm_btittle: data.data2.list2[0].mrwb_branch_tittle,
              editor_state: sta,
              buttonDetails: data.data2.list2[0].branch_buttons,
              branch_team_id: data.tm_id,

              mrwbrl_id: "",
              mrwbrl_allow_for_reviews: "",
              mrwbrl_facebook_link: "",
              facebook_respond: "",
              mrwbrl_google_link: "",
              google_respond: "",
              mrwbrl_custom_link: "",
              loading: false,
            });
          }
        }
        this.onSelectBranch(0);
      }
      let scrolltop =
        document.querySelector(".addEditBranch").offsetTop -
        document.body.scrollTop;
      window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
    });
    this.modalCloseBranch();
  };

  modalCloseBranch = () => {
    this.setState({
      modalstatusBranch: false,
      modaltitleBranch: "",
      modalbodyBranch: "",
      modalindexBranch: "",
    });
  };

  //@Rohit Review start
  deleteReviewPop = (r_id) => {
    //alert(bid);
    //this.popupCloseBranch();
    this.setState({
      modalstatusReview: true,
      modaltitleReview: "Confirm Your Action",
      modalbodyReview: "Are you sure you want to delete?",
      Review_id: r_id,
    });
  };

  //@Rohit
  confirmDeleteReview = (r_id) => {
    //alert( r_id );
    let params = { mrwbrl_id: r_id };
    api.reviews.deleteReviewbyid(params).then((data) => {
      this.setState({
        mrwbrl_facebook_link: "",
        facebook_respond: "",
        mrwbrl_google_link: "",
        google_respond: "",
        mrwbrl_custom_link: "",
        deletemsg: data.deletemsg,
      });
      setTimeout(
        function () {
          this.setState({ deletemsg: false });
        }.bind(this),
        3000
      );
      this.popupClose("review");
      this.modalCloseReview();
    });
  };
  modalCloseReview = () => {
    this.setState({
      modalstatusReview: false,
      modaltitleReview: "",
      modalbodyReview: "",
      modalindexReview: "",
    });
  };


  // START ADDED BY ANKIT FOR DRAG, DROP AND MOVE FUNCTIONALITY =================
  onchangeDropbranch = (e) => {

    this.setState({ [e.target.name]: e.target.name });

    if (e.target.value != "") {
      this.orderBranchList(e.target.value, (callback) => {
        var elems = document.querySelectorAll(".borderset");

        for (var index = 0; index < elems.length; index++) {
          elems[index].style.border = "none";
        }
        document.querySelector(".direction").disabled = true;
        document.querySelector(".branchcount").disabled = true;
        this.setState({ [e.target.name]: "" });

        api.reviews
          .updateposition({ btList: this.state.branchdata })
          .then((data) => {
            //console.log(data);
          });

      });
    }

  };

    // ROHIT CODE onSelectBranch already define ----------------- 
  onSelectBranch = (key) => {
    var elems = document.querySelectorAll(".borderset");
    for (var index = 0; index < elems.length; index++) {
      elems[index].style.border = "none";
      if (index + 1 == elems.length) {
        elems[key].style.border = "1px solid rgb(15, 98, 254)";
        this.setState({ selectedlocation: key });
        var attr = elems[key].parentElement.getAttribute("branch_id");
        var attr2 = elems[key].parentElement.getAttribute("id");
        var res = attr2.split("first_");
        this.editBranch(attr, res[1]);
      }
    }

    var elems2 = document.querySelector('.branchcount').options;
    for (var index2 = 0; index2 < elems2.length; index2++) {
      // elems2[index2].disabled = false;
      // elems2[index2].style.backgroundColor = "#fff";
      document.querySelector('.direction').disabled = false;
      document.querySelector('.branchcount').disabled = false;
    }

  };


  orderBranchList = (movelocation, callback) => {
    var order = document.querySelector(".direction").value;
    var selectedlocation = this.state.selectedlocation;

    if (order == "up" && selectedlocation == movelocation - 1) {
      callback(0);
    } else if (order == "down" && selectedlocation == movelocation + 1) {
      callback(0);
    } else {
      let tempVal = this.state.branchdata[selectedlocation];
      this.state.branchdata.splice(selectedlocation, 1);
      if (order == "up") {
        if (movelocation > selectedlocation)
          this.state.branchdata.splice(parseInt(movelocation) - 1, 0, tempVal);
        else this.state.branchdata.splice(movelocation, 0, tempVal);
      }
      if (order == "down") {
        if (movelocation > selectedlocation) {
          this.state.branchdata.splice(movelocation, 0, tempVal);
        } else {
          this.state.branchdata.splice(parseInt(movelocation) + 1, 0, tempVal);
        }
      }
      callback(1);
    }
  };

  onEndOrder = () => {
    api.reviews.updateposition({ 'btList': this.state.branchdata }).then(data => {
      console.log(data);
    })
  }
  // END ADDED BY ANKIT =================


  render() {
    const errorLabel = <div className="red" />;
    const { editorState, contentState, errors } = this.state;
    const branchlist = this.state.branchdata;
    const rmteamlist = this.state.allteamlist;
    const editfunction = this.editBranch;
    const copyfunction = this.copyBranch;
    const deletefunction = this.deleteBranch;
    const deletefunctionPop = this.deleteBranchPop;
    const onSelectBranch = this.onSelectBranch;
    const isnew = this.state.add_branch_via;
    let sn = 0;
    let { show, title, children, width, height, message } = this.props;
    if (!width) width = "500px";
    if (!height) height = "300px";

    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-content kt-grid__item kt-grid__item--fluid">
          <div className="yr-body-p">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                {this.state.loading && <div className="ui loading form"></div>}
                <div>
                  <div className="kt-portlet shadow-none">
                    <div className="kt-portlet__body yr-pd0-lr yr-mt-20 yr-pd0-mob">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="box-title mx-lg-3">
                            Manually customization
                          </div>
                          <div className="">
                            <div
                              className="review-yr-chat-round-box box-bg"
                              style={{
                                maxHeight: "865px",
                                overflow: "auto",
                                paddingTop: "0px",
                              }}
                            >
                              <div>
                                <div
                                  className="row yr-sticky-header"
                                  style={{ zIndex: 12 }}
                                >
                                  <div className="col-lg-2">
                                    <button
                                      type="button"
                                      title="Add Branch"
                                      className="btn  btn-sm btn-clean btn-icon"
                                      onClick={() =>
                                        this.createNewBranch("modal", "main")
                                      }
                                    >
                                      <img
                                        src="../../../assets/media/icons/plus.svg"
                                        alt=""
                                        width={25}
                                      />
                                    </button>
                                  </div>
                                  <div className="col-lg-12 d-flex justify-content-end">
                                    <button
                                      type="button"
                                      className="btn btn-clean btn-bold move-btn"
                                    >
                                      Move
                                    </button>
                                    <div className="inner-wrapper">
                                      <select
                                        className="form-control ml-2 direction"
                                        name="dropdirection"
                                        disabled={
                                          this.state.selectedBranchStatus ==
                                          false
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="down">Below</option>
                                        <option value="up">Above</option>
                                      </select>
                                      <select
                                        name="dropbranch"
                                        className="form-control ml-2 branchcount"
                                        value={this.state.dropbranch}
                                        onChange={(e) =>
                                          this.onchangeDropbranch(e)
                                        }
                                        disabled={
                                          this.state.selectedBranchStatus ==
                                          false
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="">Branch #</option>
                                        {this.state.branchdata &&
                                          this.state.branchdata.map(
                                            (value, index) => {
                                              if (
                                                this.state.selectedlocation ==
                                                index
                                              ) {
                                                return (
                                                  <option
                                                    value={index}
                                                    key={index}
                                                    disabled
                                                    style={{
                                                      backgroundColor: "#ccc",
                                                    }}
                                                  >
                                                    {index + 1}
                                                  </option>
                                                );
                                              } else {
                                                return (
                                                  <option
                                                    value={index}
                                                    key={index}
                                                  >
                                                    {index + 1}
                                                  </option>
                                                );
                                              }
                                            }
                                          )}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                {/* from hare */}
                                      {this.state.branchdata &&       
                                <ReactSortable
                                  animation={400}
                                  list={this.state.branchdata}
                                  setList={(newState) =>
                                    this.setState({ branchdata: newState })
                                  }
                                  onEnd={() => this.onEndOrder()}
                                >
                                  {this.state.branchdata &&
                                    Object.keys(branchlist).map(function (key) {
                                      let b_id = sn;
                                      let divid = "first_" + b_id;
                                      sn++;

                                      return (
                                        <div
                                          id={divid}
                                          branch_id={branchlist[key].mrwb_id}
                                          className="cursorpointer"
                                        >
                                          <div
                                            className="yr-chat-round-box inner-box borderset"
                                            data-branch={key}
                                            data-id={b_id}
                                            onClick={() => onSelectBranch(key)}
                                          >
                                            {
                                              //Check if message failed
                                              branchlist[key].mrwb_id != "" ? (
                                                <header className="box-head mb-2">
                                                  <div
                                                    class="col-lg-12 review-right-action-btns"
                                                    style={{
                                                      textAlign: "right",
                                                      marginRight: 0,
                                                    }}
                                                  >
                                                    <button
                                                      type="button"
                                                      className="btn btn-icon autoicon"
                                                      title="Copy"
                                                      onClick={(e) =>
                                                        copyfunction(
                                                          branchlist[key]
                                                            .mrwb_id,
                                                          e
                                                        )
                                                      }
                                                    >
                                                      <i className="la la-clone red"></i>
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="btn btn-icon autoicon"
                                                      title="Edit"
                                                      onClick={() =>
                                                        editfunction(
                                                          branchlist[key]
                                                            .mrwb_id
                                                        )
                                                      }
                                                    >
                                                      <i className="la la-edit blue"></i>
                                                    </button>

                                                    {
                                                      //Check if message failed
                                                      b_id != 0 ? (
                                                        <button
                                                          type="button"
                                                          className="btn btn-icon autoicon"
                                                          title="Delete"
                                                          onClick={(e) =>
                                                            deletefunctionPop(
                                                              branchlist[key]
                                                                .mrwb_id,
                                                              e
                                                            )
                                                          }
                                                        >
                                                          <i className="la la-trash red"></i>
                                                        </button>
                                                      ) : (
                                                        ""
                                                      )
                                                    }
                                                  </div>
                                                  <div className="col-lg-12">
                                                    <div
                                                      style={{
                                                        marginBottom: 13,
                                                        marginLeft: -10,
                                                      }}
                                                      className="small-title cursorpointer"
                                                      onClick={() =>
                                                        editfunction(
                                                          branchlist[key]
                                                            .mrwb_id
                                                        )
                                                      }
                                                    >
                                                      {
                                                        //Check if message failed
                                                        b_id != 0 ? (
                                                          <span className="kt-badge kt-badge--success kt-badge--square pre-number-box">
                                                            {b_id}
                                                          </span>
                                                        ) : (
                                                          ""
                                                        )
                                                      }
                                                      <span class="boxhastitle">
                                                        {
                                                          branchlist[key]
                                                            .mrwb_branch_tittle
                                                        }
                                                      </span>
                                                      <div></div>
                                                    </div>
                                                  </div>
                                                </header>
                                              ) : (
                                                ""
                                              )
                                            }
                                            <section
                                              className="body-text mb-3 question_map_image"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  branchlist[key]
                                                    .mrwb_branch_description,
                                              }}
                                            ></section>
                                            {/* {
                                                      ReactHtmlParser ( branchlist[key].mrwb_branch_description )
                                                  } */}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </ReactSortable>
  }
                                {this.state.branchdata ? "" : "No found."}

                                {/* End */}
                              </div>
                              <div id=""></div>
                              <div id="leftsidebar"></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5 workSpaceAddField">
                          <div className="box-title mx-lg-3">&nbsp;</div>
                          <div className="yr-chat-round-box addEditBranch">
                            <div className="row">
                              {this.state.update_branch_msg != "" ? (
                                <div className="col-lg-12 ui green message del-msg-padding">
                                  {this.state.update_branch_msg}
                                </div>
                              ) : (
                                <div></div>
                              )}
                              <div
                                className="add-branch-title col-md-12"
                                style={{ marginBottom: 10 }}
                              >
                                Add/Edit Branch
                              </div>
                            </div>
                            <div className="form-group mb-1">
                              <div>
                                {
                                  //Check if message failed
                                  this.state.rm_btittle != "" ? (
                                    <input
                                      className={
                                        "col-form-label col-lg-12 welcome-branch form-control"
                                      }
                                      type="text"
                                      id="rm_branch_tittle"
                                      value={this.state.rm_btittle}
                                      onChange={(e) => this.onchangeBranch(e)}
                                    ></input>
                                  ) : (
                                    <input
                                      className={
                                        "col-form-label col-lg-12 welcome-branch form-control"
                                      }
                                      type="text"
                                      defaultValue={"untitled"}
                                    ></input>
                                  )
                                }

                                <CustomErrorField className="error invalid-feedback" />
                              </div>
                              <div
                                className="col-lg-12 px-0"
                                className={"col-lg-12 px-0 focusError"}
                                style={{ marginTop: "10px" }}
                              >
                                <Editor
                                  editorState={this.state.editor_state[0]}
                                  onEditorStateChange={(e) =>
                                    this.onEditorStateChange(e, 0)
                                  }
                                  wrapperClassName="botbuilder-wrapper-class"
                                  editorClassName="botbuilder-editor-class"
                                  toolbarClassName="botbuilder-toolbar-class"
                                  toolbar={{
                                    options: [
                                      "inline",
                                      "list",
                                      "link",
                                      "emoji",
                                      "image",
                                      "embedded",
                                      "history",
                                    ],
                                    inline: {
                                      inDropdown: false,
                                      options: ["bold", "italic"],
                                    },
                                    list: {
                                      inDropdown: false,
                                      options: ["unordered", "ordered"],
                                    },
                                    link: {
                                      inDropdown: false,
                                      options: ["link"],
                                    },
                                    history: { inDropdown: false },
                                    embedded: {
                                      defaultSize: {
                                        height: "auto",
                                        width: "250",
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <button
                                  className="btn add-link textleft"
                                  onClick={() => this.openTab("review")}
                                >
                                  +Add review links
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  className="btn add-link pull-right"
                                  onClick={() => this.openTab("button")}
                                >
                                  +Add button
                                </button>
                              </div>
                              <div className="col-12">&nbsp;</div>

                              {/* Review List Start From Hare */}
                              {this.state.deletemsg != "" ? (
                                <div className="col-lg-12 ui green message del-msg-padding">
                                  {this.state.deletemsg}
                                </div>
                              ) : (
                                <div></div>
                              )}
                              {(this.state.mrwbrl_facebook_link != "" ||
                                this.state.mrwbrl_google_link != "") &&
                              this.state.show_center_review == 1 ? (
                                <p>
                                  <div className="col-md-12 showReviewtab">
                                    <p>
                                      <span>
                                        Please select location to capture
                                        reviews
                                      </span>
                                      <span className="col-md-2 yr-btnicon">
                                        <a
                                          className="cusrsorpointer"
                                          tile="Edit"
                                          onClick={() => this.openTab("review")}
                                        >
                                          <i class="la la-edit blue"></i>
                                        </a>
                                        <a
                                          style={{ marginLeft: 3 }}
                                          className="cusrsorpointer"
                                          title="Delete"
                                          onClick={() =>
                                            this.deleteReviewPop(
                                              this.state.mrwbrl_id
                                            )
                                          }
                                        >
                                          <i class="la la-trash red"></i>
                                        </a>
                                      </span>
                                    </p>

                                    {this.state.mrwbrl_facebook_link != "" ? (
                                      <div>
                                        <img
                                          src="/assets/images/f-logo.png"
                                          style={{ height: 15 }}
                                        />
                                        <div>
                                          &nbsp;
                                          {this.state.mrwbrl_facebook_link}
                                        </div>
                                        <br />
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                  </div>
                                  {this.state.mrwbrl_google_link != "" ? (
                                    <div className="col-md-12">
                                      <img src="/assets/images/google-logo.png" />
                                      <div>
                                        &nbsp;
                                        {this.state.mrwbrl_google_link}
                                      </div>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </p>
                              ) : (
                                <p></p>
                              )}
                              {/* Review List End From Hare */}
                              <div className="col-12">
                                {
                                  //Check if message failed
                                  this.state.msg_rm != "" ? (
                                    <div className="col-lg-12 ui green message del-msg-padding">
                                      {this.state.msg_rm}
                                    </div>
                                  ) : (
                                    <div></div>
                                  )
                                }
                              </div>
                              {/* <div className="col-6">
                                <div className="form-group">
                                  <label
                                    style={{ fontSize: 12, fontWeight: 400 }}
                                  >
                                    Team member list
                                  </label>
                                  <div className="">
                                    <select
                                      value={this.state.branch_team_id}
                                      name="rm_tm_id"
                                      id="rm_tm_id"
                                      className="form-control"
                                      onChange={() =>
                                        this.assignTeam(this.state.rm_bid)
                                      }
                                    >
                                      <option value="0">Team member</option>
                                      {this.state.allteamlist &&
                                        Object.keys(rmteamlist).map(function (
                                          key
                                        ) {
                                          return (
                                            <option value={rmteamlist[key].id}>
                                              {rmteamlist[key].member_name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    {this.state.msg_rm_error != "" ? (
                                      <div style={{ color: "red" }}>
                                        {this.state.msg_rm_error}
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                  </div>
                                </div>
                              </div> */}
                            </div>
                            <div className="row buttonlistbtbuilder">
                              {this.state.buttonDetails.length > 0 && (
                                <div className="col-12 py-5">
                                  <ul
                                    style={{
                                      listStyle: "none",
                                      paddingLeft: "0px",
                                    }}
                                    className="yr-btneditable"
                                  >
                                    <ReactSortable
                                      animation={400}
                                      list={this.state.buttonDetails}
                                      setList={(newState) =>
                                        this.state.buttonDetails
                                      }
                                    >
                                      {this.state.buttonDetails.length > 0 &&
                                        this.state.buttonDetails.map(
                                          (buttonobj, index) => {
                                            return (
                                              <li style={{ display: "inline" }}>
                                                <button className="btn yr-round-btn mr-3">
                                                  {buttonobj.buttontitle}{" "}
                                                </button>
                                                <div class="yr-btnicon">
                                                  <a
                                                    onClick={() =>
                                                      this.buttonAction(
                                                        "edit",
                                                        buttonobj.buttontitle,
                                                        buttonobj.button_respond_on_branch,
                                                        buttonobj.dbid
                                                      )
                                                    }
                                                    className="cusrsorpointer"
                                                    tile="Edit"
                                                  >
                                                    <i class="la la-edit blue"></i>
                                                  </a>{" "}
                                                  <a
                                                    className="cusrsorpointer"
                                                    onClick={() =>
                                                      this.deleteButton(
                                                        buttonobj.dbid,
                                                        this.state.rm_bid
                                                      )
                                                    }
                                                    title="Delete"
                                                  >
                                                    <i class="la la-trash red"></i>
                                                  </a>
                                                </div>
                                              </li>
                                            );
                                          }
                                        )}
                                    </ReactSortable>
                                  </ul>
                                </div>
                              )}
                            </div>
                            <div className="row" style={{ marginTop: 88 }}>
                              <div className="col-12 d-flex justify-content-end">
                                {this.state.rm_bid != "" ? (
                                  <button
                                    type="submit"
                                    className="btn linear-gradient yr-submitbtn"
                                    onClick={() =>
                                      this.updateBranch(
                                        this.state.rm_bid,
                                        this.state.rm_btittle,
                                        this.state.editor_state[0]
                                      )
                                    }
                                    style={{ height: 39, fontSize: 13 }}
                                  >
                                    Save Branch
                                  </button>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-lg-4"
                          style={{
                            display: this.state.BranchArea ? "block" : "none",
                          }}
                        >
                          <div className="box-title mx-lg-3">&nbsp;</div>
                          <div
                            className="yr-max-height addbutton"
                            style={{
                              overflow: "visible",
                              position: "relative",
                            }}
                          >
                            <div className="yr-chat-round-box yr-closebtn yr-automation-uodate">
                              <button
                                type="button"
                                class="close linear-gradient"
                                onClick={() => this.popupClose("button")}
                              >
                                &#10006;
                              </button>

                              <div className="yr-addButtonBox">
                                <h4>Add New Button</h4>
                                <div className="form-group focusError">
                                  <label>Button Name</label>
                                  <div>
                                    <input
                                      type="hidden"
                                      name="hiddenid"
                                      value={this.state.hiddenid}
                                    />
                                    <input
                                      type="text"
                                      name="buttontitle"
                                      value={this.state.buttontitle}
                                      onChange={(e) => this.onchange(e)}
                                      placeholder="Button Title"
                                      className={
                                        errors && errors["buttontitle"]
                                          ? "form-control"
                                          : "form-control"
                                      }
                                    />
                                    <div className="red">
                                      {this.state.button_title_erro}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ display: "block" }}
                                className="addButtonDiv"
                              >
                                <div className="ui segment active tab">
                                  <div className="form-group">
                                    <label>Go to branch</label>
                                    <select
                                      className={
                                        errors && errors["Branch"]
                                          ? "form-control"
                                          : "form-control"
                                      }
                                      onChange={(e) => this.changeBranch(e)}
                                      value={
                                        this.state.button_respond_on_branch
                                      }
                                    >
                                      <option value="">Select Branch</option>
                                      {this.state.branchList &&
                                        this.state.branchList.map(
                                          (value, index) => {
                                            return (
                                              <option value={value.mrwb_id}>
                                                {value.mrwb_branch_tittle}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                    <div className="red">
                                      {this.state.branch_button_erro}
                                    </div>
                                    <a
                                      nohref=""
                                      class="addTextArea cursorpointer"
                                      onClick={() =>
                                        this.createNewBranch("modal")
                                      }
                                    >
                                      <br />
                                      <i class="la la-plus"></i> Create new
                                      branch
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-right form-group">
                              <button
                                type="reset"
                                class="btn yr-cancelbtn linear-gradient-cancel mg-r"
                                onClick={() => this.popupClose("button")}
                                style={{ height: 39, fontSize: 13 }}
                              >
                                <span> Cancel </span>
                              </button>
                              <button
                                type="submit"
                                className="btn linear-gradient yr-submitbtn"
                                onClick={() =>
                                  this.saveButton(this.state.rm_bid)
                                }
                                style={{ height: 39, fontSize: 13 }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 rightbotbuilder"
                          style={{
                            display: this.state.ReviewArea ? "block" : "none",
                          }}
                        >
                          <div className="box-title mx-lg-3">&nbsp;</div>
                          <div
                            className="yr-max-height addbutton"
                            style={{
                              overflow: "visible",
                              position: "relative",
                            }}
                          >
                            <div className="yr-chat-round-box yr-closebtn yr-automation-uodate">
                              <button
                                type="button"
                                class="close linear-gradient"
                                onClick={() => this.popupClose("button")}
                              >
                                &#10006;
                              </button>

                              <div className="yr-addButtonBox">
                                <h4>
                                  Please select location to capture reviews
                                </h4>
                                <div className="form-group focusError">
                                  {this.state.review_link_msg != "" ? (
                                    <div className="col-lg-12 ui green message del-msg-padding">
                                      {this.state.review_link_msg}
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                  {this.state.review_error_msg != "" ? (
                                    <div className="col-lg-12 col-lg-12 ui red message del-msg-padding">
                                      {this.state.review_error_msg}
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                  <label className="col-xs-12 mb-3 ml-2">
                                    <img src="/assets/images/f-logo.png" />
                                  </label>
                                  <div>
                                    <select
                                      className={
                                        errors && errors["FacebookLink"]
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      name="FacebookLink"
                                      id="FacebookLink"
                                      onChange={(e) => this.onchangereviewfb(e)}
                                      value={this.state.facebook_respond}
                                    >
                                      <option value="0" selected>
                                        Hide facebook as an option
                                      </option>
                                      {this.state.facebookList &&
                                        this.state.facebookList.map(
                                          (value, index) => {
                                            if (value.type == 1) {
                                              var OptionValue = value.page_name;

                                              var n = OptionValue.length;

                                              if (n > 35) {
                                                var res = OptionValue.substr(
                                                  0,
                                                  35
                                                );
                                                var res1 = res + "...";
                                              } else {
                                                var res1 = OptionValue;
                                              }
                                            } else {
                                              var OptionValue = value.link;
                                              var n = OptionValue.length;

                                              if (n > 35) {
                                                var res = OptionValue.substr(
                                                  0,
                                                  35
                                                );
                                                var res1 = res + "...";
                                              } else {
                                                var res1 = OptionValue;
                                              }
                                            }
                                            return (
                                              <option value={value.id}>
                                                {res1}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                    <div style={{ color: "red" }}>
                                      {this.state.review_error_msg_fb}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="yr-addButtonBox">
                                <div className="form-group focusError">
                                  <label className="col-xs-12 mb-3 ml-2">
                                    <img src="/assets/images/google-logo.png" />
                                  </label>
                                  <div>
                                    <select
                                      className={
                                        errors && errors["GoogleLink"]
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      name="GoogleLink"
                                      id="GoogleLink"
                                      onChange={(e) =>
                                        this.onchangereviewgoogle(e)
                                      }
                                      value={this.state.google_respond}
                                    >
                                      <option value="0" selected>
                                        Hide google as an option
                                      </option>
                                      {this.state.googleList &&
                                        this.state.googleList.map(
                                          (value, index) => {
                                            if (value.type == 1) {
                                              var OptionValueg =
                                                value.locationName;

                                              var ng = OptionValueg.length;

                                              if (ng > 35) {
                                                var resg = OptionValueg.substr(
                                                  0,
                                                  35
                                                );
                                                var res1g = resg + "...";
                                              } else {
                                                var res1g = OptionValueg;
                                              }
                                            } else {
                                              var OptionValueg = value.link;

                                              var ng = OptionValueg.length;

                                              if (ng > 35) {
                                                var resg = OptionValueg.substr(
                                                  0,
                                                  35
                                                );
                                                var res1g = resg + "...";
                                              } else {
                                                var res1g = OptionValueg;
                                              }
                                            }

                                            return (
                                              <option value={value.id}>
                                                {res1g}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                    {/* {errors &&
                                      errors.hasOwnProperty("GoogleLink") && (
                                        <CustomErrorField
                                          message={errors["GoogleLink"]}
                                          className="error invalid-feedback"
                                        />
                                      )} */}
                                  </div>
                                  <div style={{ color: "red" }}>
                                    {this.state.review_error_msg_google}
                                  </div>
                                  <a
                                    nohref=""
                                    class="addTextArea cursorpointer"
                                    onClick={() => this.createNewLink("modal")}
                                  >
                                    <br />
                                    <i class="la la-plus"></i> Add Link
                                  </a>
                                </div>
                              </div>
                              <div className="yr-addButtonBox">
                                <div className="form-group focusError">
                                  <div className="kt-checkbox-list">
                                    <label className="kt-checkbox chat-checkbox">
                                      <input
                                        type="checkbox"
                                        name="allowforreviews"
                                        checked={
                                          this.state.mrwbrl_allow_for_reviews
                                        }
                                        onChange={this.handleCheck}
                                      />

                                      <span></span>
                                    </label>
                                  </div>
                                  <div style={{ marginLeft: "21px" }}>
                                    Allow for reviews that will be private and
                                    only visible to you
                                  </div>
                                  {this.state.mrwbrl_allow_for_reviews ==
                                  "checked" ? (
                                    <div>
                                      <label class="col-form-label">
                                        After a private review is left, redirect
                                        reviewers to this page
                                      </label>
                                      <input
                                        autocomplete="off"
                                        className="form-control"
                                        name="custom_link"
                                        id="custom_link"
                                        type="text"
                                        value={this.state.mrwbrl_custom_link}
                                        placeholder="Url"
                                        onChange={(e) => this.customlink(e)}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <input
                                        autocomplete="off"
                                        className="form-control"
                                        name="custom_link"
                                        id="custom_link"
                                        type="hidden"
                                        value=""
                                        placeholder="Url"
                                        onChange={(e) => this.customlink(e)}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="text-right form-group">
                              <button
                                type="reset"
                                class="btn yr-cancelbtn linear-gradient-cancel mg-r"
                                onClick={() => this.popupClose("button")}
                                style={{ height: 39, fontSize: 13 }}
                              >
                                <span> Cancel </span>
                              </button>
                              <button
                                type="submit"
                                className="btn linear-gradient yr-submitbtn"
                                onClick={() =>
                                  this.saveReview(this.state.mrwbrl_id)
                                }
                                style={{ height: 39, fontSize: 13 }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                        <Modal
                          show={this.state.modalstatus}
                          footer={this.modalFooter(this.state.modalindex)}
                          onclose={this.modalClose}
                          title={this.state.modaltitle}
                          body={this.state.modalbody}
                        />
                        <Modal
                          show={this.state.modalstatusBranch}
                          footer={this.modalFooterBranch()}
                          onclose={this.modalCloseBranch}
                          title={this.state.modaltitleBranch}
                          body={this.state.modalbodyBranch}
                        />
                        <Modal
                          show={this.state.modalstatusReview}
                          footer={this.modalFooterReview()}
                          onclose={this.modalCloseReview}
                          title={this.state.modaltitleReview}
                          body={this.state.modalbodyReview}
                        />
                        <CreateBranch
                          show={this.state.create_modal_status}
                          onclose={this.modalCloseCreate}
                          saveNewBranch={this.saveNewBranch}
                        ></CreateBranch>
                        <CreateLink
                          show={this.state.create_modal_link_status}
                          onclose={this.modalLinkCloseCreate}
                          saveNewLink={this.saveNewLink}
                        ></CreateLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
    PageMessage: state.page.message,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setSubHeaderTitle,
  setNavigationName,
  toggleBreadcrum,
})(ManuallyCustomization);
