import React from "react";
import "../../../../../Modal/dialog.css";
import { Table } from 'semantic-ui-react';


const DialogContactDetail = ({ show, title, message, modalType, modalClose, buttonmsg, modalbuttofun, loading, detail_data }) => {
	let details = detail_data;
	if (detail_data !== null && typeof detail_data != 'undefined') {
		details = Object.entries(detail_data);
		console.log(details);
	} else {
		details = [];
	}

	let showDetail = details.filter(el => {
		if (el[0] == "is_subscribed") {
			return
		}
		else if (el[0] == "source") {
			return
		}
		else if (el[0] == "status") {
			return
		}
		else if (el[0] == "field") {
			return
		}
		else if (el[0] == "added_datetime") {
			return
		} 
		else if (el[0] == "unsubscribed_on_domains") {
			return
		}
		else if (el[0] == "isd_code") {
			return
		}
		// else if (el[0] == "phone") {
		// 	return
		// }
		else if (el[0] == "unsubscribed_on_domains") {
			return
		}
		else {
			if (el[0] !== null && el[0].length > 0){
				return el
			}
		}
	});

	console.log(showDetail, 'show-dialog')

	return (
		<React.Fragment>
			<div
				className={show ? "modal fade yr-upgrade-popup white-bg new-model-popup show" : "modal fade yr-upgrade-popup white-bg new-model-popup"}
				style={{ display: show ? "block" : "none" }}
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					{loading && <div className="ui loading form"></div>}
					<div className="modal-content white-bg yr-campaigns-model">
						<button
							type="button"
							className="close linear-gradient"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => modalClose(false, '')}
						>
							X
						</button>
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
						</div>
						<div className="modal-body"><div className="">

							<Table id="additional_captured" style={{ marginTop: "20px" }}>

								<Table.Body>
									{showDetail.length > 0 && showDetail.map((el, id) => {
										// el[]
										return (

											<Table.Row>
												<Table.Cell colspan="2" style={{ textTransform: "capitalize" }}>{el[0].split('_').join(' ')}</Table.Cell>
												<Table.Cell>{el[1]}</Table.Cell>
											</Table.Row>
										)
									})
									}


								</Table.Body>

							</Table>

						</div></div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DialogContactDetail;