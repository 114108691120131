import React,{ useState, useEffect,useRef } from 'react';
import visaLogo from './visa-logo.png';
import Modal from "../Pages/ChatSetup/components/Modal";
import ConfirmDialog from "../Modal/ConfirmDialog.js";
import {CardForms} from './CardForm';
import billing from "../../api/billing";
import {Dimmer, Loader} from 'semantic-ui-react';
const queryString = require("query-string");

function CreditCardList( props ){
    console.log("bill props",props)
     const cards = props.cardsList.cards;
    
    //const[cardState,setCardState] = useState({ addCardForm : false, cards : cards});
    const[cardState,setCardState] = useState({ showModal : false, cards : [],user: props.user,showConfirmDialog : false, confirmtitle : "Are You sure to confirm",confirmmessage: "",deletecard:{},loaded:false,loading:false});
    const [refresh, doRefresh] = useState(0);
    const [errMsg,seterrMsg]=useState("");
	const setCardsLIst = ( ) => {
		billing.GetUserBillingDetail( {user_id:props.user.user_id })
        .then((userResp) => {
            if(  userResp.cards ){
                props.updateCards( userResp.cards );
                setCardState({...cardState,showModal:false,loaded:false,showConfirmDialog:false,cards:userResp.cards});
                props.billing_update();
            }
        });
	}
    useEffect(() => {
        const params = queryString.parse(window.location.search);
        if((params.tab !== undefined && params.tab === "billing") && (params.cardSaved !== "undefined" && params.cardSaved ==='1')){
            getlivecards();
        }
        
    }, []);

    const getlivecards=()=>{
         billing.getZohoUserCards( {user_id:props.user.user_id }).then((userResp) => {
            if(  userResp.msg=="done"){
                console.log("cardsss",userResp);
                props.updateCards( userResp.data );
                setCardState({...cardState,cards:userResp.data});
                props.billing_update();
               window.location="http://localhost:3000/settings?tab=billing";
                // window.location="https://devreact.zyratalk.com/settings?tab=billing";
            }
        });
    }
    
    //set card as primary
    const setCardPrimary = ( card ) => {
        let cardtodel={
            month           : card.expiry_month,
            year            : card.expiry_year,
            last_four_digits: card.last_four_digits,
            gateway         : "stripe",
            user            : cardState.user.user_id,
            card_id         : card.card_id
        }

        let expires_on = card.expiry_month+"/"+card.expiry_year;
        billing
        .makePrimeCard(cardtodel)
        .then((userResp) => {
            // setCardsLIst(cards);
            props.billing_update();
            props.changePrimaryCard(card.last_four_digits,expires_on);
        });
        return false;
    }

    useEffect(
        () => {
              
                if( cardState.loaded == false  ){
                    setCardState({
                        ...cardState,
                        cards : props.cardsList,
                        loaded : true
                    })
                }
        },[cardState]
    )
    //delete credit card
    const deleteCreditCard = (e,card,type) => {
        setCardState({...cardState,showConfirmDialog:true,deletecard:card,deleteCrdType:type,confirmtitle:"Remove Credit Card",confirmmessage:"Are you sure you want to remove this credit card?",deleteCardFlag:false});
        return false;
    }

    //cardactionbutton starts
    const cardActionButton = ( card ) => {
        if( card.last_four_digits != props?.default_card || (props.user.userBillingDetail.user_billing_overview[0].subscription_status!="Active") ){
            return(
            <React.Fragment>
                {card.last_four_digits != props?.default_card && <a onClick = { () => setCardPrimary ( card ) } className="blue d-block" style={{fontWeight: 600,cursor:'pointer',width: 'max-content'}}>Mark as primary</a>}
                {props.user.userBillingDetail.user_billing_overview[0].subscription_status!=="ACTIVE" && <a onClick = { (  e ) => deleteCreditCard ( e,card,"regular" ) }  className="text-danger d-block" style={{fontWeight: 600,cursor:'pointer',width: 'max-content'}}>Delete payment method <i className="ml-2 fa fa-trash"></i></a>}
            </React.Fragment>
            )
        }
    }
    const closeAddform=()=>{
        setCardState({...cardState,showModal:false,loading:false});
    }
    //actionConfirmDialog perform action
    const actionConfirmDialog = () => {
        setCardState({...cardState,showConfirmDialog:false,loading:true});
        let cardtodel1={
            card_id:cardState.deletecard.card_id,
            cust_id:cardState.deletecard.zoho_customer_id,
            user_id:props.user.user_id,
            card_number:cardState.deletecard.last_four_digits
        }

        billing
        .delCreditCard(cardtodel1)
        .then((userResp) => {
          
            if(userResp.msg=="done"){
                props.billing_update();
                setCardsLIst();
                props.domref.current.innerHTML = "<div class='alert alert-success' >Card deleted successfully.</div>";
                setTimeout(() => {
                    props.domref.current.innerHTML = "";
                }, 10000);
            }
        });
        billing.GetUserBillingDetail( {user_id:props.user.user_id })
        .then((userResp) => {
            if(  userResp.cards ){
                props.updateCards( userResp.cards );
                setCardState({...cardState,showConfirmDialog:false,loaded:false,cards:userResp.cards,loading:false});
               
            }
        });
        return false;
    }

    const toggleConfirmDialog = () => {
       
        let flag = !cardState.showConfirmDialog;
        setCardState({...cardState,showConfirmDialog:false});

        return false;
    }
    //card action button close
    let cardlistd = props.cardsList.cards;
    const cardListRender = () => {
        let crds=cardState.cards.slice().reverse();
        return(<>
            { crds.length ? crds.map(
                ( card ) => {
                    return (
                        <>
                            <tr>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <img src={visaLogo} className="bill-card" alt="" />
                                        <a style={{width: 'max-content'}}>**** **** **** {card.last_four_digits} <br/>
                                            {(card.last_four_digits == props?.default_card) && (<b>(Primary Card)</b>) }
                                        </a>
                                        
                                    </div>
                                </td>
                                <td>
                                    <span>Expired on {card.expiry_month}/{card.expiry_year}</span>
                                </td>
                                <td>
                                    <span className="text-capitalize">{card.status ? "Active" : "De-Active"}</span>
                                </td>
                                <td>
                                    <span></span>
                                    {cardActionButton(card)}
                                </td>
                            </tr>
                           
                        </>
                    )
                } 
                ) : 
                <React.Fragment>
                    <h1 className="text-left  py-4">
                        <span>No Card Found</span>
                    </h1>
                </React.Fragment>}
            </> );
    }
    //form add 
   
    const formAddCard = ( formtype ) => {
        return <CardForms closepopup={()=>closeAddform()} 
                reff={props.domref} 
                form = {formtype} 
                refresh={refresh}
                availcards={cardState.cards}
                setCardsLIst = {() => setCardsLIst()} 
                closeModal = {() => closeModal()}
                user = {cardState.user} />;
    }
    //form add 

    //function for closing model
    const closeModal = ( ) => {
        const newState = {...cardState,showModal : false };
      
        setCardState(newState);
        doRefresh(prev => prev + 1);
    
    }
    // card modal
    const cardForm = ( ) => {
        return   (<Modal
            show={cardState.showModal}
            footer={""}
            onclose={closeModal}
            title={""}
            body={formAddCard( 'addForm' )}
        />)
    }

    const openFormModel = () => {
        setCardState({ ...cardState,showModal : !cardState.showModal })
    }
    const fetchzoholink=(e)=>{
        e.preventDefault();
        billing
        .fetchzoholink({user_id:props.user.user_id})
        .then((userResp) => {
          console.log(userResp)
            if(userResp.status && userResp.link && userResp.link!=''){
                let newwindow=window.open(userResp.link,'Add Card','height=600,width=800');
                if (window.focus) {newwindow.focus()}
                let timer=setInterval(function(){
                    if (newwindow.closed && timer){
                        clearInterval(timer);
                        window.location.reload();
                    }
                 },1000);
                return false;
            }else{
                seterrMsg("An error occured.");
            }
        });
        setTimeout(() => {
            seterrMsg("");
        }, 4000);
    }
    return (
        <>
            {errMsg.length>0 && <p className='alert alert-danger'>{errMsg}</p>}
            <a className="blue px-2" style={{fontWeight: 600,cursor:'pointer'}} onClick = { (e) => fetchzoholink(e) }>Add a payment method <i className="fa fa-plus"></i></a>
            {cardForm()}

            <div className="table-responsive">
                <table className="table table-borderless table-lg my-4 my-table">
                    <thead>
                        <tr>
                            <td>Details</td>
                            <td>Expiry Date</td>
                            <td>Status</td>
                            <td>Actions</td>
                        </tr>
                    </thead>
                    <tbody className="border-top">
                        { cardState.loading  && (
                            <div className="ui loading form"></div>
                            ) 
                        }
                        { cardListRender()}
                        
                    </tbody>
                </table>
                 <ConfirmDialog
                                show={cardState.showConfirmDialog}
                                toggleModal={actionConfirmDialog}
                                closeAction={toggleConfirmDialog}
                                title={cardState.confirmtitle}
                                message={cardState.confirmmessage}
                            />
            </div>
        </>
    )

}
export default CreditCardList;